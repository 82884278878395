(function () {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('person', {
                parent: 'entity',
                url: '/person?page&sort&search',
                reloadOnSearch: false,
                listName: 'PERSON',
                type: 'overview',
                data: {
                    pageTitle: 'walterApp.person.home.title'
                },
                views: {
                    'content-overview@': {
                        templateUrl: 'app/personal-data-management/person/people.html',
                        controller: 'PersonController',
                        controllerAs: 'vm'
                    },
                    'filterbar@': {
                        templateUrl: 'app/layouts/navbar/filterbar.html',
                        controller: 'FilterbarController',
                        controllerAs: 'vm'

                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: {
                        value: null,
                        dynamic: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('filter');
                        $translatePartialLoader.addPart('columnConfig');
                        return $translate.refresh();
                    }]
                },
                onExit: ['NeededEntitiesCache', function(NeededEntitiesCache) {
                    NeededEntitiesCache.invalidate();
                }]
            })
            .state('person.column-config-edit', {
                type: 'overview',
                url: '/column-config/{entity}/edit',
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/column-config/column-config-dialog.html',
                        controller: 'ColumnConfigController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            columnConfig: ['ColumnConfig', function(ColumnConfig) {
                                return ColumnConfig.query({entity : $stateParams.entity}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('columnConfig');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('person');
                                return $translate.refresh();
                            }]
                        }
                    })
                    .result.then(function() {
                        $state.go('person', $stateParams, { reload: 'person' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('person-fee', {
                parent: 'entity',
                url: '/person?page&sort&search',
                data: {
                    pageTitle: 'walterApp.person.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/person/people-fees.html',
                        controller: 'PersonController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,


                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('person.new-member', {
                url: '/new-member',
                data: {
                    pageTitle: 'walterApp.person.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/person/member-new.html',
                        controller: 'MemberNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('membership');
                        $translatePartialLoader.addPart('emailType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Person', function ($stateParams, Person) {
                        return {
                            person: {
                                firstName: null,
                                lastName: null,
                                birthDate: null,
                                email: null,
                                telephoneNumber: null,
                                streetAddress: null,
                                postalCode: null,
                                city: null,
                                country: null,
                                hasDirectDebit: false,
                                emailType: 'VERIFIED',
                                id: null
                            },
                            membership: {
                                beginDate: null,
                                endDate: null,
                                membershipFeeId: null,
                                id: null
                            }

                        }
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('person.new', {
                url: '/new',
                data: {
                    pageTitle: 'walterApp.person.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/person/person-edit.html',
                        controller: 'PersonEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('emailType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Person', function ($stateParams, Person) {
                        return {
                            firstName: null,
                            lastName: null,
                            birthDate: null,
                            email: null,
                            telephoneNumber: null,
                            streetAddress: null,
                            postalCode: null,
                            city: null,
                            country: null,
                            hasDirectDebit: false,
                            id: null,
                            emailType: 'VERIFIED'
                        }
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('person.edit', {
                url: '/{id}/edit',
                data: {
                    pageTitle: 'walterApp.person.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/person/person-edit.html',
                        controller: 'PersonEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('emailType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Person', function ($stateParams, Person) {
                        return Person.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('person.delete', {
                url: '/{id}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/personal-data-management/person/person-delete-dialog.html',
                        controller: 'PersonDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Person', function (Person) {
                                return Person.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('person', null, {reload: 'person'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('person.detail', {
                url: '/{id}/detail',
                data: {
                    pageTitle: 'walterApp.person.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/person/person-detail.html',
                        controller: 'PersonDetailController',
                        controllerAs: 'vm'
                    }

                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('personGroup');
                        $translatePartialLoader.addPart('personInstrument');
                        $translatePartialLoader.addPart('personClothing');
                        $translatePartialLoader.addPart('membership');
                        $translatePartialLoader.addPart('membershipFeeForPeriod');
                        $translatePartialLoader.addPart('personHonor');
                        $translatePartialLoader.addPart('personAward');
                        $translatePartialLoader.addPart('emailType');
                        $translatePartialLoader.addPart('personInfo');

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Person', function ($stateParams, Person) {
                        return Person.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state, $stateParams) {
                        var currentStateData = {
                            name: 'person',
                            params: $stateParams,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            }).state('person.detail.instrument', {
            views: {
                data: {
                },
                'instrumentView': {
                    templateUrl: 'app/personal-data-management/person-instrument/person-instruments.html',
                    controller: 'PersonInstrumentController',
                    controllerAs: 'vmInstrument'
                }
            },
            resolve: {
                previousState: ["$state", function ($state, $stateParams) {
                    var currentStateData = {
                        name: 'person.detail',
                        params: $stateParams,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('person.detail.clothing', {
            views: {
                data: {
                },
                'clothingView': {
                    templateUrl: 'app/personal-data-management/person-clothing/person-clothings.html',
                    controller: 'PersonClothingController',
                    controllerAs: 'vmClothing'
                }
            },
            resolve: {
                previousState: ["$state", function ($state, $stateParams) {
                    var currentStateData = {
                        params: $stateParams,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('person.detail.membership', {
            views: {
                data: {
                },
                'membershipView': {
                    templateUrl: 'app/personal-data-management/membership/memberships.html',
                    controller: 'MembershipController',
                    controllerAs: 'vmMembership'
                }
            },
            resolve: {
                previousState: ["$state", function ($state, $stateParams) {
                    var currentStateData = {
                        name: 'person.detail',
                        params: $stateParams,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('person.detail.honor', {
            views: {
                data: {
                },
                'honorView': {
                    templateUrl: 'app/personal-data-management/person-honor/person-honors.html',
                    controller: 'PersonHonorController',
                    controllerAs: 'vmHonor'
                }
            },
            resolve: {
                previousState: ["$state", function ($state, $stateParams) {
                    var currentStateData = {
                        name: 'person.detail',
                        params: $stateParams,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('person.detail.award', {
            views: {
                data: {
                },
                'awardView': {
                    templateUrl: 'app/personal-data-management/person-award/person-awards.html',
                    controller: 'PersonAwardController',
                    controllerAs: 'vmAward'
                }
            },
            resolve: {
                previousState: ["$state", function ($state, $stateParams) {
                    var currentStateData = {
                        name: 'person.detail',
                        params: $stateParams,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        }).state('person.detail.info', {
            views: {
                data: {
                },
                'infoView': {
                    templateUrl: 'app/personal-data-management/person-info/person-info.html',
                    controller: 'PersonInfoController',
                    controllerAs: 'vmInfo'
                }
            },
            resolve: {
                previousState: ["$state", function ($state, $stateParams) {
                    var currentStateData = {
                        name: 'person.detail',
                        params: $stateParams,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }

})();
