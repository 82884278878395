(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('instrument-service', {
            parent: 'inventory',
            url: '/instrument-service?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.instrumentService.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/instrument-service/instrument-services.html',
                    controller: 'InstrumentServiceController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('instrumentService');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('instrument-service.new', {
            url: '/new?instrumentId',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.instrumentService.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/instrument-service/instrument-service-edit.html',
                    controller: 'InstrumentServiceEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('instrumentService');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InstrumentService', function($stateParams, InstrumentService) {
                        return {
                            date: null,
                            cashbookEntryAmount: null,
                            note: null,
                            instrumentId: null,
                            id: null
                        };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'instrument-service',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
        })
        .state('instrument-service.edit', {
            url: '/{id}/edit?instrumentId',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.instrumentService.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/instrument-service/instrument-service-edit.html',
                    controller: 'InstrumentServiceEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('instrumentService');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'InstrumentService', function($stateParams, InstrumentService) {
                    return InstrumentService.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'instrument',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('instrument-service.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/inventory/instrument-service/instrument-service-delete-dialog.html',
                    controller: 'InstrumentServiceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InstrumentService', function(InstrumentService) {
                            return InstrumentService.get({id : $stateParams.id}).$promise;
                        }],
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'instrument-detail',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                }).result.then(function() {
                    $state.go('instrument-detail', {id: $stateParams.instrumentId}, { reload: 'instrument-detail' });
                    		//
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
