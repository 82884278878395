(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MailLogController', MailLogController);

    MailLogController.$inject = ['$scope', '$state', '$filter', 'MailLog', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function MailLogController ($scope, $state, $filter, MailLog, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.date = pagingParams.date;
        vm.status = pagingParams.status;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.switchPage = switchPage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        loadAll();

        function loadAll () {
            var state = null;

            if (vm.status != "") {
                state = vm.status;
            }

            MailLog.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                'date': $filter('date')(vm.date, 'yyyy-MM-dd'),
                'mailLogStatusList': state
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.mailLogs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                date: vm.date,
                status: vm.status
            });
        }

        function switchPage() {
            pagingParams.page = vm.page;
            loadAll();
            transition();
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
