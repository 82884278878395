(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];


    function stateConfig($stateProvider) {
        $stateProvider
            .state('person-bulk', {
                parent: 'personal-data-management',
                url: '/person-bulk',
                params: {
                    personCollection: null
                },
                data: {
                    pageTitle: 'walterApp.personBulk.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/person-bulk/person-bulk.html',
                        controller: 'PersonBulkController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('personBulk');
                        $translatePartialLoader.addPart('emailType');
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                },
                onExit: ['NeededEntitiesCache', function(NeededEntitiesCache) {
                    NeededEntitiesCache.invalidate();
                }]
            });
    }

})();
