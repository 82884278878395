(function() {
	'use strict';

	angular
		.module('walterApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('bank-import-data-edit', {
			url: '{id}/edit',
            parent: 'cashbook-entry.import',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.bankImportData.edit.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/bank-import-data/bank-import-data-edit.html',
					controller: 'BankImportDataEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('bankImportData');
                    $translatePartialLoader.addPart('global');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'BankImportData', function($stateParams, BankImportData) {
					return BankImportData.get({ id : $stateParams.id }).$promise;
				}]
			}
		})
        .state('cashbook-entry.import.confirm-import', {
            url: '/confirm/',
            params: {
                selectedBankImportData: null
            },
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/accounting/bank-import-data/bank-import-data-dialog.html',
                    controller: 'ImportDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        selectedBankImportData: function() { return $stateParams.selectedBankImportData; }
                    }
                }).result.then(function() {
                    $state.go('cashbook-entry', null, { reload: 'cashbook-entry' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
	}

})();
