(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Customization', Customization);

    Customization.$inject = ['$resource'];

    function Customization ($resource) {
        var resourceUrl =  'api/customizations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CustomizationNeededEntities', CustomizationNeededEntities);

    CustomizationNeededEntities.$inject = ['NeededEntitiesCache'];

    function CustomizationNeededEntities (NeededEntitiesCache) {

        this.personGroups = personGroups;
        this.featureGroups = featureGroups;
        this.companies = companies;
        this.cashbookAccounts = cashbookAccounts;
        this.cashbookCategories = cashbookCategories;

        function personGroups() {
            return NeededEntitiesCache.getCache('api/customizations/person-groups/').query();
        }

        function featureGroups() {
            return NeededEntitiesCache.getCache('api/customizations/feature-groups/').query();
        }

        function companies() {
            return NeededEntitiesCache.getCache('api/customizations/companies/').query();
        }

        function cashbookAccounts() {
            return NeededEntitiesCache.getCache('api/customizations/cashbook-accounts/').query();
        }

        function cashbookCategories() {
            return NeededEntitiesCache.getCache('api/customizations/cashbook-categories/').query();
        }

        return this;

    }
})();

