(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('ColumnConfig', ColumnConfig);

    ColumnConfig.$inject = ['$resource'];

    function ColumnConfig ($resource) {
        var resourceUrl =  'api/column-config/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'update': { method:'PUT', isArray: true }
        });
    }
})();

(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('ColumnConfigService', ColumnConfigService);

    ColumnConfigService.$inject = ['$cacheFactory', '$filter', 'ColumnConfig'];

    function ColumnConfigService ($cacheFactory, $filter, ColumnConfig) {
        /** Alles was für persistente Filter benötigt wird! */
        var me = this;
        this.columnConfigCache = $cacheFactory('ColumnConfig');
        this.query = query;
        this.update = update;

        function query(listName, showOnlyVisible, callback) {
            var cachedValue = this.columnConfigCache.get(listName);

            if (!showOnlyVisible || cachedValue === undefined) {
                ColumnConfig.query({
                    entity: listName,
                    showOnlyVisible: true
                }).$promise.then(function (data) {
                    if (showOnlyVisible) {
                        me.columnConfigCache.put(listName, data);
                    }
                    callback(data);
                });
            } else {
                callback(cachedValue);
            }
        }

        function update(columnConfig, successCallback, errorCallback) {
            ColumnConfig.update(columnConfig, function(result) {
                var visibleColumns = $filter('filter')(result, {visible: true});
                var listName = visibleColumns[0]['entity'];

                me.columnConfigCache.put(listName, visibleColumns);
                successCallback(result);
            }, errorCallback);
        }

        return this;
    }
})();
