(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

        .state('emailverificationletter', {
            parent: 'app',
            url: '/emailverificationletter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.emailverificationletter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/letters/email-verification-letters/emailverificationletter-edit.html',
                    controller: 'EmailVerificationLetterEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('emailverificationletter');
                    $translatePartialLoader.addPart('letter');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'EmailVerificationLetter', function($stateParams, EmailVerificationLetter) {
                    return {
                        title: null,
                        text: null,
                        personGroup: null,
                        letterType: "EMAIL_VERIFICATION_LETTER"
                    };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'emailverificationletter',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('emailverificationletter.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/letters/email-verification-letters/emailverificationletter-delete-dialog.html',
                    controller: 'EmailVerificationLetterDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['EmailVerificationLetter', function(EmailVerificationLetter) {
                            return EmailVerificationLetter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('emailverificationletter', null, { reload: 'emailverificationletter' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('emailverificationletter.overwrite', {
            url: '/{id}/overwrite',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/letters/email-verification-letters/emailverificationletter-overwrite-dialog.html',
                    controller: 'EmailVerificationLetterEditController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['EmailVerificationLetter', function(EmailVerificationLetter) {
                            return EmailVerificationLetter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('emailverificationletter', null, { reload: 'emailverificationletter' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
