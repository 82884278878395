(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CustomizationDeleteController',CustomizationDeleteController);

    CustomizationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Customization'];

    function CustomizationDeleteController($uibModalInstance, entity, Customization) {
        var vm = this;

        vm.customization = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Customization.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
