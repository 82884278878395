(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('InstrumentController', InstrumentController);

    InstrumentController.$inject = ['$scope', '$state', '$stateParams', 'DateUtils', 'Instrument', 'InstrumentInstrumentType', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'PaginationUtil', 'ColumnConfigService', 'InstrumentRelatedEntities', 'FilterService'];

    function InstrumentController ($scope, $state, $stateParams, DateUtils, Instrument, InstrumentInstrumentType, ParseLinks, AlertService, pagingParams, paginationConstants, PaginationUtil, ColumnConfigService, InstrumentRelatedEntities, FilterService) {
        var vm = this;

        vm.listName = "INSTRUMENT";

        /** Column-Config */

        ColumnConfigService.query(vm.listName, true, setColumnConfig);

        function setColumnConfig(columnConfig) {
            vm.columnConfig = columnConfig;
        }

        vm.listDateFormats = {
            purchaseDate: "dd.MM.yyyy"
        };

        vm.listCheckboxes = {
            privateInstrument: true
        }

        vm.listPrices = {
            price: {
                printWhen: 'instrument.price'
            }
        }

        pagingParams.search = $stateParams.search;
        pagingParams.sort = $stateParams.sort;
        pagingParams.predicate = PaginationUtil.parsePredicate($stateParams.sort);
        pagingParams.ascending = PaginationUtil.parseAscending($stateParams.sort);


        vm.loadPage = loadPage;
        vm.resetSearch = resetSearch;
        vm.switchSearch = switchSearch;
        vm.isSearchDataDirty = isSearchDataDirty;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.switchPage = switchPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.instrumenttypes = InstrumentRelatedEntities.getInstrumentTypes();

        var currentSearch = pagingParams.search;
        if (currentSearch)
        {
            vm.search = angular.fromJson(currentSearch);

            loadAll();
        }
        else  {
            resetSearch();
        }

        vm.searchActive = vm.search.search;

        function loadAll (noFilterReset) {
            if (!noFilterReset) {
                FilterService.resetFilterSelection();
            }

            var dateFormat = 'yyyy-MM-dd';

            var dateBegin = vm.search.dateBegin ? DateUtils.convertLocalDateToServer(vm.search.dateBegin) : null;
            var dateEnd = vm.search.dateEnd ? DateUtils.convertLocalDateToServer(vm.search.dateEnd) : null;

            Instrument.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                name: vm.search.name,
                type: vm.search.type,
                privateInstrument: vm.search.privateInstrument,
                dateBegin: dateBegin,
                dateEnd: dateEnd,
                priceBegin: vm.search.priceBegin,
                priceEnd: vm.search.priceEnd,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.instruments = data;
                vm.page = pagingParams.page;
                transition();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function switchPage() {
            pagingParams.page = vm.page;
            transition();
        }

        function transition () {
            vm.search.search = vm.searchActive;
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: angular.toJson(vm.search)
            });
        }

        function switchSearch() {
            if (vm.searchActive) {
                vm.searchActive = false;
            } else {
                vm.searchActive = true;
            }
            transition();
        }


     // for search

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.resetSearch = resetSearch;
        function resetSearch() {
            vm.instruments = null;
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = {
            	name: null,
                type: null,
                privateInstrument: null,
                dateBegin: null,
                dateEnd: null,
                priceBegin: null,
                priceEnd: null
            };
            loadAll();
        }

        vm.isSearchDataDirty = isSearchDataDirty;
        function isSearchDataDirty() {
            return (vm.search.name != null && vm.search.name.length > 0) ||
                vm.search.type != null ||
                vm.search.privateInstrument != null ||
                vm.search.dateBegin != null ||
                vm.search.dateEnd != null ||
                vm.search.priceBegin != null ||
                vm.search.priceEnd != null;
        }

        function setSearch(search) {
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = search;
            loadAll(true);
        }

        function getCurrentSearch() {
            return vm.search;
        }

        FilterService.setResetSearchFun(resetSearch);
        FilterService.setSearchFun(setSearch);
        FilterService.getCurrentSearchFun(getCurrentSearch);
        FilterService.setIsVisible(true);

        vm.datePickerOpenStatus.dateEnd = false;
        vm.datePickerOpenStatus.dateBegin = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
