(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('InstrumentServiceEditController', InstrumentServiceEditController);

    InstrumentServiceEditController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$rootScope', 'previousState', 'entity', 'InstrumentService', 'giveInstrument'];

    function InstrumentServiceEditController($timeout, $scope, $stateParams, $state, $rootScope, previousState, entity, InstrumentService, giveInstrument) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.instrumentService = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        $scope.instrumentId = $stateParams.instrumentId;
        vm.instrumentService.instrumentId = $scope.instrumentId;
        
        vm.maxDate = new Date();
        vm.minDate = giveInstrument.getPurchaseDate();
        
        if(entity.id != null) {
        	vm.createMode = false;  
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.instrumentService.id !== null) {
                InstrumentService.update(vm.instrumentService, onSaveSuccess, onSaveError);
            } else {
                InstrumentService.save(vm.instrumentService, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
        	$scope.$emit('walterApp:instrumentServiceUpdate', result);
            $state.go(previousState.name, {id: $stateParams.instrumentId});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        vm.dateOptions = {
        		maxDate: vm.maxDate,
        		minDate: vm.minDate
        };
    }
})();
