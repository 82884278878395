(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CompositionDetailController', CompositionDetailController);

    CompositionDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', '$filter', 'previousState', 'entity',  'CompositionArrangers', 'CompositionComposers', 'ComposersForComposition', 'ArrangersForComposition', 'ColorCode'];

    function CompositionDetailController($scope, $state, $rootScope, $stateParams, $filter, previousState, entity,  CompositionArrangers, CompositionComposers, ComposersForComposition, ArrangersForComposition, ColorCode) {
        var vm = this;

        vm.composition = entity;
        vm.previousState = previousState;
        vm.composers = CompositionComposers.query({size : 9999});
        vm.selectedComposer = null;

        vm.arrangers = CompositionArrangers.query({size : 9999});
        vm.selectedArranger = null;

        vm.addComposer = addComposer;
        vm.addArranger = addArranger;
        vm.goBack = goBack;

        getComposers();
        getArrangers();

        function getComposers() {
        	ComposersForComposition.query({id : entity.id, sort : 'name,asc'}, function(data,headers) {
        		vm.composersForComposition = data;
        	});
        }

        function getArrangers() {
        	ArrangersForComposition.query({id : entity.id, sort : 'name,asc'}, function(data, headers) {
        		vm.arrangersForComposition = data;
        	});
        }

        function addComposer() {
        	ComposersForComposition.update({id : entity.id}, vm.selectedComposer, function() {
        		getComposers();
        	});
        }

        function addArranger() {
        	ArrangersForComposition.update({id : entity.id}, vm.selectedArranger, function() {
        		getArrangers();
        	});
        }

        function goBack() {
            $state.transitionTo(previousState.name, previousState.params, {reload : false, notify: false});
        }

        var unsubscribe = $rootScope.$on('walterApp:compositionUpdate', function(event, result) {
            vm.composition = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
