(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipEditController', MembershipEditController);

    MembershipEditController.$inject = ['$timeout', '$state', '$scope', 'previousState', '$stateParams', 'entity', 'Person', 'Membership', 'MembershipFee', '$filter'];

    function MembershipEditController ($timeout, $state, $scope, previousState, $stateParams, entity, Person, Membership, MembershipFee, $filter) {
        var vm = this;

        vm.membership = entity;
        vm.membershipFees = MembershipFee.query();

        vm.previousState = previousState.name;
        vm.save = save;
        vm.back = back;
        vm.person = Person.get({id : $stateParams.personid});
        vm.membership.personId = $stateParams.personid;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.createMode = false;


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        if(entity.id !== null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
        }


        function save () {
            vm.isSaving = true;
            if (vm.membership.id !== null) {
                Membership.update(vm.membership, onSaveSuccess, onSaveError);
            } else {
                Membership.save(vm.membership, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:membershipUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
                $state.go(vm.previousState, {"id": vm.person.id});
        }

        vm.datePickerOpenStatus.beginDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.datepickerOptions = {
        	    datepickerMode:'year',
        	    minMode:'year',
        	    showWeeks:"false"
        };

        $scope.changeDate = function () {
            var date = vm.membership.endDate;
        	if (date.getDate() === 31 && date.getMonth() === 11) {
        		//do nothing
        	}
        	else {
                date.setFullYear(date.getFullYear() +1);
                date.setDate(date.getDate() -1);
                vm.membership.endDate = date;
         //       var dateText = $filter('date')(dateText, "dd.MM.yyyy");
                var _date = $filter('date')(new Date(date), 'dd.MM.yyyy');
                $('#field_endDate').val(_date).trigger('input');
        	}
        }
    }
})();
