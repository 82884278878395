(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('person-clothing', {
            parent: 'entity',
            url: '/person-clothing?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.personClothing.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/person-clothing/person-clothings.html',
                    controller: 'PersonClothingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('personClothing');
                    $translatePartialLoader.addPart('clothing');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('person-clothing.new', {
            url: '/new/person/{personid}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.personClothing.home.create'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/person-clothing/person-clothing-edit.html',
                    controller: 'PersonClothingEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('personClothing');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PersonClothing', function($stateParams, PersonGroup) {
                    return {
                        beginDate: null,
                        endDate: null,
                        id: null
                    };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'person.detail.clothing',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('person-clothing.edit', {
            url: '/{id}/edit/person/{personid}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.personClothing.home.edit'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/person-clothing/person-clothing-edit.html',
                    controller: 'PersonClothingEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('personClothing');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PersonClothing', function($stateParams, PersonClothing) {
                    return PersonClothing.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'person.detail.clothing',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('person-clothing.delete', {
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/personal-data-management/person-clothing/person-clothing-delete-dialog.html',
                    controller: 'PersonClothingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PersonClothing', function(PersonClothing) {
                            return PersonClothing.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                     $state.go('person.detail.clothing', {"id": $state.params.personid } );
                }, function() {
                     $state.go('^');
                });
            }]
        });
    }

})();
