(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('TemplateEditController', TemplateEditController);

    TemplateEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Template', 'Upload'];

    function TemplateEditController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, Template, Upload) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.clear = clear;
        vm.template = entity;
        vm.progress = false;
		vm.showUploadField = false;
        vm.isInEditMode = entity.id !== null;
        vm.hasFileUploaded = entity.file !== null;
        vm.toggleShowUploadField = toggleShowUploadField;


        if(entity.id != null) {
        }
        else {
        	vm.createMode = true;
        }

        function toggleShowUploadField() {
			vm.showUploadField = !vm.showUploadField;

			if(vm.showUploadField) { vm.template.file = null; }
			else { vm.template.file = entity.file; }
		}

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
			$uibModalInstance.dismiss('cancel');
		}

        function save (file) {
            vm.isSaving = true;
            var template = {};
			angular.copy(vm.template, template);

			// cleanup
			if(template.id == null) delete template.id;
			if(template.title == null) delete template.title;
			if(template.marginLeft == null) delete template.marginLeft;
			if(template.marginRight == null) delete template.marginRight;
			if(template.marginTop == null) delete template.marginTop;
			if(template.marginBottom == null) delete template.marginBottom;
			delete template.file;
			delete template.uploadedFile;
			delete template.$promise;
			delete template.$resolved;

            Upload.upload({
				url: 'api/templates' + (vm.template.id !== null ? '/' + vm.template.id : ''),
				method: 'POST',
				data: {
					template: template,
					uploadFile: file
				}
			})
			.progress(function (evt) { vm.progress = parseInt(100.0 * evt.loaded / evt.total); })
			.success(onSaveSuccess)
			.error(onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:templateUpdate', result);
            vm.isSaving = false;
            $state.go(previousState.name);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.downloadTemplate = downloadTemplate;
        function downloadTemplate() {
            Template.download({
                uuid: vm.template.file
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                var blob = new Blob([data.data], { type: headers('content-type') });
                saveAs(blob, data.filename);
            };

            function onError(error) {
                AlertService.error(error.data.message);
            };
        }
    }
})();
