(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipFeeDetailController', MembershipFeeDetailController);

    MembershipFeeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MembershipFee', 'MembershipFeeAmount', 'AmountsForMembershipFee'];

    function MembershipFeeDetailController($scope, $rootScope, $stateParams, previousState, entity, MembershipFee, MembershipFeeAmount, AmountsForMembershipFee) {
        var vm = this;

        vm.membershipFee = entity;
        vm.amountsForMembershipFee = AmountsForMembershipFee.query({id : entity.id});
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('walterApp:membershipFeeUpdate', function(event, result) {
            vm.membershipFee = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
