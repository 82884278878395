(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];


    function stateConfig($stateProvider) {
        $stateProvider
            .state('data-import', {
                parent: 'admin',
                url: '/data-import',
                data: {
                    pageTitle: 'walterApp.dataImport.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/data-import/data-import.html',
                        controller: 'DataImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataImport');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
