(function() {
	'use strict';

	angular.module('walterApp').controller('PeopleFeesController',
			PeopleFeesController);

	PeopleFeesController.$inject = [ '$scope', '$state', 'DateUtils',
			'PeopleFees', 'ParseLinks', 'AlertService', 'pagingParams',
			'paginationConstants' ];

	function PeopleFeesController($scope, $state, DateUtils, PeopleFees,
			ParseLinks, AlertService, pagingParams, paginationConstants) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;

		vm.mode = 'current';

		vm.loadAll = loadAll;

		loadAll();

		function loadAll() {

			PeopleFees.query({
				page : pagingParams.page - 1,
				size : vm.itemsPerPage,
				sort : sort(),
				previousPeriods : vm.mode == 'previous' || vm.mode == 'all',
				currentPeriod: vm.mode == 'current' || vm.mode == 'all'
			}, onSuccess, onError);
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.people = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch
			});
		}
	}
})();
