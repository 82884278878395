(function () {
    'use strict';
    angular
        .module('walterApp')
        .factory('DemandLetter', DemandLetter);

    DemandLetter.$inject = ['Blob', 'FileSaver', '$resource'];

    function DemandLetter(Blob, FileSaver, $resource) {
        var resourceUrl = 'api/demand-letters/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', params: { letterType: 'DEMAND_LETTER' }, isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'generate': {
                'url': 'api/demand-letters/generate',
                'responseType': 'arraybuffer',
                transformResponse: function (data, headers, status) {
                    if (status == 200) {
                        if (data) {
                            var filename = "file.pdf";
                            if (headers) {
                                //get filename from header (partly from http://stackoverflow.com/questions/33046930/how-to-get-the-name-of-a-file-downloaded-with-angular-http
                                var contentDisposition = headers('Content-Disposition');
                                filename = contentDisposition.split(';')[1].trim().split('=')[1];
                                filename = filename.replace(/"/g, '');
                            }
                            var blob = new Blob([data]),
                                url = window.URL.createObjectURL(blob);
                            FileSaver.saveAs(blob, filename);
                            return angular.toString(url);
                        }
                    } else {
                        //because we have an arraybuffer we have to extract the needed data
                        var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));

                        var obj = JSON.parse(decodedString);
                        var message = obj['message'];
                        return message;
                    }
                },
                method: 'POST'
            }

        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('DemandLetterTemplate', DemandLetterTemplate);

    DemandLetterTemplate.$inject = ['$resource'];

    function DemandLetterTemplate ($resource) {
        var resourceUrl =  'api/demand-letters/templates';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

