(function() {
	'use strict';
	angular
		.module('walterApp')
		.factory('CashbookEntry', CashbookEntry);

	CashbookEntry.$inject = ['$resource', 'DateUtils'];

	function CashbookEntry ($resource, DateUtils) {
		var resourceUrl =  'api/cashbook-entries/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true,
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

						if(data.dateFrom) {
							data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
						}

						if(data.dateTo) {
							data.dateTo = DateUtils.convertLocalDateFromServer(data.dateTo);
						}
					}
					return data;
				}
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
						data.date = DateUtils.convertLocalDateFromServer(data.date);
					}
					return data;
				}
			},
			'update': {
				method: 'PUT',
				transformRequest: function (data) {
					var copy = angular.copy(data);
					copy.date = DateUtils.convertLocalDateToServer(copy.date);
					return angular.toJson(copy);
				}
			},
			'save': {
				method: 'POST',
				transformRequest: function (data) {
					var copy = angular.copy(data);
					copy.date = DateUtils.convertLocalDateToServer(copy.date);
					return angular.toJson(copy);
				}
			},
			'report': {
				method: 'GET',
				url: 'api/cashbook-report',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					var header = headers('content-disposition');
					var result = header.split(';')[1].trim().split('=')[1];
					var filename = result.replace(/"/g, '');
					
					return {
						data: data,
						filename: filename
					}
				}
			},
			'saldo': {
				method: 'GET',
				url: 'api/cashbook-saldo',
				transformResponse: function(data, headers) {
					return { value: parseFloat(data) };
				}
			}
		});
	}
})();

(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('CashbookRelatedEntities', CashbookRelatedEntities);

    CashbookRelatedEntities.$inject = ['$resource'];

    function CashbookRelatedEntities ($resource) {
        var me = this;

        var cashbookCategories;
        var cashbookAccounts;

        this.getCashbookCategories = getCashbookCategories;
        this.getCashbookAccounts = getCashbookAccounts;
        this.invalidate = invalidate;

        function getCashbookCategories() {
            if (!cashbookCategories) {
                cashbookCategories = CashbookCategoryResource().query();
            }
            return cashbookCategories;
        }

        function getCashbookAccounts() {
            if (!cashbookAccounts) {
                cashbookAccounts = CashbookAccountResource().query();
            }
            return cashbookAccounts;
        }

        function invalidate() {
            cashbookCategories = null;
            cashbookAccounts = null;
        }

        function CashbookCategoryResource() {
            var resourceUrl =  'api/cashbook-categories';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true}
            });
        }

        function CashbookAccountResource() {
            var resourceUrl =  'api/cashbook-accounts';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true, cache: true}
            });
        }

        return this;
    }
})();
