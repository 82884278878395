(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonInstrumentDeleteController',PersonInstrumentDeleteController);

    PersonInstrumentDeleteController.$inject = ['$uibModalInstance', 'entity', 'PersonInstrument'];

    function PersonInstrumentDeleteController($uibModalInstance, entity, PersonInstrument) {
        var vm = this;

        vm.personInstrument = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PersonInstrument.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
