(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CompositionEditController', CompositionEditController);

    CompositionEditController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', '$timeout', 'previousState', 'entity', 'Composition',  'CashbookEntry', 'Company', 'Genre', 'MusicBook', 'Arranger', 'Composer', 'ColorCode'];

    function CompositionEditController($scope, $state, $rootScope, $stateParams, $timeout, previousState, entity, Composition, CashbookEntry, Company, Genre, MusicBook, Arranger, Composer, ColorCode) {
        var vm = this;

        vm.composition = entity;
        vm.previousState = previousState;
        vm.goBack = goBack;

        vm.colorCodes = ColorCode.query();
        vm.companies = Company.query({ size: 9999 }); // presumable load all entries
        vm.genres = Genre.query({ size: 9999 }); // presumable load all entries
        vm.musicBooks = MusicBook.query();

        vm.save = save;

        if(entity.id != null) {
        	vm.createMode = false;

            // transform fields for typeahead if necessary
            if(typeof vm.composition.genreId != 'object') {
                vm.composition.genreId = {
                    id: vm.composition.genreId,
                    name: vm.composition.genreName
                };
            }
            if(typeof vm.composition.orderingCompanyId != 'object') {
                vm.composition.orderingCompanyId = {
                    id: vm.composition.orderingCompanyId,
                    name: vm.composition.orderingCompanyName
                };
            }
            if(typeof vm.composition.publisherId != 'object') {
                vm.composition.publisherId = {
                    id: vm.composition.publisherId,
                    name: vm.composition.publisherName
                };
            }
        }
        else {
        	vm.createMode = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save () {
            vm.isSaving = true;

            var composition = {};
            angular.copy(vm.composition, composition);

            // transform fields from typeahead configuration to one usable by the rest-interface
            if(typeof composition.genreId === 'object' && composition.genreId !== null) {
                composition.genreId = composition.genreId.id;
            }
            else {
                composition.genreId = null;
            }
            if(typeof composition.orderingCompanyId === 'object' && composition.orderingCompanyId !== null) {
                composition.orderingCompanyId = composition.orderingCompanyId.id;
            }
            else {
                composition.orderingCompanyId = null;
            }
            if(typeof composition.publisherId === 'object' && composition.publisherId !== null) {
                composition.publisherId = composition.publisherId.id;
            }
            else {
                composition.publisherId = null;
            }

            if (composition.id !== null) {
                Composition.update(composition, onSaveSuccess, onSaveError);
            } else {
                Composition.save(composition, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:compositionUpdate', result);
            $state.go('composition', previousState.params, {reload : true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function goBack() {
        	$state.go(previousState.name,  previousState.params);
        }
    }
})();
