(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$filter', 'Principal', 'LoginService', '$state', 'Notification', 'AlertService', '$injector'];

    function HomeController ($scope, $filter, Principal, LoginService, $state, Notification, AlertService, $injector) {
        var vm = this;

        vm.notifications = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        // hide notifications after logout
        $scope.$watch(Principal.isAuthenticated, function(newValue, oldValue) {
            if(newValue === false) { vm.notifications = null; }
        });

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if(Principal.isAuthenticated()) {
                    Notification.query().$promise.then(function(data) {
                        vm.notifications = data.reduce(function(result, current) {
                            result[current.notificationRuleId] = result[current.notificationRuleId] || [];

                            var EntityService = $injector.get(current.notificationRuleEntity);
                            EntityService.get({ id: current.entityId }, function(data) {
                                var message = current.notificationRuleMessageSingle + '';

                                for(var key in data) {
                                    if (data[key] instanceof  Date) {
                                        message = message.replace('{{entity.' + key + '}}', $filter('date')(data[key]));
                                    } else {
                                        message = message.replace('{{entity.' + key + '}}', data[key]);
                                    }

                                }

                                current.message = message;
                            });
                            result[current.notificationRuleId].push(current);

                            return result;
                        }, {});
                    });
                }
                else {
                    vm.notifications = null;
                }
            });
        }
        function register () {
            $state.go('register');
        }

        vm.getMessageForGroup = function(group) {
            return group[0].notificationRuleMessageGroup.replace('{{count}}', group.length);
        };

        vm.getClassForNotification = function(notification) {
            return notification.readByUserId == null ? 'list-group-item-info' : '';
        };

        vm.readNotification = function(notification) {
            Notification.read({ id: notification.id }, onSuccess, onError);

            function onSuccess() {
                AlertService.success($filter('translate')('walterApp.notification.read'));
                $state.transitionTo($state.current, {}, { reload: true });
            };

            function onError(error) {
                AlertService.error(error.data.message);
            };
        };

        vm.deleteNotification = function(notification) {
            Notification.delete({ id: notification.id }, onSuccess, onError);

            function onSuccess() {
                $state.transitionTo($state.current, {}, { reload: true });
            };

            function onError(error) {
                AlertService.error(error.data.message);
            };
        };
    }
})();
