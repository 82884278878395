(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('TenantConfigurationController', TenantConfigurationController);

	TenantConfigurationController.$inject = ['$scope', '$state', '$filter', 'TenantConfiguration', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

	function TenantConfigurationController ($scope, $state, $filter, TenantConfiguration, ParseLinks, AlertService, pagingParams, paginationConstants) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;


		vm.updateConfiguration = function () {
            TenantConfiguration.updateMultitenancyConfiguration({}, onSuccessU, onErrorU);
            function onSuccessU() {
                loadAll();
            }
            function onErrorU(error) {
                AlertService.error(error.data.message);
                loadAll();
            }
        }

		loadAll();

		function loadAll () {
			TenantConfiguration.query({
				page: pagingParams.page - 1,
				size: vm.itemsPerPage,
				sort: sort()
			}, onSuccess, onError);
			function sort() {
				var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.tenantConfigurations = data;
				vm.page = pagingParams.page;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function loadPage (page) {
			vm.page = page;
			vm.transition();
		}

		function transition () {
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: vm.currentSearch
			});
		}

	}
})();
