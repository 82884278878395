(function() {
    'use strict';

    angular
        .module('walterApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'translationHandler', '$window',
        'Auth', 'Principal', 'VERSION', '$transitions'];

    function stateHandler($rootScope, $state, $sessionStorage, $translate, JhiLanguageService, translationHandler, $window,
        Auth, Principal, VERSION, $transitions) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.$state = $state;


            $rootScope.VERSION = VERSION;


            var stateChangeStart = $transitions.onStart( {}, function($transition$) {
                $rootScope.toState = $transition$.to();
                $rootScope.toStateParams = $transition$.params('to');
                $rootScope.fromState = $transition$.from();

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if ($rootScope.toState.external) {
                    $transition$.abort();
                    $window.open($rootScope.toState.url, '_self');
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }


                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });

            });
            // var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
            //     $rootScope.toState = toState;
            //     $rootScope.toStateParams = toStateParams;
            //     $rootScope.fromState = fromState;
            //
            //     // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
            //     if (toState.external) {
            //         event.preventDefault();
            //         $window.open(toState.url, '_self');
            //     }
            //
            //     if (Principal.isIdentityResolved()) {
            //         Auth.authorize();
            //     }
            //
            //
            //     // Update the language
            //     JhiLanguageService.getCurrent().then(function (language) {
            //         $translate.use(language);
            //     });
            //
            // });

            // var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
            //     var titleKey = 'global.title' ;
            //
            //     // Set the page title key to the one configured in state or use default one
            //     if (toState.data.pageTitle) {
            //         titleKey = toState.data.pageTitle;
            //     }
            //     translationHandler.updateTitle(titleKey);
            // });



            var stateChangeSuccess = $transitions.onSuccess( {}, function($transition$) {
                var titleKey = 'global.title' ;
                var toState = $transition$.to();
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);
            });


            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });
        }
    }
})();
