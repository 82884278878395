(function() {
	'use strict';

	angular
		.module('walterApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('tenant-configuration', {
			parent: 'admin',
			url: '/tenant-configuration?page&sort&search',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.tenantConfiguration.home.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/tenant-configuration/tenant-configurations.html',
					controller: 'TenantConfigurationController',
					controllerAs: 'vm'
				}
			},
			params: {
				page: {
					value: '1',
					squash: true
				},
				sort: {
					value: 'id,asc',
					squash: true
				},
				search: null
			},
			resolve: {
				pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
					return {
						page: PaginationUtil.parsePage($stateParams.page),
						sort: $stateParams.sort,
						predicate: PaginationUtil.parsePredicate($stateParams.sort),
						ascending: PaginationUtil.parseAscending($stateParams.sort),
						search: $stateParams.search
					};
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('tenantConfiguration');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})
		.state('tenant-configuration.new', {
			url: '/new',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.tenantConfiguration.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/tenant-configuration/tenant-configuration-edit.html',
					controller: 'TenantConfigurationEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('tenantConfiguration');
					return $translate.refresh();
				}],
				entity: ['$stateParams', function($stateParams) {
                    return {
                        tenantId: null,
                        schema: null,
                        dbConfiguration: {
                            url: null
                        }
					};
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name || 'tenant-configuration',
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
		.state('tenant-configuration-detail', {
            parent: 'tenant-configuration',
			url: '/{id}',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.tenantConfiguration.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/tenant-configuration/tenant-configuration-detail.html',
					controller: 'TenantConfigurationDetailController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('tenantConfiguration');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'TenantConfiguration', function($stateParams, TenantConfiguration) {
					return TenantConfiguration.get({id : $stateParams.id}).$promise;
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name || 'tenant-configuration',
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
		.state('tenant-configuration.edit', {
			url: '/{id}/edit',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.tenantConfiguration.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/tenant-configuration/tenant-configuration-edit.html',
					controller: 'TenantConfigurationEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('tenantConfiguration');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'TenantConfiguration', function($stateParams, TenantConfiguration) {
					return TenantConfiguration.get({ id : $stateParams.id }).$promise;
				}],
				previousState: ["$state", "$stateParams", function ($state, $stateParams) {
                    var currentStateData = {
                        name: $state.current.name || 'tenant-configuration',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
				}]
			}
		})
		.state('tenant-configuration.delete-view', {
			url: '/{id}/delete',
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/admin/tenant-configuration/tenant-configuration-delete-dialog.html',
					controller: 'TenantConfigurationDeleteController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['TenantConfiguration', function(TenantConfiguration) {
							return TenantConfiguration.get({id : $stateParams.id}).$promise;
						}]
					}
				}).result.then(function() {
					$state.go('tenant-configuration', null, { reload: 'tenant-configuration' });
				}, function() {
					$state.go('^');
				});
			}]
		})
		.state('tenant-configuration.delete-edit', {
			url: '/{id}/delete',
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/admin/tenant-configuration/tenant-configuration-delete-dialog.html',
					controller: 'TenantConfigurationDeleteController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['TenantConfiguration', function(TenantConfiguration) {
							return TenantConfiguration.get({id : $stateParams.id}).$promise;
						}]
					}
				}).result.then(function() {
					$state.go('tenant-configuration', null, { reload: 'tenant-configuration' });
				}, function() {
					$state.go('^');
				});
			}]
		});
	}

})();
