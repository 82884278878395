(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('EmailVerificationLink', EmailVerificationLink);

    EmailVerificationLink.$inject = ['$resource', 'DateUtils'];

    function EmailVerificationLink ($resource, DateUtils) {
        var resourceUrl =  'api/email-verification-link/:personId';

        return $resource(resourceUrl, {personId:'@personId'}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'create': {
                method: 'POST'
            },
            'invalidate': {
                method: 'DELETE'
            }
        });
    }
})();
