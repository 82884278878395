(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipController', MembershipController);

    MembershipController.$inject = ['$scope', '$state', '$stateParams', 'Membership', 'MembershipFeeForPeriod', 'PeriodsForPerson', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', '$window', 'UiaccService'];

    function MembershipController ($scope, $state, $stateParams, Membership, MembershipFeeForPeriod, PeriodsForPerson, ParseLinks, AlertService, pagingParams, paginationConstants, $window, UiaccService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = "id";
        vm.reverse = true;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.periodsForPerson = PeriodsForPerson.query({id : $stateParams.id});

        vm.selectedYear = null;
        vm.addFeeForPeriod = addFeeForPeriod;
        vm.years = [];
        vm.fillPossibleYears = fillPossibleYears;

        vm.changePaidAllowed = UiaccService.isEditable('membership');

        function onErrorPeriod(error) {
        	AlertService.error(error.data.message);
        }

        vm.changePaid = changePaid;
        vm.changeNote = changeNote;

        loadAll();


        function loadAll () {
            Membership.query({
                page: 0,
                size: 1000,
                personId: $stateParams.id
             //   sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.memberships = data;
                vm.page = 1;
                vm.fillPossibleYears(data);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function fillPossibleYears(data) {
            angular.forEach(data, function(membership) {
               var begin = new Date(membership.beginDate)

               var beginYear = begin.getFullYear();
               var endYear = null;
               if (membership.endDate) {
                   var end = new Date(membership.endDate);
                   endYear = end.getFullYear();
               } else {
                   endYear = beginYear + 10;
               }
               for(var i = beginYear; i <= endYear; i++) {
                   vm.years.push(i);
               }
            });
        }

        function addFeeForPeriod() {
            if (vm.selectedYear != null) {
                var a_data = {};
                a_data.year = vm.selectedYear;
                PeriodsForPerson.save({id : $stateParams.id},  a_data, onSuccessFeePeriod );


            }
            function onSuccessFeePeriod() {
                vm.periodsForPerson = PeriodsForPerson.query({id : $stateParams.id});
            }
        }


        function changePaid(periodId, nr, year, reference, paid, membershipId, note) {
        	vm.isSaving = true;
        	vm.period = MembershipFeeForPeriod.get({id: periodId});
        	if (paid == true) {
        		vm.period.paid = false;
        	}
        	else {
        		vm.period.paid = true;
        	}
        	vm.period.note = note;
        	vm.period.id = periodId;
        	vm.period.nr = nr;
        	vm.period.year = year;
        	vm.period.referenceCode = reference;
        	vm.period.membershipId = membershipId;
        	MembershipFeeForPeriod.update(vm.period, onSaveSuccess, onSaveError);
        }

        function changeNote(period) {
            MembershipFeeForPeriod.update(period, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.periodsForPerson = PeriodsForPerson.query({id : $stateParams.id});
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
