(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonInfoController', PersonInfoController);

    PersonInfoController.$inject = ['$scope', '$state', '$stateParams', 'Person'];

    function PersonInfoController ($scope, $state, $stateParams, Person) {
        var vmInfo = this;

        vmInfo.getPersonJson = getPersonJson;
        vmInfo.getPersonPdf = getPersonPdf;

        vmInfo.deletionDate = $scope.person.dateLostRelevance;

        function getPersonJson() {
            Person.getJson({personId : $stateParams.id});
        }

        function getPersonPdf() {
            Person.getPdf({personId : $stateParams.id});
        }
    }
})();
