(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Notification', 'UiaccService'];

    function NavbarController ($state, $scope, Auth, Principal, ProfileService, LoginService, Notification, UiaccService) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        vm.notifications = null;
        $scope.$watch(vm.isAuthenticated, function() {
            if(vm.isAuthenticated()) {
                Notification.count({}, function(data) {
                    vm.notifications = (data[1] || 0) + '/' + (data[0] || 0);
                }, function() {
                    vm.notifications = null;
                });
            }
            else {
                vm.notifications = null;
            }
        });

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
