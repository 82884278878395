(function() {
	'use strict';

	angular.module('walterApp')
			.controller('ImportController', ImportController);

	ImportController.$inject = [ '$scope', '$rootScope', '$state', '$filter', 'Upload',
			'BankImportData', 'APIImport', 'CashbookCategory', 'CashbookAccount'];

	function ImportController($scope, $rootScope, $state, $filter, Upload, BankImportData, APIImport, CashbookCategory, CashbookAccount) {
		var vm = this;

		vm.file = null;
		vm.importType = null;
		vm.cashbookCategoryId = null;
		vm.cashbookAccount = null;
		vm.username = null;
		vm.password = null;
		vm.from = null;
		vm.to = null;
		vm.bankImportData = BankImportData.query();
		vm.selectedBankImportData = [];
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.cashbookcategories = CashbookCategory.query();
		vm.cashbookAccounts = CashbookAccount.query({bankAccount: true});
		vm.allowedTypes = {
		  GEORGE_IMPORTER: ["CSV", "API"],
          BACA_IMPORTER: ["CSV"],
          RAIKA_IMPORTER: ["CSV"],
          EASYBANK_IMPORTER: ["CSV"]
        };
		vm.hasAutoTransfer = hasAutoTransfer;

		vm.doImport = doImport;
		vm.importCSV = importCSV;
		vm.addRemoveSelectedData = addRemoveSelectedData;
		vm.goBack = goBack;
		vm.isTypeAllowedForCurrentInstitute = isTypeAllowedForCurrentInstitute;

		function isTypeAllowedForCurrentInstitute(type) {
		    if (vm.cashbookAccount == null) {
		        return false;
            }

		    if (vm.allowedTypes[vm.cashbookAccount.bankImporterType].includes(type)) {
		        return true;
            }
        }

		function doImport() {
			vm.isSaving = true;
			if (vm.importType == 'CSV') {
				importCSV();
			} else if (vm.importType == 'API') {
				importAPI();
			}
		}

		function importCSV() {
		    var postData = {
		        csvFile : vm.file,
                cashbookAccountId : vm.cashbookAccount.id
            };

		    if (vm.cashbookCategoryId != null) {
		        postData['cashbookCategoryId'] = vm.cashbookCategoryId;
            }

			Upload.upload({
				url : 'api/bank-import-data/csv',
				method : 'POST',
				data : postData
			}).progress(function(evt) {
				vm.progress = parseInt(100.0 * evt.loaded / evt.total);
			}).success(onImportSuccess).error(onSaveError);
		}

		function importAPI() {
			APIImport.save({
				username : vm.username,
				password : vm.password,
				from : vm.from,
				to : vm.to,
				cashbookAccountId: vm.cashbookAccount.id,
                cashbookCategoryId : vm.cashbookCategoryId},
				onImportSuccess, onSaveError);
		}

		function addRemoveSelectedData(data) {

			var removed = false;

			for (var i = 0; i < vm.selectedBankImportData.length; i++) {
				if (vm.selectedBankImportData[i].id == data.id) {
					vm.selectedBankImportData.splice(i, 1);
					removed = true;
				}
			}

			if (!removed) {
				vm.selectedBankImportData.push(data);
			}
		}

		function onImportSuccess(result) {
			$scope.$emit('walterApp:bankImportDataUpdate', result);
			vm.isSaving = false;
			vm.bankImportData = BankImportData.query();
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function goBack() {
			$state.go('cashbook-entry');
		}

		vm.datePickerOpenStatus.from = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		function hasAutoTransfer() {
		    if (vm.bankImportData.length > 0) {
		        if ($filter('filter')(vm.bankImportData, function(data) {return data.transfer === true}).length > 0) {
		            return true;
                }
            }

		    return false;
        }
	}
})();
