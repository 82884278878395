(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('CashbookEntryController', CashbookEntryController);

	CashbookEntryController.$inject = ['$scope', '$state', '$stateParams', 'DateUtils', 'CashbookEntry', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'PaginationUtil', 'CashbookCategory', 'CashbookAccount', 'ColumnConfigService', 'CashbookRelatedEntities', 'FilterService'];

	function CashbookEntryController ($scope, $state, $stateParams, DateUtils, CashbookEntry, ParseLinks, AlertService, pagingParams, paginationConstants, PaginationUtil, CashbookCategory, CashbookAccount, ColumnConfigService, CashbookRelatedEntities, FilterService) {
		var vm = this;

        vm.listName = "CASHBOOK-ENTRY";

        /** Column-Config */

        ColumnConfigService.query(vm.listName, true, setColumnConfig);

        function setColumnConfig(columnConfig) {
            vm.columnConfig = columnConfig;
        }

        vm.listDateFormats = {
            date: "mediumDate"
        };

        vm.listPrices = {
            'walterApp.CashbookEntryType.INCOME': {
                printWhen: 'cashbookEntry.type == "INCOME"'
            },
            'walterApp.CashbookEntryType.SPENDING': {
                printWhen: 'cashbookEntry.type == "SPENDING"'
            },
        };

        vm.listColorFields = {
            cashbookCategoryName: 'cashbookCategoryColor'
        }

        pagingParams.search = $stateParams.search;
        pagingParams.sort = $stateParams.sort;
        pagingParams.predicate = PaginationUtil.parsePredicate($stateParams.sort);
        pagingParams.ascending = PaginationUtil.parseAscending($stateParams.sort);

        vm.loadPage = loadPage;
        vm.resetSearch = resetSearch;
        vm.switchSearch = switchSearch;
        vm.isSearchDataDirty = isSearchDataDirty;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.switchPage = switchPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.cashbookcategories = CashbookRelatedEntities.getCashbookCategories();
        vm.cashbookAccounts = CashbookRelatedEntities.getCashbookAccounts();
        vm.isReportExportOpen = false;
		vm.saldo = 0;
		CashbookEntry.saldo().$promise.then(function(data) { vm.saldo = data.value; });

        var currentSearch = pagingParams.search;
        if (currentSearch)
        {
            vm.search = angular.fromJson(currentSearch);

            loadAll();
        }
        else  {
            resetSearch();
        }

        vm.searchActive = vm.search.search;

        function loadAll (noFilterReset) {
            if (!noFilterReset) {
                FilterService.resetFilterSelection();
            }
            var dateFormat = 'yyyy-MM-dd';

            var dateFrom = vm.search.dateFrom ? DateUtils.convertLocalDateToServer(vm.search.dateFrom) : null;
            var dateTo = vm.search.dateTo ? DateUtils.convertLocalDateToServer(vm.search.dateTo) : null;

            CashbookEntry.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                title: vm.search.title,
                type: vm.search.type,
                categoryId: vm.search.categoryId,
                accountId: vm.search.accountId,
                dateFrom: dateFrom,
                dateTo: dateTo,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.cashbookEntries = data;
                vm.page = pagingParams.page;
                transition();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function switchPage() {
            pagingParams.page = vm.page;
            transition();
        }

        function transition () {
            vm.search.search = vm.searchActive;
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: angular.toJson(vm.search)
            });
        }

        function switchSearch() {
            if (vm.searchActive) {
                vm.searchActive = false;
            } else {
                vm.searchActive = true;
            }
            transition();
        }

        function resetSearch() {
            vm.compositions = null;
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = {
                title: null,
                type: null,
                categoryId: null,
                dateFrom: null,
                dateTo: null
            };
            loadAll();
        }

		// for search
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;

		function isSearchDataDirty() {
			return (vm.search.title !== null && vm.search.title.length > 0) ||
				vm.search.type !== null ||
				vm.search.categoryId !== null ||
                vm.search.accountId != null ||
				(vm.search.dateFrom !== null && vm.search.dateFrom.length > 0) ||
				(vm.search.dateTo !== null && vm.search.dateTo.length > 0);
		}

        function setSearch(search) {
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = search;
            loadAll(true);
        }

        function getCurrentSearch() {
            return vm.search;
        }

        FilterService.setResetSearchFun(resetSearch);
        FilterService.setSearchFun(setSearch);
        FilterService.getCurrentSearchFun(getCurrentSearch);
        FilterService.setIsVisible(true);

		// for export
		vm.export = {
			from: new Date((new Date()).getFullYear(), 0, 1),
			to: new Date(),
			categories: []
		};

		vm.exportReport = exportReport;
		function exportReport() {
			CashbookEntry.report({
				from: DateUtils.convertLocalDateToServer(vm.export.from),
				to: DateUtils.convertLocalDateToServer(vm.export.to),
				categories: vm.export.categories
			}, onSuccess, onError);

			function onSuccess(data, headers) {
				var blob = new Blob([data.data], { type: 'application/pdf' });
				saveAs(blob, data.filename);
			};

			function onError(error) {
				AlertService.error(error.data.message);
			};
		}

		vm.datePickerOpenStatus.dateTo = false;
		vm.datePickerOpenStatus.dateFrom = false;
		vm.datePickerOpenStatus.exportTo = false;
		vm.datePickerOpenStatus.exportFrom = false;

		function openCalendar (date) {
			vm.datePickerOpenStatus[date] = true;
		}
	}
})();
