(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipFeeForPeriodDeleteController',MembershipFeeForPeriodDeleteController);

    MembershipFeeForPeriodDeleteController.$inject = ['$uibModalInstance', 'entity', 'MembershipFeeForPeriod'];

    function MembershipFeeForPeriodDeleteController($uibModalInstance, entity, MembershipFeeForPeriod) {
        var vm = this;

        vm.membershipFeeForPeriod = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MembershipFeeForPeriod.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
