(function () {
    'use strict';

    angular
        .module('walterApp')
        .controller('AppointmentTypeEditController', AppointmentTypeEditController);

    AppointmentTypeEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams',
        'previousState', 'entity', 'AppointmentType', 'Appointment', 'PersonGroup'];

    function AppointmentTypeEditController($timeout, $scope, $rootScope, $state, $stateParams,
                                           previousState, entity, AppointmentType, Appointment, PersonGroup) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.appointmentType = entity;
        vm.personGroups = PersonGroup.query();

        vm.createMode = (entity.id == null);

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save() {
            vm.isSaving = true;
            if (vm.appointmentType.id !== null) {
                AppointmentType.update(vm.appointmentType, onSaveSuccess, onSaveError);
            } else {
                AppointmentType.save(vm.appointmentType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('walterApp:appointmentTypeUpdate', result);
            $state.go(previousState.name);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
