(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CustomizationController', CustomizationController);

    CustomizationController.$inject = ['$state', 'Customization', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function CustomizationController($state, Customization, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.DEACTIVATED_FEATUREGROUPS = 'DEACTIVATED_FEATUREGROUPS';
        vm.AKM_DATA = 'AKM_DATA';

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.deactivatedFeatureGroupsTextArray = [];

        loadAll();


        function loadAll () {
            Customization.query({
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customizations = data;
                vm.page = pagingParams.page;
                adaptText();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function adaptText() {
            vm.customizations.forEach(function(customization) {
                if (customization.name === vm.DEACTIVATED_FEATUREGROUPS && customization.text != "") {
                       var textArr = customization.text.split(", ");
                       textArr.forEach(function(part) {
                            vm.deactivatedFeatureGroupsTextArray.push(part);
                       });
                }
                if (customization.name === vm.AKM_DATA) {
                    if (angular.equals(angular.fromJson(customization.data), {})) {
                        customization.text = "";
                    } else {
                        customization.text = "available";
                    }
                }

            });
        }
    }
})();
