(function() {
    'use strict';

    angular.uppercase=function(text){
        if (text) {
            return text.toUpperCase();
        }
        return text;
    }
    angular.lowercase=function(text){
        if (text) {
            return text.toLowerCase();
        }
        return text;
    }

    angular
        .module('walterApp', [
            'mwl.calendar',
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ngAnimate',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.tinymce',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ngFileSaver'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
