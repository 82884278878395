(function () {
    'use strict';

    angular
        .module('walterApp')
        .controller('AppointmentEditController', AppointmentEditController);

    AppointmentEditController.$inject = ['$timeout', '$state', '$scope', '$rootScope', '$stateParams', '$translate', 'previousState',
        'entity', 'Appointment', 'CashbookEntry', 'Person', 'AppointmentType'];

    function AppointmentEditController($timeout, $state, $scope, $rootScope, $stateParams, $translate, previousState,
                                       entity, Appointment, CashbookEntry, Person, AppointmentType) {
        var vm = this;

        vm.appointment = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.goBack = goBack;

        vm.cashbookentries = CashbookEntry.query();
        vm.people = Person.query();
        vm.appointmenttypes = AppointmentType.query();
        vm.previousState = previousState;

        vm.dateTimeButtonBar = {
        		    show: true,
                    now: {
                    	show: true,
                    	text: $translate.instant('calendar.now')
                    },
                    today: {
                    	show: true,
                        text: $translate.instant('calendar.current')
                    },
                    clear: {
                    	show: true,
                    	text: $translate.instant('calendar.clear')
                    },
                    date: {
                    	show: true,
                    	text: $translate.instant('calendar.date')
                    },
                    time: {
                        show: true,
                        text: $translate.instant('calendar.time')
                    },
                    close: {
                        show: true,
                        text: $translate.instant('calendar.close')
                    }
                };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.appointment.isHeadQuota != true) {
            	vm.appointment.isHeadQuota = false;
            }

            if (vm.appointment.id !== null) {
                Appointment.update(vm.appointment, onSaveSuccess, onSaveError);
            } else {
                Appointment.save(vm.appointment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('walterApp:appointmentUpdate', result);
            vm.isSaving = false;
            $state.go('appointment', previousState.params, {reload : true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function changeType() {
        	for (var i = 0; i < vm.appointmenttypes.length; i++) {
        		if (vm.appointmenttypes[i].id == vm.appointment.typeId) {
        			vm.appointment.isOfficial = vm.appointmenttypes[i].isOfficial;
        			return;
        		}
        	}
        	vm.appointment.isOfficial = false;
        }

        vm.changeType = changeType;

        vm.datePickerOpenStatus.beginDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function goBack() {
            $state.go(previousState.name,  previousState.params);
        }
    }
})();
