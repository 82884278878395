(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonEditController', PersonEditController);

    PersonEditController.$inject = ['$state', '$timeout', '$scope', '$stateParams', 'previousState', 'entity', 'Person'];

    function PersonEditController ($state, $timeout, $scope, $stateParams,  previousState, entity, Person) {
        var vm = this;

        vm.person = entity;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.previousState = previousState;
        vm.createMode = false;
        vm.back = back;

        if (vm.person.id != null) {
            vm.createMode = false;
        } else {
            vm.createMode = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function save () {
            vm.isSaving = true;
            if (vm.person.id !== null) {
                Person.update(vm.person, onSaveSuccess, onSaveError);
            } else {
                Person.save(vm.person, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
            if (previousState.params.id) {
                $state.go(vm.previousState.name, {"id": vm.previousState.params.id}, {reload : true});
            } else {
                $state.go(vm.previousState.name, {}, {reload : true});
            }
        }
        vm.datePickerOpenStatus.birthDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
