(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('InstrumentEditController', InstrumentEditController);

    InstrumentEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Instrument', 'InstrumentInstrumentType'];

    function InstrumentEditController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, Instrument, InstrumentInstrumentType) {
        var vm = this;
        vm.previousState = previousState;
        vm.instrument = entity;
        vm.save = save;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.instrumenttypes = InstrumentInstrumentType.query();
        vm.maxDate = new Date();
        vm.goBack = goBack;

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.instrument.id !== null) {
            	Instrument.update(vm.instrument, onSaveSuccess, onSaveError);
            } else {
            	Instrument.save(vm.instrument, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:instrumentUpdate', result);
            $state.go('instrument', previousState.params, {reload : true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.purchaseDate = false;

        function openCalendar (date) {
        	vm.datePickerOpenStatus[date] = true;
        }

        vm.dateOptions = {
        	  maxDate: vm.maxDate
        };

        function goBack() {
        	$state.go(previousState.name,  previousState.params);
        }
    }
})();
