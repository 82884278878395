(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Template', Template);

    Template.$inject = ['$resource'];

    function Template ($resource) {
        var resourceUrl =  'api/templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'download': {
                method: 'GET',
                url: 'api/templates/file/:uuid',
                responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    var header = headers('content-disposition');
                    var result = header.split(';')[1].trim().split('=')[1];
                    var filename = result.replace(/"/g, '');

                    return {
                        data: data,
                        filename: filename
                    }
                }
            }
        });
    }
})();
