(function () {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('appointment-overview', {
                parent: 'appointments',
                abstract: true,
                views: {
                    'filterbar@': {
                        templateUrl: 'app/layouts/navbar/filterbar.html',
                        controller: 'FilterbarController',
                        controllerAs: 'vm'

                    }
                },
                onExit: ['AppointmentRelatedEntities', function(AppointmentRelatedEntities) {
                    AppointmentRelatedEntities.invalidate();
                }]
            })
            .state('appointment', {
                parent: 'appointment-overview',
                url: '/appointment?page&sort&search',
                type: 'overview',
                listName: "APPOINTMENT",
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointment.home.title'
                },
                views: {
                    'content-overview@': {
                        templateUrl: 'app/appointments/appointment/appointments.html',
                        controller: 'AppointmentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'beginDate,desc',
                        squash: true
                    },
                    search: {
                        value: null,
                        dynamic: true
                    },
                    mode: {
                        value: 1,
                        dynamic: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            mode: $stateParams.mode
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointment');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('columnConfig');
                        $translatePartialLoader.addPart('filter');
                        return $translate.refresh();
                    }]
                }
            })
            .state('appointment.column-config-edit', {
                type: 'overview',
                url: '/column-config/{entity}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/column-config/column-config-dialog.html',
                        controller: 'ColumnConfigController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            columnConfig: ['ColumnConfig', function(ColumnConfig) {
                                return ColumnConfig.query({entity : $stateParams.entity}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('columnConfig');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('appointment');
                                return $translate.refresh();
                            }]
                        }
                    })
                    .result.then(function() {
                        $state.go('appointment', $stateParams, { reload: 'appointment' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('appointment.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointment-detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/appointments/appointment/appointment-edit.html',
                        controller: 'AppointmentEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    	$translatePartialLoader.addPart('officialAppointment');
                    	$translatePartialLoader.addPart('appointment');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Appointment', function ($stateParams, Appointment) {
                        return {
                            id: null,
                            name: null,
                            description: null,
                            streetAddress: null,
                            postalCode: null,
                            city: null,
                            country: null,
                            beginDate: null,
                            endDate: null,
                            isOfficial: false,
                            organizerName: null,
                            organizerAddress: null,
                            isHeadQuota: false
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'appointment',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('appointment-detail', {
                parent: 'appointment',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointment-detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/appointments/appointment/appointment-detail.html',
                        controller: 'AppointmentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointment');
                        $translatePartialLoader.addPart('composition');
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('officialAppointment');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Appointment', function ($stateParams, Appointment) {
                        return Appointment.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'appointment',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('appointment-detail.edit', {
                url: '/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/inventory/appointment/appointment-edit.html',
                        controller: 'AppointmentEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointment');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Appointment', function($stateParams, Appointment) {
                        return Appointment.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'appointment-detail',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('appointment-detail.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/inventory/appointment/appointment-delete-dialog.html',
                        controller: 'AppointmentDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Appointment', function(Appointment) {
                                return Appointment.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('appointment', $stateParams, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('appointment-edit', {
                parent: 'appointment',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointment.edit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/appointments/appointment/appointment-edit.html',
                        controller: 'AppointmentEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointment');
                        $translatePartialLoader.addPart('officialAppointment');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Appointment', function ($stateParams, Appointment) {
                        return Appointment.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'appointment',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }]
                }
            })
            .state('appointment-edit.delete', {
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/appointments/appointment/appointment-delete-dialog.html',
                        controller: 'AppointmentDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Appointment', function (Appointment) {
                                return Appointment.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('appointment', $stateParams, { reload: 'appointment' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('appointment-detail.removeComposition', {
                url: '/compositions/delete/{compositionId}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/appointments/appointment/appointment-removeComposition-dialog.html',
                        controller: 'AppointmentRemoveCompositionController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Composition', function(Composition) {
                                return Composition.get({id : $stateParams.compositionId}).$promise;
                            }],
                            appointment: ['Appointment', function(Appointment) {
                                return Appointment.get({id : $stateParams.id}).$promise;
                            }],
                        }
                    }).result.then(function() {
                        $state.go('appointment-detail', null, { reload: 'appointment-detail' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
