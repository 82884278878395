(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('AppointmentRemoveCompositionController',AppointmentRemoveCompositionController);

    AppointmentRemoveCompositionController.$inject = ['$uibModalInstance', 'entity', 'appointment', 'Composition', 'CompositionsForAppointment'];

    function AppointmentRemoveCompositionController($uibModalInstance, entity, appointment, Composition, CompositionsForAppointment) {
        var vm = this;

        vm.appointment = appointment;
        vm.composition = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
        	CompositionsForAppointment.delete({id : vm.appointment.officialAppointmentId, compositionId : vm.composition.id}, vm.composition,
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
