(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('EmailVerificationLetterEditController',  EmailVerificationLetterEditController);


    EmailVerificationLetterEditController.$inject = ['FileSaver', 'Blob',  '$uibModal', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'EmailVerificationLetter', 'EmailVerificationLetterTemplate', 'EmailVerificationLetterPersonGroup'];

    function EmailVerificationLetterEditController(FileSaver, Blob, $uibModal, $state, $scope, $rootScope, $stateParams, previousState, DataUtils, entity, EmailVerificationLetter, EmailVerificationLetterTemplate, EmailVerificationLetterPersonGroup) {
        var vm = this;

        vm.emailVerificationLetter = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.loadEmailVerificationLetter = loadEmailVerificationLetter;
        vm.clearId = clearId;
        vm.save = save;
        vm.templates = EmailVerificationLetterTemplate.query();
        vm.persongroups = EmailVerificationLetterPersonGroup.query();

        vm.emailVerificationLetters = EmailVerificationLetter.query();
        vm.generate = generate;
        vm.personGroupsFlat = [];
        vm.currentChosenPersonGroup = null;
        vm.addCurrentPersonGroup = addCurrentPersonGroup
        vm.emailVerificationLetter.personGroups = [];
        vm.removePersonGroup = removePersonGroup;
        vm.tinyMceOptions = {
            language: 'de_AT',
            menu: {
              edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
              format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'}
            },
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright',
            height: 300
        };


        loadAllEmailVerificationLetters();
        buildUpPersonGroupDropDown();



        function buildUpPersonGroupDropDown() {
            vm.personGroupsFlat = [];
            EmailVerificationLetterPersonGroup.query().$promise.then(
                function (result) {

                    var personGroupsHier = treeify(result);

                    personGroupsHier.forEach(function (item) {
                        recur(item, 0, vm.personGroupsFlat);
                    });
                },
                function (error) {

                }
            );
        }


        function removePersonGroup(id) {
            if (id) {
                vm.emailVerificationLetter.personGroups.forEach(function (item, i) {
                    if (item.id == id ) {
                        vm.emailVerificationLetter.personGroups.splice(i, 1);
                    }
                });
                buildUpPersonGroupDropDown();
            }

        }

        function addCurrentPersonGroup() {
            if (vm.currentChosenPersonGroup) {
                if (vm.emailVerificationLetter.personGroups.indexOf(vm.currentChosenPersonGroup) == -1 ) {
                    vm.emailVerificationLetter.personGroups.push(vm.currentChosenPersonGroup);
                    buildUpPersonGroupDropDown()
                }
            }
        }

        function times (n, str) {
            var result = '';
            for (var i = 0; i < n; i++) {
                result += str;
            }
            return result;
        };


        function recur(item, level, arr) {
            var contains = false;
            if (vm.emailVerificationLetter.personGroups) {
                vm.emailVerificationLetter.personGroups.forEach(function (pgItem) {
                    if (pgItem.id == item.id) {
                        contains = true;
                    }
                });
            }
            if (!contains) {
                arr.push({
                    name: item.name,
                    id: item.id,
                    level: level,
                    text: times(level, '–') + " " + item.name
                });

                if (item.children) {
                    item.children.forEach(function (item) {
                        recur(item, level + 1, arr);
                    });
                }
            }
        };


        // http://stackoverflow.com/questions/22367711/construct-hierarchy-tree-from-flat-list-with-parent-field
        function treeify(list, idAttr, parentAttr, childrenAttr) {
            if (!idAttr) idAttr = 'id';
            if (!parentAttr) parentAttr = 'parentId';
            if (!childrenAttr) childrenAttr = 'children';

            var treeList = [];
            var lookup = {};
            list.forEach(function(obj) {
                lookup[obj[idAttr]] = obj;
                obj[childrenAttr] = [];
            });
            list.forEach(function(obj) {
                if (obj[parentAttr] != null) {
                    lookup[obj[parentAttr]][childrenAttr].push(obj);
                } else {
                    treeList.push(obj);
                }
            });
            return treeList;
        };


        function loadAllEmailVerificationLetters() {
            vm.emailVerificationLetters = EmailVerificationLetter.query();

        }

        function loadEmailVerificationLetter() {
            if (vm.emailVerificationLetterId) {
                vm.emailVerificationLetter = EmailVerificationLetter.get({id : vm.emailVerificationLetterId});
            } else {
                vm.emailVerificationLetter.id  = null;
                vm.emailVerificationLetter.text = null;
                vm.emailVerificationLetter.personGroups = null;
                vm.emailVerificationLetter.templateId = null;
                vm.emailVerificationLetter.title = null;
                vm.emailVerificationLetter.letterType = 'EMAIL_VERIFICATION_LETTER';
            }
        }

        function clearId() {
            var exist = false;
            vm.emailVerificationLetters.forEach(function(l) {
                if (l.title == vm.emailVerificationLetter.title) {
                    vm.emailVerificationLetterId = l.id;
                    vm.emailVerificationLetter = l;
                    exist = true;
                }
            });
            if (!exist) {
                vm.emailVerificationLetter.id  = null;
                vm.emailVerificationLetterId = null;
            }

        }

        function save () {

            if (vm.saveAs) {
                vm.isSaving = true;
                var update = false;

                vm.emailVerificationLetters.forEach(function (l) {
                    if (l.title == vm.emailVerificationLetter.title) {

                        vm.emailVerificationLetter.id = l.id;

                        $uibModal.open({
                            templateUrl: 'app/letters/email-verification-letters/emailverificationletter-overwrite-dialog.html',
                            controller: 'EmailVerificationLetterOverwriteController',
                            controllerAs: 'vmO',
                            size: 'md',
                            resolve: {
                                entity: vm.emailVerificationLetter
                            }
                        }).result.then(function () {
                            EmailVerificationLetter.update(vm.emailVerificationLetter, onUpdateSuccess, onSaveError);
                        }, function () {
                            onSaveError();
                        });


                        update = true;
                    }
                });

                if (!update) {
                    vm.emailVerificationLetter.id = null;
                    EmailVerificationLetter.save(vm.emailVerificationLetter, onSaveSuccess, onSaveError);
                }
            }

        }

        function generate( ) {
            if (vm.emailVerificationLetter) {
                EmailVerificationLetter.generate(vm.emailVerificationLetter, onGenerateSuccess, onGenerateError);
            }
        }

        function onGenerateSuccess(result ) {
            result = null;
        }

        function onGenerateError(result ) {
            result = null;

        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:emailVerificationLetterUpdate', result);
            vm.isSaving = false;
            vm.emailVerificationLetter = result;
            loadAllEmailVerificationLetters();
            vm.emailVerificationLetterId = vm.emailVerificationLetter.id;

        }

        function onUpdateSuccess (result) {
            $scope.$emit('walterApp:emailVerificationLetterUpdate', result);
            vm.isSaving = false;
            vm.emailVerificationLetter = result;
            vm.emailVerificationLetterId = vm.emailVerificationLetter.id;

        }



        function onSaveError () {
            vm.isSaving = false;
        }




        var unsubscribe = $rootScope.$on('walterApp:emailVerificationLetterUpdate', function(event, result) {
            vm.emailVerificationLetter = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
