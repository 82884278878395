(function() {
    'use strict';

    angular
        .module('walterApp')
        .directive('uiacc', uiacc);

    uiacc.$inject = ['UiaccService'];

    function uiacc(UiaccService) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {
                    var result;
                    if (reset) {
                        setVisible();
                    }
                    if (write) {
                        result = UiaccService.isEditable(target);
                    } else {
                        result = UiaccService.isDisplayable(target);
                    }
                    if (result) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };

            var target = attrs.uiacc;
            var write = false;


            if (target === "menu") {
                //define visibility based on li-children

                var show = false;
                //find first ul children
                var list = element.find("ul")[0];
                if (!list) {
                    return
                }
                var listElements = list.getElementsByTagName("LI");

                //get through all list elements
                for (var i = 0; i < listElements.length; ++i) {
                    var a = listElements[i].getElementsByTagName("a")[0];
                    var asref = a.getAttribute("ui-sref");
                    show = show || UiaccService.isDisplayable(asref);
                    if (show) {
                        break;
                    }
                }
                if (!show) {
                    setHidden();
                }
                return;
            }

            if (!target) {
                target =  attrs.uiSref;
            }


            if (target) {
                var res = UiaccService.getTarget(target);
                if (res) {
                    write = res.write;
                    target = res.name;
                }
            } else {
                return;
            }


            if (target) {
                defineVisibility(true);

            }
        }
    }
})();
