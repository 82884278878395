(function() {
    'use strict';

    angular
        .module('walterApp')
        .provider('UiaccService', UiaccService);

    function UiaccService() {

        /*jshint validthis: true */
        this.$get = getService;


        getService.$inject = [];

        function getService () {

            var accountData;

            var displayElements;

            var writeElements;

            var targetElements = new Map();

            return {
                setAccessRules: setAccessRules,
                isDisplayable: isDisplayable,
                isEditable: isEditable,
                getTarget: getTarget
            };

            function getTarget(stateRef) {
                var cached = targetElements.get(stateRef);
                if (cached) {
                    return cached
                }
                var result = {};
                result.write = false;
                result.name = "";

                if(stateRef && stateRef.indexOf(".") > -1) {
                    var targetArray = stateRef.split('.');
                    var target = "";
                    var i = 0;
                    var editI = -1;
                    targetArray.forEach(function (item) {
                        if (item.indexOf('delete', 0) === 0 ||
                            item.indexOf('edit', 0) === 0 |
                            item.indexOf('new', 0) === 0) {
                            result.write = true;
                            editI = i;
                        }
                        i++;
                    });
                    if (editI > -1) {
                        target = "";
                        for (var j = 0; j < editI; j++) {
                            if (j > 0) {
                                target = target + ".";
                            }
                            target = target + targetArray[j];
                        }
                    }
                } else {
                    target = stateRef;
                }
                result.name = target;
                targetElements.set(stateRef, result);
                return result;
            }

            function setAccessRules(account) {
                accountData = account.data;
                displayElements = accountData.displayElements;
                writeElements = accountData.writeElements;
            }

            function isEditable(element) {
                if (!writeElements) {
                    return false;
                }
                return writeElements.includes(element);
            }

            function isDisplayable(element) {
                if (!displayElements || !element) {
                    return false;
                }
                return displayElements.includes(element);
            }


        }
    }
})();
