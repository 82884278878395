(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('InstrumentDetailController', InstrumentDetailController);

    InstrumentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Instrument', 'ServicesForInstrument', 'PersonsForInstrument', 'giveInstrument', 'UiaccService'];

    function InstrumentDetailController($scope, $rootScope, $stateParams, previousState, entity, Instrument, ServicesForInstrument, PersonsForInstrument, giveInstrument, UiaccService) {
        var vm = this;
        vm.instrument = entity;
        vm.servicesForInstrument = ServicesForInstrument.query({id : entity.id});
        vm.personsForInstrument = PersonsForInstrument.query({id : entity.id});
        vm.previousState = previousState.name;
        vm.showServices = UiaccService.isDisplayable('instrument-service')
        vm.showPeople = UiaccService.isDisplayable('person-instrument'),
        vm.tab = getActiveTab()
        var unsubscribe = $rootScope.$on('walterApp:instrumentUpdate', function(event, result) {
            vm.instrument = result;
        });

        $scope.$on('$destroy', unsubscribe);

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        giveInstrument.setPurchaseDate(vm.instrument.purchaseDate);

        function getActiveTab() {
            if (vm.showServices && vm.showPeople) {
                return 0;
            } else
            if (vm.showPeople) {
                return 1;
            }
            else {
                return 0;
            }
        }
    }
})();

angular.module('walterApp').factory('giveInstrument', function () {
        	var data = {
        		purchaseDate: ''
        	};

        	return {
        		getPurchaseDate: function () {
        			return data.purchaseDate;
        		},
        		setPurchaseDate: function (purchaseDate) {
        			data.purchaseDate = purchaseDate;
        		}
        	};
        });
