(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('cashbook-entry.cashbook-account-transfer', {
                url: '/cashbook-account-transfer',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.cashbookAccountTransfer.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/accounting/cashbook-account-transfer/cashbook-account-transfer.html',
                        controller: 'CashbookAccountTransferController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cashbookAccountTransfer');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'cashbook-entry',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }
})();
