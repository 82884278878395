(function () {
    'use strict';
    angular
        .module('walterApp')
        .factory('Appointment', Appointment);

    Appointment.$inject = ['$resource', 'DateUtils'];

    function Appointment($resource, DateUtils) {
        var resourceUrl = 'api/appointments/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.beginDate = DateUtils.convertDateTimeFromServer(data.beginDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'save': { method: 'POST' },
            'report': {
                method: 'POST',
                url: '/api/appointments/akmexport',
                responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    var header = headers('content-disposition');
                    var result = header.split(';')[1].trim().split('=')[1];
                    var filename = result.replace(/"/g, '');

                    return {
                        data: data,
                        filename: filename
                    }
                }
            },
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('AppointmentsForAppointment', AppointmentsForAppointment);

    AppointmentsForAppointment.$inject = ['$resource'];

    function AppointmentsForAppointment ($resource) {
        var resourceUrl =  'api/official-appointments/:id/compositions/:compositionId';

        return $resource(resourceUrl, {id: '@id', compositionId: '@compositionId'}, {
            'query': { method: 'GET', isArray: true},
        	'update': { method: 'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PeopleForPersonGroup', PeopleForPersonGroup);

    PeopleForPersonGroup.$inject = ['$resource'];

    function PeopleForPersonGroup ($resource) {
        var resourceUrl =  'api/appointments/person-groups/:id/people';

        return $resource(resourceUrl, {id: '@id'}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('AvailableAppointments', AvailableAppointments);

    AvailableAppointments.$inject = ['$resource'];

    function AvailableAppointments ($resource) {

        var resourceUrl =  'api/official-appointments/compositions';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PeopleForAppointment', PeopleForAppointment);

    PeopleForAppointment.$inject = ['$resource'];

    function PeopleForAppointment ($resource) {
        var resourceUrl =  'api/appointments/:id/people/:personId';

        return $resource(resourceUrl, {id: '@id', personId: '@personId'}, {
            'query': { method: 'GET', isArray: true},
            'update': { method: 'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('AvailableCompositions', AvailableCompositions);

    AvailableCompositions.$inject = ['$resource'];

    function AvailableCompositions ($resource) {

        var resourceUrl =  'api/official-appointments/compositions';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('CompositionsForAppointment', CompositionsForAppointment);

    CompositionsForAppointment.$inject = ['$resource'];

    function CompositionsForAppointment ($resource) {
        var resourceUrl =  'api/official-appointments/:id/compositions/:compositionId';

        return $resource(resourceUrl, {id: '@id', compositionId: '@compositionId'}, {
            'query': { method: 'GET', isArray: true},
            'update': { method: 'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('AppointmentRelatedEntities', AppointmentRelatedEntities);

    AppointmentRelatedEntities.$inject = ['$resource'];

    function AppointmentRelatedEntities ($resource) {
        var me = this;

        var appointmentTypes;

        this.getAppointmentTypes = getAppointmentTypes;
        this.invalidate = invalidate;

        function getAppointmentTypes() {
            if (!appointmentTypes) {
                appointmentTypes = AppointmentTypeResource().query();
            }
            return appointmentTypes;
        }

        function invalidate() {
            appointmentTypes = null;
        }

        function AppointmentTypeResource() {
            var resourceUrl =  'api/appointment-types';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true}
            });
        }

        return this;
    }
})();
