(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('TenantConfigurationDetailController', TenantConfigurationDetailController);

    TenantConfigurationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TenantConfiguration'];

    function TenantConfigurationDetailController($scope, $rootScope, $stateParams, previousState, entity, TenantConfiguration) {
        var vm = this;

        vm.tenantConfiguration = entity;
        vm.previousState = previousState.name;
        vm.connectionUseText = '';
        changeConnectionUseText();
        vm.updateConnection = function () {
            TenantConfiguration.updateDbConnection(entity.tenantId);
        }
        vm.refresh = function() {
            vm.tenantConfiguration = TenantConfiguration.get({id: $stateParams.id}, onSuccess);

            function onSuccess() {
                changeConnectionUseText()
            };
        }


        function changeConnectionUseText () {
            switch (vm.tenantConfiguration.status.usedConnectionStatus) {
                case 'OTHER':
                    vm.connectionUseText =  "walterApp.tenantConfiguration.otherConnectionInUse";
                    break;
                case 'NONE':
                    vm.connectionUseText = "walterApp.tenantConfiguration.noConnectionInUse";
                    break;
                case 'CURRENT':
                    vm.connectionUseText = "walterApp.tenantConfiguration.thisConnectionInUse";
                    break;
                default:

            }

        }
        var unsubscribe = $rootScope.$on('walterApp:tenantConfigurationUpdate', function(event, result) {
            vm.tenantConfiguration = result;
        });
        $scope.$on('$destroy', unsubscribe);


    }
})();
