(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('CashbookEntryEditController', CashbookEntryEditController);

	CashbookEntryEditController.$inject = ['$timeout', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CashbookEntry', 'Composition', 'InstrumentService', 'MembershipFeeForPeriod', 'Appointment', 'CashbookCategory', 'Receipt', 'CashbookAccount', '$filter', '$uibModal'];

	function CashbookEntryEditController($timeout, $state, $scope, $rootScope, $stateParams, previousState, entity, CashbookEntry, Composition, InstrumentService, MembershipFeeForPeriod, Appointment, CashbookCategory, Receipt, CashbookAccount, $filter, $uibModal) {
		var vm = this;

		vm.cashbookEntry = entity;
		vm.clear = clear;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.save = save;
		vm.goBack = goBack;

		vm.appointments = Appointment.query();
		vm.cashbookcategories = CashbookCategory.query();
		vm.receipts = Receipt.query();
		vm.cashbookAccounts = CashbookAccount.query();
		vm.previousState = previousState;
		vm.datepickerOptions = {
			maxDate: new Date()
		};

		vm.appointment = null;
		if(vm.cashbookEntry.appointmentId > 0) {
			vm.appointment = {
				id: vm.cashbookEntry.appointmentId,
				name: vm.cashbookEntry.appointmentName,
				beginDate: vm.cashbookEntry.appointmentBeginDate
			};
		}

		vm.formatAppointmentLabel = formatAppointmentLabel;
		function formatAppointmentLabel(appointment) {
			var begin = new Date(appointment.beginDate);
			return $filter('date')(begin, 'dd.MM.yyyy') + ': ' + appointment.name;
		}

		vm.isCreatedByForeignRelation = isCreatedByForeignRelation;
		function isCreatedByForeignRelation() {
			var compositions = vm.cashbookEntry.compositions && vm.cashbookEntry.compositions.length === 0;
			var instrumentService = vm.cashbookEntry.instrumentServiceId === null;
			var instrument = vm.cashbookEntry.instrumentId === null;
			var membershipFeeForPeriod = vm.cashbookEntry.membershipFeeForPeriodId === null;

			return vm.cashbookEntry.id !== null && !(compositions && instrumentService && instrument && membershipFeeForPeriod);
		}

		$timeout(function () {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function save () {
			vm.isSaving = true;
			if (vm.cashbookEntry.id !== null) {
				CashbookEntry.update(vm.cashbookEntry, onSaveSuccess, onSaveError);
			} else {
				CashbookEntry.save(vm.cashbookEntry, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess (result) {
			$scope.$emit('walterApp:cashbookEntryUpdate', result);
			vm.isSaving = false;
            $state.go('cashbook-entry', previousState.params, {reload : true});
		}

		function onSaveError () {
			vm.isSaving = false;
		}

        function goBack() {
            $state.go(previousState.name,  previousState.params);
        }

		vm.datePickerOpenStatus.date = false;

		function openCalendar (date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.clearAppointmentSelection = clearAppointmentSelection;
		function clearAppointmentSelection() {
			vm.appointment = null;
			vm.cashbookEntry.appointmentId = null;
		}

		vm.openChooseAppointmentDialog = openChooseAppointmentDialog;
		function openChooseAppointmentDialog() {
			$uibModal.open({
				templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-choose-appointment.html',
				controller: 'CashbookEntryChooseAppointmentController',
				controllerAs: 'vm',
				size: 'md',
				resolve: {
					pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
						return {
							page: PaginationUtil.parsePage($stateParams.page),
							sort: $stateParams.sort,
							predicate: PaginationUtil.parsePredicate($stateParams.sort),
							ascending: PaginationUtil.parseAscending($stateParams.sort),
							search: $stateParams.search
						};
					}],
					translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('appointment');
						$translatePartialLoader.addPart('cashbookEntry');
						$translatePartialLoader.addPart('global');
						return $translate.refresh();
					}]
				}
			}).result.then(function(appointment) {
				vm.cashbookEntry.appointmentId = appointment.id;
				vm.appointment = appointment;
			});
		}
	}
})();
