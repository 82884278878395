(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PersonBulkService', PersonBulkService);

    PersonBulkService.$inject = ['NeededEntitiesCache','$resource'];

    function PersonBulkService (NeededEntitiesCache, $resource) {

        this.personGroups = personGroups;
        this.peopleService = peopleService;

        function personGroups() {
            return NeededEntitiesCache.getCache('api/people/import/person-groups/').query();
        }

        function peopleService() {
            var resourceUrl = 'api/people/bulk';

            return $resource(resourceUrl, {}, {
                'save': {
                    method: 'POST'
                }
            });
        }

        return this;

    }
})();
