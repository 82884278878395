(function() {
    'use strict';

    angular
        .module('walterApp')
        .factory('tenantInterceptor', tenantInterceptor);

    tenantInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

    function tenantInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */
             config.headers = config.headers || {};
             var domain = window.location.hostname;

             try {
                 var tenant = domain ? domain.substr(0, domain.indexOf('.')) : null;


                 if (tenant) {
                     config.headers["X-TenantID"] = tenant;
                 }
             } catch(e) {
                 //do nothing
             };

            return config;
        }
    }
})();
