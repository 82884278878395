(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('membership-fee', {
            parent: 'personal-data-management',
            url: '/membership-fee?page&sort&search',
            data: {
                pageTitle: 'walterApp.membershipFee.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/membership-fee/membership-fees.html',
                    controller: 'MembershipFeeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'description,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('membershipFee');
                    $translatePartialLoader.addPart('membershipFeePeriod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('membership-fee.new', {
                url: '/new',
                data: {
                    pageTitle: 'walterApp.membershipFee.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/membership-fee/membership-fee-edit.html',
                        controller: 'MembershipFeeEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('membershipFee');
                        $translatePartialLoader.addPart('membershipFeePeriod');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MembershipFee', function ($stateParams, MembershipFee) {
                        return {
                        	description: null,
                            period: null,
                            periodTimeFraction: null,
                            id: null
                        }
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'membership-fee',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
        })
        .state('membership-fee.edit', {
                url: '/{id}/edit',
                data: {
                    pageTitle: 'walterApp.membershipFee.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/personal-data-management/membership-fee/membership-fee-edit.html',
                        controller: 'MembershipFeeEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('membershipFee');
                        $translatePartialLoader.addPart('membershipFeePeriod');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MembershipFee', function ($stateParams, MembershipFee) {
                        return MembershipFee.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'membership-fee',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('membership-fee.delete', {
                url: '/{id}/delete',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/personal-data-management/membership-fee/membership-fee-delete-dialog.html',
                        controller: 'MembershipFeeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['MembershipFee', function (MembershipFee) {
                                return MembershipFee.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('membership-fee', null, {reload: 'membership-fee'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
        .state('membership-fee-detail', {
            parent: 'personal-data-management',
            url: '/membership-fee/{id}',
            data: {
                pageTitle: 'walterApp.membershipFee.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/membership-fee/membership-fee-detail.html',
                    controller: 'MembershipFeeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('membershipFee');
                    $translatePartialLoader.addPart('membershipFeePeriod');
                    $translatePartialLoader.addPart('membershipFeeAmount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MembershipFee', function($stateParams, MembershipFee) {
                    return MembershipFee.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'membership-fee',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('membership-fee-detail.delete', {
            url: '/{id}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/personal-data-management/membership-fee/membership-fee-delete-dialog.html',
                    controller: 'MembershipFeeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MembershipFee', function(MembershipFee) {
                            return MembershipFee.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('membership-fee', {}, { reload: 'membership-fee' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
