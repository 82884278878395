(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipFeeEditController', MembershipFeeEditController);

    MembershipFeeEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'MembershipFee'];

    function MembershipFeeEditController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, MembershipFee) {
        var vm = this;
        vm.previousState = previousState;
        vm.membershipFee = entity;
        vm.save = save;
        vm.clear = clear;
        vm.goBack = goBack;
        
        //default value
        vm.membershipFee.period = "ANNUALLY";

        if(entity.id != null) {
        	vm.createMode = false; 
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        
        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }
        
        function save () {
            vm.isSaving = true;
            if (vm.membershipFee.id !== null) {
            	MembershipFee.update(vm.membershipFee, onSaveSuccess, onSaveError);
            } else {
            	MembershipFee.save(vm.membershipFee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:instrumentUpdate', result);
            $state.go(previousState.name,  {id: vm.membershipFee.id});
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function goBack() {
        	$state.go(previousState.name,  {id: vm.membershipFee.id});
        }
    }
})();
