(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CompanyDetailController', CompanyDetailController);

    CompanyDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Company', 'PersonGroup'];

    function CompanyDetailController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, Company, PersonGroup) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.company = entity;
        vm.goBack = goBack;

        vm.personGroup = PersonGroup.query();
        vm.selectedPersonGroup = null;
        vm.addPersonGroup = addPersonGroup;
        vm.removePersonGroup = removePersonGroup;

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.company.id !== null) {
                Company.update(vm.company, onSaveSuccess, onSaveError);
            } else {
                Company.save(vm.company, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:CompanyUpdate', result);
            $state.go(previousState.name);
        }

        function goBack() {
        	console.log(previousState);
        	$state.go(previousState.name, previousState.params, {reload : true});f
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function addPersonGroup() {

            if (vm.selectedPersonGroup != null) {
                var currPersonGroups = vm.company.personGroups;
                //index of only with IE > 8
                var index = -1;
                if (currPersonGroups) {
                        index = currPersonGroups.map(function (e) {
                            e.id
                        }).indexOf(vm.selectedPersonGroup.id);
                } else {
                    vm.company.personGroups = [];
                }

                if (index == -1) {
                    vm.company.personGroups.push(vm.selectedPersonGroup);
                }
            }


        }

        function removePersonGroup(personGroupToRemove) {
            var currPersonGroups = vm.company.personGroups;
            //index of only with IE > 8
            var index = currPersonGroups.indexOf(personGroupToRemove);

            if (index > -1) {
                currPersonGroups.splice(index,  1);
                vm.company.personGroups = currPersonGroups;
            }

        }


    }
})();
