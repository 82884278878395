(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('RoleEditController', RoleEditController);

    RoleEditController.$inject = ['$timeout', '$scope', '$state','$stateParams', '$filter', 'entity', 'Role', 'previousState'];

    function RoleEditController ($timeout, $scope, $state, $stateParams, $filter, entity, Role, previousState) {
        var vm = this;

        vm.role = entity;
        vm.previousState = previousState;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.possibleAuthorities;

        vm.displayIndices = [];
        vm.changeIndices = [];
        loadAuthorities();


        function loadAuthorities () {
            Role.getFeatureGroups({}, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.possibleAuthorities = [];

                data.forEach(function(group) {
                   var outputGroup = { group: group.group, authorities : [] };
                   group.authorities.forEach(function(posAuth) {
                           var auth;
                           if (vm.role.authorities) {
                               var display = canBeDisplayed(posAuth);
                               var change = canBeChanged(posAuth);

                               var id;
                               if (display) {
                                   id = $filter('filter')(vm.role.authorities, {name: posAuth}, true)[0].id;
                               }
                               auth = {name: posAuth, id: id, display: display, change: change};
                           } else {
                               auth =  {name: posAuth, display: false, change: false};
                           }
                           outputGroup.authorities.push(auth);
                       });
                   vm.possibleAuthorities.push(outputGroup);
                });



            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

            function canBeDisplayed(auth) {
                var res = $filter('filter')(vm.role.authorities, {name: auth}, true)[0];
                if (res) {
                    return true;
                }
                return false;
            }
            function canBeChanged(auth) {
                var res = $filter('filter')(vm.role.authorities, {name: auth}, true)[0];
                if (res && res.write === true) {
                    return true;
                }
                return false;
            }
        };


        function save () {
            vm.isSaving = true;
            vm.role.authorities = [];
            vm.possibleAuthorities.forEach(function(group) {
                group.authorities.forEach(function(posAuth) {
                    var write = posAuth.change;
                    if (write || posAuth.display) {
                        if (posAuth.id) {
                            vm.role.authorities.push( { id: posAuth.id,  name: posAuth.name, write : write } );
                        } else {
                            vm.role.authorities.push( {  name: posAuth.name, write : write } );
                        }

                    }

                });
            });



            if (vm.role.id !== null) {
                Role.update(vm.role, onSaveSuccess, onSaveError);
            } else {
                Role.save(vm.role, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:roleUpdate', result);
            vm.isSaving = false;
            $state.go(previousState.name, previousState.params);
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
