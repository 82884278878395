(function() {
	'use strict';

	angular
		.module('walterApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('notification-rule', {
			parent: 'admin',
			url: '/notification-rule?page&sort&search',
			data: {
				pageTitle: 'walterApp.notificationRule.home.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/notification-rule/notification-rules.html',
					controller: 'NotificationRuleController',
					controllerAs: 'vm'
				}
			},
			params: {
				page: {
					value: '1',
					squash: true
				},
				sort: {
					value: 'id,asc',
					squash: true
				},
				search: null
			},
			resolve: {
				pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
					return {
						page: PaginationUtil.parsePage($stateParams.page),
						sort: $stateParams.sort,
						predicate: PaginationUtil.parsePredicate($stateParams.sort),
						ascending: PaginationUtil.parseAscending($stateParams.sort),
						search: $stateParams.search
					};
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('notificationRule');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		})
		.state('notification-rule.new', {
			url: '/new',
			data: {
				pageTitle: 'walterApp.notificationRule.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/notification-rule/notification-rule-edit.html',
					controller: 'NotificationRuleEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('notificationRule');
					return $translate.refresh();
				}],
				entity: ['$stateParams', function($stateParams) {
					return {
						title: null,
						entity: null,
						conditions: null,
						active: false,
						id: null
					};
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name || 'notification-rule',
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
		.state('notification-rule-detail', {
            parent: 'notification-rule',
			url: '/{id}',
			data: {
				pageTitle: 'walterApp.notificationRule.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/notification-rule/notification-rule-detail.html',
					controller: 'NotificationRuleDetailController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('notificationRule');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'NotificationRule', function($stateParams, NotificationRule) {
					return NotificationRule.get({id : $stateParams.id}).$promise;
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name || 'notification-rule',
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
		.state('notification-rule.edit', {
			url: '/{id}/edit',
			data: {
				pageTitle: 'walterApp.notificationRule.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/admin/notification-rule/notification-rule-edit.html',
					controller: 'NotificationRuleEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('notificationRule');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'NotificationRule', function($stateParams, NotificationRule) {
					return NotificationRule.get({ id : $stateParams.id }).$promise;
				}],
				previousState: ["$state", "$stateParams", function ($state, $stateParams) {
					var currentStateData = {
						name: 'notification-rule-detail',
						params: $stateParams,
						url: $state.href('notification-rule-detail', $stateParams)
					};
					return currentStateData;
				}]
			}
		})
		.state('notification-rule.delete-view', {
			url: '/{id}/delete',
			data: {
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/admin/notification-rule/notification-rule-delete-dialog.html',
					controller: 'NotificationRuleDeleteController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['NotificationRule', function(NotificationRule) {
							return NotificationRule.get({id : $stateParams.id}).$promise;
						}]
					}
				}).result.then(function() {
					$state.go('notification-rule', null, { reload: 'notification-rule' });
				}, function() {
					$state.go('^');
				});
			}]
		})
		.state('notification-rule.delete-edit', {
			url: '/{id}/delete',
			data: {
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/admin/notification-rule/notification-rule-delete-dialog.html',
					controller: 'NotificationRuleDeleteController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['NotificationRule', function(NotificationRule) {
							return NotificationRule.get({id : $stateParams.id}).$promise;
						}]
					}
				}).result.then(function() {
					$state.go('notification-rule', null, { reload: 'notification-rule' });
				}, function() {
					$state.go('^');
				});
			}]
		});
	}

})();
