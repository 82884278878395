(function () {
    'use strict';

    angular
        .module('walterApp')
        .controller('DemandLetterEditController', DemandLetterEditController);


    DemandLetterEditController.$inject = ['FileSaver', 'Blob', '$uibModal', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'DemandLetter', 'DemandLetterTemplate'];

    function DemandLetterEditController(FileSaver, Blob, $uibModal, $state, $scope, $rootScope, $stateParams, previousState, DataUtils, entity, DemandLetter, DemandLetterTemplate) {
        var vm = this;

        vm.demandletter = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.loadDemandLetter = loadDemandLetter;
        vm.clearId = clearId;
        vm.save = save;
        vm.templates = DemandLetterTemplate.query();
        vm.selectedOptions = {};
        vm.demandletters = DemandLetter.query();
        vm.generate = generate;
        vm.tinyMceOptions = {
            language: 'de_AT',
            menu: {
                edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
                format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'}
            },
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright',
            height: 300
        };

        loadAllDemandLetters();

        function loadAllDemandLetters() {
            vm.demandletters = DemandLetter.query();
        }

        function loadDemandLetter() {
            if (vm.demandletterId) {
                vm.demandletter = DemandLetter.get({id: vm.demandletterId});
            } else {
                vm.demandletter.id = null;
                vm.demandletter.text = null;
                vm.demandletter.paylemt = true;
                vm.demandletter.templateId = null;
                vm.demandletter.title = null;
                vm.demandletter.letterType = 'DEMAND_LETTER';
            }
        }

        function clearId() {
            var exist = false;
            vm.demandletters.forEach(function (l) {
                if (l.title == vm.demandletter.title) {
                    vm.demandletterId = l.id;
                    vm.demandletter = l;
                    exist = true;
                }
            });
            if (!exist) {
                vm.demandletter.id = null;
                vm.demandletterId = null;
            }
        }

        function save() {

            if (vm.saveAs) {
                vm.isSaving = true;
                var update = false;

                vm.demandletters.forEach(function (l) {
                    if (l.title == vm.demandletter.title) {

                        vm.demandletter.id = l.id;

                        $uibModal.open({
                            templateUrl: 'app/letters/demand-letters/demandletter-overwrite-dialog.html',
                            controller: 'DemandLetterOverwriteController',
                            controllerAs: 'vm',
                            size: 'md',
                            resolve: {
                                entity: vm.demandletter
                            }
                        }).result.then(function () {
                            DemandLetter.update(vm.demandletter, onUpdateSuccess, onSaveError);
                        }, function () {
                            onSaveError();
                        });

                        update = true;
                    }
                });

                if (!update) {
                    vm.demandletter.id = null;
                    DemandLetter.save(vm.demandletter, onSaveSuccess, onSaveError);
                }
            }
        }

        function generate() {
            if (vm.demandletter) {
                DemandLetter.generate(vm.demandletter, onGenerateSuccess, onGenerateError);
            }
        }

        function onGenerateSuccess(result) {
            result = null;
        }

        function onGenerateError(result) {
            result = null;
        }

        function onSaveSuccess(result) {
            $scope.$emit('walterApp:demandLetterUpdate', result);
            vm.isSaving = false;
            vm.demandletter = result;
            loadAllDemandLetters();
            vm.demandletterId = vm.demandletter.id;
        }

        function onUpdateSuccess(result) {
            $scope.$emit('walterApp:demandLetterUpdate', result);
            vm.isSaving = false;
            vm.demandletter = result;
            vm.demandletterId = vm.demandletter.id;
        }


        function onSaveError() {
            vm.isSaving = false;
        }


        var unsubscribe = $rootScope.$on('walterApp:demandLetterUpdate', function (event, result) {
            vm.demandletter = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
