(function() {
    'use strict';

    var concedit = {
        template: '<div ng-repeat="user in vm.concedit" class="alert alert-warning">' +

                         ' <span data-translate="global.concedit.msg_one" data-translate-values="{ user : user }"></span>'+

                  '</div>',
        controller: conceditController,
        controllerAs: 'vm'
    };

    angular
        .module('walterApp')
        .component('concedit', concedit);

    conceditController.$inject = ['$scope', 'ConceditService', 'JhiTrackerService', 'Principal'];



    function conceditController($scope, ConceditService, JhiTrackerService, Principal) {
        var vm = this;

        vm.state = $scope.$root.toState.name;
        vm.paramId = $scope.$root.toStateParams.id;
        vm.username = {};
        vm.concedit = [];

        Principal.identity().then(function(account) {
                vm.username = account.login;
        });




        JhiTrackerService.subscribe();

        JhiTrackerService.receive().then(null, null, function(activity) {
            if (!activity.userLogin) {
                return;
            }
            if (vm.username !== activity.userLogin) {

                if( activity.paramId !== null &&  vm.state === activity.page && activity.paramId === vm.paramId) {
                    if (vm.concedit.indexOf(activity.userLogin) === -1 ) {
                        vm.concedit.push(activity.userLogin) ;
                        JhiTrackerService.sendActivity();
                    }

                } else if ( vm.concedit.indexOf(activity.userLogin) > -1 ) {
                    vm.concedit.splice(vm.concedit.indexOf(activity.userLogin), 1);
                }
            }
        });

        $scope.$on('$destroy', function () {
            vm.concedits = [];
        });


        this.$onDestroy = function() {
            JhiTrackerService.unsubscribe();
        }
    }
})();
