(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('LetterEditController',  LetterEditController);


    LetterEditController.$inject = ['FileSaver', 'Blob',  '$uibModal', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Letter', 'MailLetter', 'LetterTemplate', 'LetterPersonGroup'];

    function LetterEditController(FileSaver, Blob, $uibModal, $state, $scope, $rootScope, $stateParams, previousState, DataUtils, entity, Letter, MailLetter, LetterTemplate, LetterPersonGroup) {
        var vm = this;

        if ($stateParams.entity) {
            vm.letter = $stateParams.entity;
            vm.letterId = vm.letter.id;
        } else {
            vm.letter = entity;
            vm.letter.personGroups = [];
        }
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.loadLetter = loadLetter;
        vm.clearId = clearId;
        vm.save = save;
        vm.templates = LetterTemplate.query();
        vm.persongroups = LetterPersonGroup.query();

        vm.letters = Letter.query();
        vm.generate = generate;
        vm.personGroupsFlat = [];
        vm.currentChosenPersonGroup = null;
        vm.addCurrentPersonGroup = addCurrentPersonGroup

        vm.removePersonGroup = removePersonGroup;
        vm.tinyMceOptions = {
            language: 'de_AT',
            menu: {
              edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
              format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'}
            },
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright',
            height: 300
        };


        loadAllLetters();
        buildUpPersonGroupDropDown();



        function buildUpPersonGroupDropDown() {
            vm.personGroupsFlat = [];
            LetterPersonGroup.query().$promise.then(
                function (result) {

                    var personGroupsHier = treeify(result);

                    personGroupsHier.forEach(function (item) {
                        recur(item, 0, vm.personGroupsFlat);
                    });
                },
                function (error) {

                }
            );
        }


        function removePersonGroup(id) {
            if (id) {
                vm.letter.personGroups.forEach(function (item, i) {
                    if (item.id == id ) {
                        vm.letter.personGroups.splice(i, 1);
                    }
                });
                buildUpPersonGroupDropDown();
            }

        }

        function addCurrentPersonGroup() {
            if (vm.currentChosenPersonGroup) {
                if (vm.letter.personGroups.indexOf(vm.currentChosenPersonGroup) == -1 ) {
                    vm.letter.personGroups.push(vm.currentChosenPersonGroup);
                    buildUpPersonGroupDropDown()
                }
            }
        }

        function times (n, str) {
            var result = '';
            for (var i = 0; i < n; i++) {
                result += str;
            }
            return result;
        };


        function recur(item, level, arr) {
            var contains = false;
            if (vm.letter.personGroups) {
                vm.letter.personGroups.forEach(function (pgItem) {
                    if (pgItem.id == item.id) {
                        contains = true;
                    }
                });
            }
            if (!contains) {
                arr.push({
                    name: item.name,
                    id: item.id,
                    level: level,
                    text: times(level, '–') + " " + item.name
                });

                if (item.children) {
                    item.children.forEach(function (item) {
                        recur(item, level + 1, arr);
                    });
                }
            }
        };


        // http://stackoverflow.com/questions/22367711/construct-hierarchy-tree-from-flat-list-with-parent-field
        function treeify(list, idAttr, parentAttr, childrenAttr) {
            if (!idAttr) idAttr = 'id';
            if (!parentAttr) parentAttr = 'parentId';
            if (!childrenAttr) childrenAttr = 'children';

            var treeList = [];
            var lookup = {};
            list.forEach(function(obj) {
                lookup[obj[idAttr]] = obj;
                obj[childrenAttr] = [];
            });
            list.forEach(function(obj) {
                if (obj[parentAttr] != null) {
                    lookup[obj[parentAttr]][childrenAttr].push(obj);
                } else {
                    treeList.push(obj);
                }
            });
            return treeList;
        };


        function loadAllLetters() {
            vm.letters = Letter.query();

        }

        function loadLetter() {
            if (vm.letterId) {
                vm.letter = Letter.get({id : vm.letterId});
            } else {
                vm.letter.id  = null;
                vm.letter.text = null;
                vm.letter.personGroups = null;
                vm.letter.templateId = null;
                vm.letter.title = null;
                vm.letter.letterType = 'SERIAL_LETTER';
            }
        }

        function clearId() {
            var exist = false;
            vm.letters.forEach(function(l) {
                if (l.title == vm.letter.title) {
                    vm.letterId = l.id;
                    vm.letter = l;
                    exist = true;
                }
            });
            if (!exist) {
                vm.letter.id  = null;
                vm.letterId = null;
            }

        }

        function save () {

            if (vm.saveAs) {
                vm.isSaving = true;
                var update = false;

                vm.letters.forEach(function (l) {
                    if (l.title == vm.letter.title) {

                        vm.letter.id = l.id;

                        $uibModal.open({
                            templateUrl: 'app/letters/serial-letters/letter-overwrite-dialog.html',
                            controller: 'LetterOverwriteController',
                            controllerAs: 'vmO',
                            size: 'md',
                            resolve: {
                                entity: vm.letter
                            }
                        }).result.then(function () {
                            Letter.update(vm.letter, onUpdateSuccess, onSaveError);
                        }, function () {
                            onSaveError();
                        });


                        update = true;
                    }
                });

                if (!update) {
                    vm.letter.id = null;
                    Letter.save(vm.letter, onSaveSuccess, onSaveError);
                }
            }

        }

        function generate( ) {
            if (vm.letter) {
                Letter.generate(vm.letter, onGenerateSuccess, onGenerateError);
            }
        }

        function onGenerateSuccess(result ) {
            result = null;
        }

        function onGenerateError(result ) {
            result = null;

        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:letterUpdate', result);
            vm.isSaving = false;
            vm.letter = result;
            loadAllLetters();
            vm.letterId = vm.letter.id;

        }

        function onUpdateSuccess (result) {
            $scope.$emit('walterApp:letterUpdate', result);
            vm.isSaving = false;
            vm.letter = result;
            vm.letterId = vm.letter.id;

        }



        function onSaveError () {
            vm.isSaving = false;
        }




        var unsubscribe = $rootScope.$on('walterApp:letterUpdate', function(event, result) {
            vm.letter = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
