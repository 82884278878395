(function () {
    'use strict';

    angular
        .module('walterApp')
        .controller('DemandLetterOverwriteController', DemandLetterOverwriteController);

    DemandLetterOverwriteController.$inject = ['$uibModalInstance', 'entity', 'DemandLetter'];

    function DemandLetterOverwriteController($uibModalInstance, entity, DemandLetter) {
        var vm = this;

        vm.demandletter = entity;
        vm.clear = clear;
        vm.confirmOverwrite = confirmOverwrite;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmOverwrite(id) {
            $uibModalInstance.close(true);
        }
    }
})();
