(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('ClothingGroupEditController', ClothingGroupEditController);

    ClothingGroupEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'ClothingGroup', 'ClothingType'];

    function ClothingGroupEditController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, ClothingGroup, ClothingType) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.remove = remove;
        vm.clothingGroup = entity;
        vm.clothingTypes = ClothingType.query();
        vm.mode = $stateParams.mode;



        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            	ClothingGroup.save(vm.clothingGroup, onSaveSuccess, onSaveError);

        }

        function remove () {

            ClothingGroup.delete(vm.clothingGroup, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:clothingGroupUpdate', result);
            $state.go(previousState.name);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
