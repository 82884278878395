(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('ReceiptUnlinkController', ReceiptUnlinkController);

	ReceiptUnlinkController.$inject = ['$uibModalInstance', 'entity', 'Receipt', 'cashbookEntryId'];

	function ReceiptUnlinkController($uibModalInstance, entity, Receipt, cashbookEntryId) {
		var vm = this;

		vm.receipt = entity;
		vm.clear = clear;
		vm.confirmUnlink = confirmUnlink;
		vm.cashbookEntryId = cashbookEntryId;

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function confirmUnlink() {
			Receipt.unlinkCashbookEntry({ id: entity.id, cashbookEntryId: cashbookEntryId }, function () {
				$uibModalInstance.close(true);
			});
		}
	}
})();
