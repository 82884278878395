(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonHonorDeleteController',PersonHonorDeleteController);

    PersonHonorDeleteController.$inject = ['$uibModalInstance', 'entity', 'PersonHonor'];

    function PersonHonorDeleteController($uibModalInstance, entity, PersonHonor) {
        var vm = this;

        vm.personHonor = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PersonHonor.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
