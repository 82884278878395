(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonClothingEditController', PersonClothingEditController);

    PersonClothingEditController.$inject = ['$timeout', '$state', '$scope', 'previousState', '$stateParams', 'entity', 'PersonClothing', 'Instrument', 'Person', 'Clothing', 'ClothingGroup', 'ClothingWithGroup'];

    function PersonClothingEditController ($timeout, $state, $scope, previousState, $stateParams, entity, PersonClothing, Instrument, Person, Clothing, ClothingGroup, ClothingWithGroup) {
        var vm = this;

        vm.personClothing = entity;

        vm.previousState = previousState.name;
        vm.save = save;
        vm.back = back;
        vm.person = Person.get({id : $stateParams.personid});
        vm.personClothing.personId = $stateParams.personid;
        vm.clothingGroup;
        vm.data;


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.clothings = Clothing.query();
        vm.clothingGroups = ClothingGroup.query();
        vm.createMode = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        if(entity.id != null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
        }

        if (vm.personClothing.clothingTypeId && vm.personClothing.clothingSize) {
            ClothingGroup.get({csize : vm.personClothing.clothingSize, typeId : vm.personClothing.clothingTypeId}, onGetSuccess, onSaveError);
        }

        function save () {
            vm.isSaving = true;
            if (vm.clothingGroup != null) {

                ClothingWithGroup.get(vm.clothingGroup, onSaveSuccessGr, onSaveErrorGr);

        }
        }

        function onGetSuccess (result) {
            vm.clothingGroup = result;
        }

        function onSaveSuccessGr (result) {
            vm.personClothing.clothingId = result.id;
            if (!vm.personClothing.clothingId) {
                onSaveError();
            } else {
                if (vm.personClothing.id !== null) {
                    PersonClothing.update(vm.personClothing, onSaveSuccess, onSaveError);
                } else {
                    PersonClothing.save(vm.personClothing, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveErrorGr () {
            vm.isSaving = false;
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personClothingUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
                $state.go(vm.previousState, {"id": vm.person.id});
        }

        vm.datePickerOpenStatus.beginDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
