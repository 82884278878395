(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CashbookAccountTransfer', CashbookAccountTransfer);

    CashbookAccountTransfer.$inject = ['$resource'];

    function CashbookAccountTransfer ($resource) {
        var resourceUrl =  'api/cashbook-account-transfer';

        return $resource(resourceUrl, {}, {
            'save': { method:'POST' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CashbookAccountTransferRelatedEntities', CashbookAccountTransferRelatedEntities);

    CashbookAccountTransferRelatedEntities.$inject = ['$resource'];

    function CashbookAccountTransferRelatedEntities ($resource) {
        var me = this;

        var cashbookAccounts;

        this.getCashbookAccounts = getCashbookAccounts;
        this.invalidate = invalidate;


        function getCashbookAccounts() {
            if (!cashbookAccounts) {
                cashbookAccounts = CashbookAccountResource().query();
            }
            return cashbookAccounts;
        }

        function invalidate() {
            cashbookAccounts = null;
        }

        function CashbookAccountResource() {
            var resourceUrl =  'api/cashbook-accounts';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true, cache: true}
            });
        }

        return this;
    }
})();
