(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MailLetterEditController',  MailLetterEditController);


    MailLetterEditController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'MailLetter', 'Letter', 'PeopleWithInvalidMail', 'PeopleWithValidMail'];

    function MailLetterEditController($state, $scope, $rootScope, $stateParams, previousState, MailLetter, Letter, PeopleWithInvalidMail, PeopleWithValidMail) {
        var vm = this;

        vm.previousState = previousState;
        vm.mailLetter = $stateParams.letter;
        vm.mailLetter.saveInLetter = false;
        vm.isShowInvalidMailsWarning = false;
        vm.isShowNoPeopleWarning = false;

        vm.peopleWithValidMail = PeopleWithValidMail.query({mailLetterDTO: vm.mailLetter}, function(response) {
            if (response.length == 0) {
                vm.isShowNoPeopleWarning = true;
            } else {
                vm.peopleWithInvaildMail = PeopleWithInvalidMail.query({mailLetterDTO: $stateParams.letter}, function(response) {
                    if (response.length != 0) {
                        vm.isShowInvalidMailsWarning = true;
                    }
                });
            }
        });

        vm.showPeople = false;
        vm.goBack = goBack;
        vm.sendMail = sendMail;
        vm.goToMailLog = goToMailLog;
        vm.generateForInvalidEmails = generateForInvalidEmails;
        vm.isShowSaveInLetter = isShowSaveInLetter;
        vm.loadMailLetter = loadMailLetter;
        vm.switchShowPeople = switchShowPeople;

        loadMailLetter();

        function loadMailLetter() {
            if (vm.mailLetter.id) {
                vm.mailLetter = MailLetter.get({id: vm.mailLetter.id});
            }
        }

        function goBack() {
            $state.go(previousState.name, {entity: vm.mailLetter});
        }

        function sendMail() {
            vm.mailLetter.attachmentFileName = vm.mailLetter.subject.replace(" ", "_") + ".pdf";
            MailLetter.send(vm.mailLetter);
        }

        function goToMailLog() {
            $state.go('mail-log');
        }

        function isShowSaveInLetter() {
            return vm.mailLetter.id != null;
        }

        function generateForInvalidEmails() {
            var letterDTO = vm.mailLetter;
            letterDTO.personGroups = [];
            letterDTO.persons = vm.peopleWithInvaildMail;

            Letter.generate(letterDTO, onGenerateSuccess, onGenerateError);
        }

        function onGenerateSuccess(result ) {
            result = null;
        }

        function onGenerateError(result ) {
            result = null;

        }

        function switchShowPeople() {
            vm.showPeople = !vm.showPeople;
        }
    }
})();
