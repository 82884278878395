(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('BankImportDataEditController', BankImportDataEditController);

    BankImportDataEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', '$filter', 'entity', 'BankImportData', 'CashbookCategory', 'CashbookAccount'];

    function BankImportDataEditController($timeout, $scope, $rootScope, $state, $stateParams, $filter, entity, BankImportData, CashbookCategory, CashbookAccount) {
        var vm = this;
        vm.save = save;
        vm.bankImportData = entity;
        vm.entryDate = $filter('date')(vm.bankImportData.entryDate, 'dd.MM.yyyy');
        vm.cashbookCategories = CashbookCategory.query();
        vm.cashbookAccounts = CashbookAccount.query();

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            BankImportData.update(vm.bankImportData, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $state.go('cashbook-entry.import');
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
