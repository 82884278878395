(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider']

    function stateConfig($stateProvider) {
        $stateProvider
        .state('composition-overview', {
            parent: 'inventory',
            abstract: true,
            views: {
                'filterbar@': {
                    templateUrl: 'app/layouts/navbar/filterbar.html',
                    controller: 'FilterbarController',
                    controllerAs: 'vm'

                }
            },
            onExit: ['CompositionRelatedEntities', function(CompositionRelatedEntities) {
                CompositionRelatedEntities.invalidate();
            }]
        })
        .state('composition', {
            parent: 'composition-overview',
            url: '/composition?page&sort&search',
            type: 'overview',
            listName: "COMPOSITION",
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.composition.home.title'
            },
            views: {
                'content-overview@': {
                    templateUrl: 'app/inventory/composition/compositions.html',
                    controller: 'CompositionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'colorCode.name,nr,asc',
                    squash: true
                },
                search: {
                    value: null,
                    dynamic: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('composition');
                    $translatePartialLoader.addPart('filter');
                    $translatePartialLoader.addPart('columnConfig');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('composition.column-config-edit', {
            type: 'overview',
            url: '/column-config/{entity}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/column-config/column-config-dialog.html',
                    controller: 'ColumnConfigController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        columnConfig: ['ColumnConfig', function(ColumnConfig) {
                            return ColumnConfig.query({entity : $stateParams.entity}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('columnConfig');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('composition');
                            return $translate.refresh();
                        }]
                    }
                })
                .result.then(function() {
                    $state.go('composition', $stateParams, { reload: 'composition' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composition.new', {
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/composition/composition-edit.html',
                    controller: 'CompositionEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('composition');
                    $translatePartialLoader.addPart('customization');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Composition', function($stateParams, Composition) {
                    return {id: null};
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'composition',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('composition-detail', {
            parent: 'composition',
            url: '/composition/{id}/detail',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.composition.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/composition/composition-detail.html',
                    controller: 'CompositionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('composition');
                    $translatePartialLoader.addPart('composer');
                    $translatePartialLoader.addPart('arranger');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Composition', function($stateParams, Composition) {
                    return Composition.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'composition',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('composition-detail.edit', {
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/composition/composition-edit.html',
                    controller: 'CompositionEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('composition');
                    $translatePartialLoader.addPart('customization');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Composition', function($stateParams, Composition) {
                    return Composition.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'composition-detail',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('composition-detail.delete', {
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/inventory/composition/composition-delete-dialog.html',
                    controller: 'CompositionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Composition', function(Composition) {
                            return Composition.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composition', $stateParams, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composition-detail.removeComposer', {
            url: '/composers/delete/{composerId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/inventory/composition/composition-removeComposer-dialog.html',
                    controller: 'CompositionRemoveComposerController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Composer', function(Composer) {
                            return Composer.get({id : $stateParams.composerId}).$promise;
                        }],
                        composition: ['Composition', function(Composition) {
                            return Composition.get({id : $stateParams.id}).$promise;
                        }],
                    }
                }).result.then(function() {
                    $state.go('composition-detail', null, { reload: 'composition-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composition-detail.removeArranger', {
            url: '/arrangers/delete/{arrangerId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/inventory/composition/composition-removeArranger-dialog.html',
                    controller: 'CompositionRemoveArrangerController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Arranger', function(Arranger) {
                            return Arranger.get({id : $stateParams.arrangerId}).$promise;
                        }],
                        composition: ['Composition', function(Composition) {
                            return Composition.get({id : $stateParams.id}).$promise;
                        }],
                    }
                }).result.then(function() {
                    $state.go('composition-detail', null, { reload: 'composition-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('composition-edit', {
            parent: 'composition',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/inventory/composition/composition-edit.html',
                    controller: 'CompositionEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('composition');
                    $translatePartialLoader.addPart('customization');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Composition', function($stateParams, Composition) {
                    return Composition.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'composition',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('composition-edit.delete', {
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/inventory/composition/composition-delete-dialog.html',
                    controller: 'CompositionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Composition', function(Composition) {
                            return Composition.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('composition', $stateParams, { reload: 'composition' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
