(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CustomizationEditController', CustomizationEditController);

    CustomizationEditController.$inject = ['$state', '$timeout', '$scope', 'previousState', '$stateParams', 'entity', 'Customization', 'CustomizationNeededEntities'];

    function CustomizationEditController ($state, $timeout, $scope, previousState, $stateParams, entity, Customization, CustomizationNeededEntities) {
        var vm = this;

        vm.MEMBER_PERSONGROUP = 'MEMBER_PERSONGROUP';
        vm.HOURS_TO_INVALIDATE_VERIFICATION = 'HOURS_TO_INVALIDATE_VERIFICATION';
        vm.DEACTIVATED_FEATUREGROUPS = 'DEACTIVATED_FEATUREGROUPS';
        vm.AKM_DATA = 'AKM_DATA';
        vm.ORGANIZATION_COMPANY ='ORGANIZATION_COMPANY';
        vm.MONTHS_TO_DELETE_PERSON_DATA = 'MONTHS_TO_DELETE_PERSON_DATA';
        vm.DEFAULT_CASHBOOK_ACCOUNT = 'DEFAULT_CASHBOOK_ACCOUNT';
        vm.DEFAULT_CASHBOOK_CATEGORY_FOR_TRANSFER = 'DEFAULT_CASHBOOK_CATEGORY_FOR_TRANSFER';

        vm.customization = entity;
        vm.save = save;
        vm.previousState = previousState.name;
        vm.createMode = (entity.id === null);
        vm.entity = entity;
        vm.reset = reset;

        vm.akmData = {};

        if (entity.name === vm.MEMBER_PERSONGROUP) {
            vm.personGroups = CustomizationNeededEntities.personGroups();
            vm.personGroup = parseInt(entity.data);
        }


        if (entity.name === vm.ORGANIZATION_COMPANY) {
            vm.companies = CustomizationNeededEntities.companies();
            vm.company = parseInt(entity.data);
        }


        if (entity.name === vm.HOURS_TO_INVALIDATE_VERIFICATION)  {
            vm.hours = parseInt(entity.data);
        }
        if (entity.name === vm.MONTHS_TO_DELETE_PERSON_DATA)  {
            vm.months = parseInt(entity.data);
        }

        if (entity.name === vm.DEACTIVATED_FEATUREGROUPS) {
            vm.featureGroupsOutput = [];
            vm.deactivatedFeatures = angular.fromJson(entity.data);
            vm.featureGroups = CustomizationNeededEntities.featureGroups();

            vm.featureGroups.$promise.then(
                function() {
                    vm.featureGroups.forEach(function (group) {
                        var groupOut = {name: group, deactivated: false};
                        if (vm.deactivatedFeatures) {
                            if (vm.deactivatedFeatures.indexOf(group) > -1) {
                                groupOut.deactivated = true;
                            }
                        }
                        vm.featureGroupsOutput.push(groupOut);
                    })
                }

            );


        }

        if (entity.name === vm.AKM_DATA) {
            vm.akmData = angular.fromJson(entity.data);
        }

        if (entity.name === vm.DEFAULT_CASHBOOK_ACCOUNT) {
            vm.cashbookAccounts = CustomizationNeededEntities.cashbookAccounts();
            vm.cashbookAccount = parseInt(entity.data);
        }

        if (entity.name === vm.DEFAULT_CASHBOOK_CATEGORY_FOR_TRANSFER) {
            vm.cashbookCategories = CustomizationNeededEntities.cashbookCategories();
            vm.cashbookCategory = parseInt(entity.data);
        }

        function onSuccessFeatureGroups(data, headers) {
            if (vm.featureGroups) {

                vm.featureGroups.then(function() {
                    vm.featureGroups.forEach(function (group) {
                        var groupOut = {name: group.group, deactivated: false};
                        if (vm.deactivatedFeatures) {
                            if (vm.deactivatedFeatures.indexOf(group.group) > -1) {
                                groupOut.deactivated = true;
                            }
                        }
                        vm.featureGroupsOutput.push(groupOut);
                    });
                });
            }

        }

        function reset() {
            if (vm.entity.name === vm.MEMBER_PERSONGROUP) {
                vm.personGroup = 0;
            }
            if (vm.entity.name === vm.ORGANIZATION_COMPANY) {
                vm.company = 0;
            }
            if (vm.entity.name === vm.AKM_DATA) {
                vm.akmData = {};
            }
            if (vm.entity.name === vm.DEFAULT_CASHBOOK_ACCOUNT) {
                vm.cashbookAccount = 0;
            }
            if (vm.entity.name === vm.DEFAULT_CASHBOOK_CATEGORY_FOR_TRANSFER) {
                vm.cashbookCategory = 0;
            }
            save();
        }

        function save () {
            if (vm.entity.name === vm.MEMBER_PERSONGROUP) {
                vm.customization.data = vm.personGroup;
            }
            if (vm.entity.name === vm.ORGANIZATION_COMPANY) {
                vm.customization.data = vm.company;
            }
            if (vm.entity.name === vm.HOURS_TO_INVALIDATE_VERIFICATION) {
                vm.customization.data  = vm.hours;
            }
            if (vm.entity.name === vm.MONTHS_TO_DELETE_PERSON_DATA) {
                vm.customization.data  = vm.months;
            }

            if (vm.entity.name === vm.DEACTIVATED_FEATUREGROUPS) {
                var deactivatedFeatureGroups = [];

                vm.featureGroupsOutput.forEach(function(group) {
                    if(group.deactivated) {
                        deactivatedFeatureGroups.push(group.name);
                    }
                });

                vm.customization.data  = angular.toJson(deactivatedFeatureGroups);
            }
            if (vm.entity.name === vm.AKM_DATA) {
                vm.customization.data = angular.toJson(vm.akmData);
            }
            if (vm.entity.name === vm.DEFAULT_CASHBOOK_ACCOUNT) {
                vm.customization.data = vm.cashbookAccount;
            }
            if (vm.entity.name === vm.DEFAULT_CASHBOOK_CATEGORY_FOR_TRANSFER) {
                vm.customization.data = vm.cashbookCategory;
            }

            vm.isSaving = true;
            vm.customization.text = "";
            if (vm.customization.id !== null) {
                Customization.update(vm.customization, onSaveSuccess, onSaveError);
            } else {
                Customization.save(vm.customization, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:customizationUpdate', result);
            $state.go(vm.previousState);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
