(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipFeeAmountEditController', MembershipFeeAmountEditController);

    MembershipFeeAmountEditController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$rootScope', 'previousState', 'entity', 'MembershipFeeAmount'];

    function MembershipFeeAmountEditController($timeout, $scope, $stateParams, $state, $rootScope, previousState, entity, MembershipFeeAmount) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.membershipFeeAmount = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        $scope.membershipFeeId = $stateParams.membershipFeeId;
        vm.membershipFeeAmount.membershipFeeId = $scope.membershipFeeId;
        
        if(entity.id != null) {
        	vm.createMode = false;  
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.membershipFeeAmount.id !== null) {
                MembershipFeeAmount.update(vm.membershipFeeAmount, onSaveSuccess, onSaveError);
            } else {
                MembershipFeeAmount.save(vm.membershipFeeAmount, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
        	$scope.$emit('walterApp:membershipFeeAmountUpdate', result);
            $state.go(previousState.name, {id: $stateParams.membershipFeeId});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.beginDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
    }
})();
