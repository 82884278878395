(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonHonorEditController', PersonHonorEditController);

    PersonHonorEditController.$inject = ['$timeout', '$state', '$scope', 'previousState', '$stateParams', 'entity', 'PersonHonor', 'Honor', 'Person'];

    function PersonHonorEditController ($timeout, $state, $scope, previousState, $stateParams, entity, PersonHonor, Honor, Person) {
        var vm = this;

        vm.personHonor = entity;

        vm.previousState = previousState.name;
        vm.save = save;
        vm.back = back;
        vm.person = Person.get({id : $stateParams.personid});
        vm.personHonor.personId = $stateParams.personid;


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.honors = Honor.query();
        vm.createMode = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        if(entity.id != null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
        }


        function save () {
            vm.isSaving = true;
            if (vm.personHonor.id !== null) {
                PersonHonor.update(vm.personHonor, onSaveSuccess, onSaveError);
            } else {
                PersonHonor.save(vm.personHonor, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personHonorUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
                $state.go(vm.previousState, {"id": vm.person.id});
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
