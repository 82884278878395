(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(['$locationProvider', function($locationProvider) {
            $locationProvider.hashPrefix("");
        }])
        .config(['$qProvider', function ($qProvider) {
            $qProvider.errorOnUnhandledRejections(false);
        }]);
})();
