(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Honor', Honor);

    Honor.$inject = ['$resource'];

    function Honor ($resource) {
        var resourceUrl =  'api/honors/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PersonsForHonor', PersonsForHonor);

    PersonsForHonor.$inject = ['$resource'];
    
    function PersonsForHonor ($resource) {
        var resourceUrl =  'api/honors/:id/personHonors';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();