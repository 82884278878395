(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonBulkController', PersonBulkController);



    PersonBulkController.$inject = ['$scope', '$state', '$stateParams', 'PersonBulkService', 'previousState', 'AlertService']

    function PersonBulkController ($scope, $state, $stateParams, PersonBulkService, previousState, AlertService) {

        var vm = this;
        vm.showDetails = showDetails;
        vm.addPersonGroup = addPersonGroup;
        vm.removePersonGroup = removePersonGroup;
        vm.removePersonGroupFromPerson = removePersonGroupFromPerson;
        vm.changeToVerified = changeToVerified;
        vm.selectAll = selectAll;
        vm.save = save;
        vm.previousState = previousState;
        vm.currentIndex = null;
        vm.person = null;
        vm.selectedAll = false;
        vm.personCollection = $stateParams.personCollection;
        if (vm.personCollection !== null) {
            vm.persons = vm.personCollection.persons;
            vm.members = vm.personCollection.members;
        }

        vm.personGroups = PersonBulkService.personGroups();

        function showDetails(person, index) {
            if (vm.currentIndex === index) {
                vm.currentIndex = null;
                vm.person = null;
            } else {
                vm.currentIndex = index;
                vm.person = person;
            }
        }


        function selectAll() {
            var newValue = true;
            if (vm.selectedAll) {
                newValue = false;
            }
            vm.persons.forEach(function(person) {
                    person.chosen = newValue;
                }
            );
            vm.members.forEach(function(member) {
                member.chosen = newValue;
                }
            );
        }
        function addPersonGroup() {
            if (vm.selectedPersonGroup !== null) {
                vm.persons.forEach(function(person) {
                        if (person.chosen) {
                            addPersonGroupToPerson(person, vm.selectedPersonGroup);
                        }
                    }
                );
                vm.members.forEach(function(member) {
                    if (member.chosen) {
                        addPersonGroupToPerson(member.person, vm.selectedPersonGroup);
                    }
                });
            }
        }

        function changeToVerified(){
            vm.persons.forEach(function(person) {
                    if (person.chosen) {
                        person.emailType = "VERIFIED";
                    }
                }
            );
            vm.members.forEach(function(member) {
                if (member.chosen) {
                    member.person.emailType = "VERIFIED";
                }
            });
        }

        function removePersonGroup() {
            if (vm.selectedPersonGroup !== null) {
                vm.persons.forEach(function(person) {
                        if (person.chosen) {
                            removePersonGroupFromPerson(person, vm.selectedPersonGroup );
                        }
                    }
                );
                vm.members.forEach(function(member) {
                    if (member.chosen) {
                        removePersonGroupFromPerson(member.person, vm.selectedPersonGroup );
                    }
                });
            }
        }

        function addPersonGroupToPerson(person, persongroup) {
            var currPersonGroups = person.personGroups;
            //index of only with IE > 8
            var index = currPersonGroups.findIndex(function(pg) {
                return pg.id === persongroup.id;
            });

            if (index === -1) {
                person.personGroups.push(persongroup);
            }
        }

        function removePersonGroupFromPerson(person, persongroup) {
            var currPersonGroups = person.personGroups;
            //index of only with IE > 8
            var index = currPersonGroups.findIndex(function(pg) {
                return pg.id === persongroup.id;
            });

            if (index !== -1) {
                person.personGroups.splice(index, 1);
            }
        }

        function save() {
            var service = PersonBulkService.peopleService();
            vm.persons.forEach(function(person) {
                    delete person.chosen;
                }
            );
            if (vm.members) {
                vm.members.forEach(function (member) {
                        delete member.chosen;
                    }
                );
            }

            service.save(vm.personCollection, onSuccess, onError);
        }

        function onSuccess(data) {
            if (data.persons.length <= 0 && data.members.length <= 0) {
                AlertService.success("walterApp.person.home.imported");
                $state.go('person');
            } else {
                AlertService.error("walterApp.personBulk.error.followingCouldNotBeSaved");
                vm.persons = data.persons;
                vm.members = data.members;
            }

        }

        function onError(error) {

        }



    }
})();
