(function () {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('appointment-type', {
                parent: 'appointments',
                url: '/appointment-type?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointmentType.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/appointments/appointment-type/appointment-types.html',
                        controller: 'AppointmentTypeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointmentType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('appointment-type.new', {
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointmentType.edit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/appointments/appointment-type/appointment-type-edit.html',
                        controller: 'AppointmentTypeEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointmentType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AppointmentType', function ($stateParams, AppointmentType) {
                        return {
                            id: null,
                            name: null,
                            isOfficial: false,
                            personGroupId: null
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'appointment-type',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('appointment-type.edit', {
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.appointmentType.edit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/appointments/appointment-type/appointment-type-edit.html',
                        controller: 'AppointmentTypeEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('appointmentType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AppointmentType', function ($stateParams, AppointmentType) {
                        return AppointmentType.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'appointment-type',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('appointment-type.delete', {
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/appointments/appointment-type/appointment-type-delete-dialog.html',
                        controller: 'AppointmentTypeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['AppointmentType', function (AppointmentType) {
                                return AppointmentType.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('appointment-type', null, {reload: 'appointment-type'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
