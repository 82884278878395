(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Award', Award);

    Award.$inject = ['$resource'];

    function Award ($resource) {
        var resourceUrl =  'api/awards/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PersonsForAward', PersonsForAward);

    PersonsForAward.$inject = ['$resource'];
    
    function PersonsForAward ($resource) {
        var resourceUrl =  'api/awards/:id/personAwards';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();