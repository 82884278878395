(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('TenantConfiguration', TenantConfiguration);

    TenantConfiguration.$inject = ['$resource'];

    function TenantConfiguration ($resource) {
        var resourceUrl =  'api/tenant-configurations/:id';

        return $resource(resourceUrl, {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': {method: 'PUT'},
                'getAvailableConnections': {
                    method: 'GET',
                    url: 'api/tenant-configurations/db-connections',
                    isArray: true
                },
                'updateDbConnection': {
                   method: 'PUT',
                   url: 'api/tenant-configurations/db-connections'
                },
                'updateMultitenancyConfiguration': {
                    method: 'PUT',
                    url: 'api/tenant-configurations/config'
                }
            }
        );
    }
})();
