(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Person', Person);

    Person.$inject = ['$resource', 'DateUtils', 'FileSaver'];

    function Person ($resource, DateUtils, FileSaver) {
        var resourceUrl =  'api/people/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
                        data.dateLostRelevance = DateUtils.convertLocalDateFromServer(data.dateLostRelevance);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    return angular.toJson(copy);
                }
            },
            'getJson': {
                url: 'api/people/:personId/json',
                responseType: 'arraybuffer',
                method: 'GET',
                transformResponse: function (data, headers, status) {
                    if (status == 200) {

                        if (data) {
                            var filename = "file.json";
                            if (headers) {
                                //get filename from header (partly from http://stackoverflow.com/questions/33046930/how-to-get-the-name-of-a-file-downloaded-with-angular-http
                                var contentDisposition = headers('Content-Disposition');
                                filename = contentDisposition.split(';')[1].trim().split('=')[1];
                                filename = filename.replace(/"/g, '');
                            }
                            var blob = new Blob([data]),
                                url = window.URL.createObjectURL(blob);
                            FileSaver.saveAs(blob, filename);
                            return angular.toString(url);
                        }
                    } else {
                        //because we have an arraybuffer we have to extract the needed data
                        var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));

                        var obj = JSON.parse(decodedString);
                        var message = obj.message;
                        return message;
                    }
                }
            },
            'getPdf': {
                url: 'api/people/:personId/pdf',
                responseType: 'arraybuffer',
                method: 'GET',
                transformResponse: function (data, headers, status) {
                    if (status === 200) {

                        if (data) {
                            var filename = "file.json";
                            if (headers) {
                                //get filename from header (partly from http://stackoverflow.com/questions/33046930/how-to-get-the-name-of-a-file-downloaded-with-angular-http
                                var contentDisposition = headers('Content-Disposition');
                                filename = contentDisposition.split(';')[1].trim().split('=')[1];
                                filename = filename.replace(/"/g, '');
                            }
                            var blob = new Blob([data]),
                                url = window.URL.createObjectURL(blob);
                            FileSaver.saveAs(blob, filename);
                            return angular.toString(url);
                        }
                    } else {
                        //because we have an arraybuffer we have to extract the needed data
                        var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));

                        var obj = JSON.parse(decodedString);
                        var message = obj.message;
                        return message;
                    }
                }
            }

        });
    }
})();


(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PersonNeededEntities', PersonNeededEntities);

    PersonNeededEntities.$inject = ['NeededEntitiesCache'];

    function PersonNeededEntities (NeededEntitiesCache) {

        this.personGroups = personGroups;

        function personGroups() {
            return NeededEntitiesCache.getCache('api/people/person-groups').query();
        }

        return this;

    }
})();

