(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('FilterbarController', FilterbarController);

    FilterbarController.$inject = ['$state', '$scope', 'FilterService'];

    function FilterbarController ($state, $scope, FilterService) {
        var vm = this;


        vm.availableFilter = [];
        vm.filterName = "";
        vm.selectFilter = selectFilter;
        vm.saveCurrentFilter = saveCurrentFilter;
        vm.deleteFilter = deleteFilter;
        vm.isVisible = isVisible;
        vm.selectedFilter = null;

        if ($state.current.listName) {

            FilterService.setListName($state.current.listName);
            FilterService.setAvailableFilterFun(setAvailableFilter);
            FilterService.setResetFilterSelectionFun(resetFilterSelection);

            FilterService.getAllFilter();

        }

        function setAvailableFilter(availableFilter){
            vm.availableFilter = availableFilter;
        }

        function selectFilter(filter) {
            vm.selectedFilter = filter;
            FilterService.selectFilter(filter);
        }

        function resetFilterSelection() {
            vm.selectedFilter = null;
        }

        function saveCurrentFilter() {
            FilterService.saveCurrentFilter(vm.filterName);
            vm.filterName= "";
        }

        function deleteFilter(filter) {
            FilterService.deleteFilter(filter);
            selectFilter(null);
        }

        function isVisible() {
            return FilterService.isVisible();
        }
    }
})();
