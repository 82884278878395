(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonDetailController', PersonDetailController);

    PersonDetailController.$inject = ['$state', '$scope', '$filter', '$location', 'pagingParams', '$rootScope',  '$stateParams', 'previousState', 'entity', 'Person',  'PersonNeededEntities', 'EmailVerificationLink', 'AlertService', 'UiaccService'];

    function PersonDetailController($state, $scope, $filter, $location, pagingParams, $rootScope, $stateParams, previousState, entity, Person,  PersonNeededEntities, EmailVerificationLink, AlertService, UiaccService) {
        var vm = this;

        vm.tabs = [
            { index:1, name:"membership", route:"person.detail.membership", heading:"walterApp.person.tab.membership", view: "membershipView",show: UiaccService.isDisplayable('membership') },
            { index:1, name:"instrument", route:"person.detail.instrument", heading:"walterApp.person.tab.instrument", view: "instrumentView",show: UiaccService.isDisplayable('person-instrument') },
            { index:3, name:"clothing", route:"person.detail.clothing", heading:"walterApp.person.tab.clothing", view: "clothingView", show: UiaccService.isDisplayable('person-clothing') },
            { index:4, name:"honor", route:"person.detail.honor", heading:"walterApp.person.tab.honor", view: "honorView", show: UiaccService.isDisplayable('person-honor')},
            { index:5, name:"award", route:"person.detail.award", heading:"walterApp.person.tab.award", view:"awardView", show: UiaccService.isDisplayable('person-award') },
            { index:6, name:"info", route:"person.detail.info", heading:"walterApp.person.tab.info", view:"infoView", show: UiaccService.isDisplayable('person-full-information') }
        ]
        vm.activeTab = 0;

        vm.person = entity;
        vm.previousState = previousState.name;

        vm.personGroup = PersonNeededEntities.personGroups();
        vm.selectedPersonGroup = null;
        vm.selectedYear = null;
        vm.addPersonGroup = addPersonGroup;
        vm.transition = transition;
        vm.removePersonGroup = removePersonGroup;
        vm.getVerificationLink = getVerificationLink;
        vm.renewVerificationLink = renewVerificationLink;


        $scope.person = vm.person;

        vm.emailVerificationLink = "";
        vm.emailVerificationCode = "";
        vm.emailVerificationDate = "";
        vm.hasActiveEmailVerificationLink = true;

        if (vm.person.emailType == 'VERIFIED') {
            getVerificationLink();
        }

        $scope.$on("$stateChangeSuccess", function () {
            vm.tabs.forEach(function(tab) {
                if ($state.is(tab.route)) {
                    vm.activeTab = tab.index;
                }
            })
        });
        // checkTab($state);
        //
        //   function checkTab (state) {
        //      if (state.$current.self.name == "person.detail.instrument") {
        //           vm.instrumentTabActive = "active";
        //       }
        //   }


        var unsubscribe = $rootScope.$on('walterApp:personUpdate', function(event, result) {
            vm.person = result;
        });






        $scope.$on('$destroy', unsubscribe)

        function addPersonGroup() {
            if (vm.selectedPersonGroup != null) {
                var currPersonGroups = vm.person.personGroups;
                //index of only with IE > 8
                var index = currPersonGroups.indexOf(vm.selectedPersonGroup);

                if (index == -1) {
                    vm.person.personGroups.push(vm.selectedPersonGroup);
                    vm.person = Person.update(vm.person);
                }
            }

        }

        function removePersonGroup(personGroupToRemove) {
            var currPersonGroups = vm.person.personGroups;
            //index of only with IE > 8
            var index = currPersonGroups.indexOf(personGroupToRemove);

            if (index > -1) {
                currPersonGroups.splice(index,  1);
                vm.person.personGroups = currPersonGroups;
                vm.person = Person.update(vm.person);
            }

        }

        function getVerificationLink() {
            EmailVerificationLink.get({personId : vm.person.id}).$promise.then(function (data) {
                fillVerificationLinkData(data);
            });
        }




        function renewVerificationLink() {
            if (vm.hasActiveEmailVerificationLink && vm.emailVerificationDate == null) {
                EmailVerificationLink.delete({personId: vm.person.id}).$promise.then(function (data) {
                    EmailVerificationLink.create(
                        {personId: vm.person.id}).$promise.then(function (data) {
                        fillVerificationLinkData(data);
                    });
                });
            } else {
                EmailVerificationLink.create(
                    {personId: vm.person.id}).$promise.then(function (data) {
                    fillVerificationLinkData(data);
                });
            }

            AlertService.success($filter('translate')('walterApp.person.emailVerificationLinkRenewed'));
        }

        function fillVerificationLinkData(data) {
            if (data.personId) {
                vm.emailVerificationLink = data.link;
                vm.emailVerificationCode = data.secretKey;
                vm.emailVerificationDate = data.validationDate;
                vm.hasActiveEmailVerificationLink = !data.invalid;
            } else {
                vm.hasActiveEmailVerificationLink = false;
            }
        }

        function transition () {

        }

    }


})();
