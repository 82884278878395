(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CashbookCategoryDetailController', CashbookCategoryDetailController);

    CashbookCategoryDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'CashbookCategory'];

    function CashbookCategoryDetailController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, CashbookCategory) {
        var vm = this;
        vm.previousState = previousState;
        vm.save = save;
        vm.cashbookCategory = entity;
    	vm.cashbookCategories = CashbookCategory.query();

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        
        function save () {
            vm.isSaving = true;
            if (vm.cashbookCategory.id !== null) {
                CashbookCategory.update(vm.cashbookCategory, onSaveSuccess, onSaveError);
            } else {
                CashbookCategory.save(vm.cashbookCategory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:cashbookCategoryUpdate', result);
            $state.go(previousState.name, previousState.params);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
