(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonAwardEditController', PersonAwardEditController);

    PersonAwardEditController.$inject = ['$timeout', '$state', '$scope', 'previousState', '$stateParams', 'entity', 'PersonAward', 'Award', 'Person'];

    function PersonAwardEditController ($timeout, $state, $scope, previousState, $stateParams, entity, PersonAward, Award, Person) {
        var vm = this;

        vm.personAward = entity;

        vm.previousState = previousState.name;
        vm.save = save;
        vm.back = back;
        vm.person = Person.get({id : $stateParams.personid});
        vm.personAward.personId = $stateParams.personid;


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.awards = Award.query();
        vm.createMode = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        if(entity.id != null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
        }


        function save () {
            vm.isSaving = true;
            if (vm.personAward.id !== null) {
                PersonAward.update(vm.personAward, onSaveSuccess, onSaveError);
            } else {
                PersonAward.save(vm.personAward, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personAwardUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
                $state.go(vm.previousState, {"id": vm.person.id});
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
