(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonClothingController', PersonClothingController);

    PersonClothingController.$inject = ['$scope', '$state', '$stateParams', 'PersonClothing', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Clothing'];

    function PersonClothingController ($scope, $state, $stateParams, PersonClothing, ParseLinks, AlertService, pagingParams, paginationConstants, Clothing) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clothings = Clothing.query;

        loadAll();

        function loadAll () {
            PersonClothing.query({
                page: 0,
                size: 1000,
                personId: $stateParams.id,
              //  sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.personClothings = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
