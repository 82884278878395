(function() {
    'use strict';

    angular
    .module('walterApp')
    .controller('ColumnConfigController',ColumnConfigController);

    ColumnConfigController.$inject = ['$uibModalInstance', '$filter', '$stateParams', 'columnConfig', 'ColumnConfig', 'ColumnConfigService'];

    function ColumnConfigController($uibModalInstance, $filter, $stateParams, columnConfig, ColumnConfig, ColumnConfigService) {
        var vm = this;

        vm.columnConfig = columnConfig;
        vm.clear = clear;
        vm.save = save;
        vm.selectedAvailable = null;
        vm.selectedVisible = null;
        vm.pushToVisibleColumns = pushToVisibleColumns;
        vm.pushToHiddenColumns = pushToHiddenColumns;
        vm.hasMoreThanOneVisibleColumn = hasMoreThanOneVisibleColumn;
        vm.moveUp = moveUp;
        vm.moveDown = moveDown;
        vm.reset = reset;

        function hasMoreThanOneVisibleColumn() {
            return ($filter('filter')(vm.columnConfig, {visible: true}).length) > 1;
        }

        function pushToVisibleColumns() {

            var countVisibleColumns = $filter('filter')(vm.columnConfig, {visible: true}).length + 1;

            var config = {};
            vm.columnConfig.forEach(function(item) {
                if (item.id === vm.selectedAvailable) {
                    config = item;
                }
            });

            config.visible = true;
            config.position = countVisibleColumns;

            vm.columnConfig.sort(function(a,b) {
                return parseFloat(a.position) - parseFloat(b.position);
            });

            rearrangePositions($filter('filter')(vm.columnConfig, {visible: false}));

            console.log($filter('filter')(vm.columnConfig, {visible: true}));
            console.log($filter('filter')(vm.columnConfig, {visible: false}));
        }

        function pushToHiddenColumns() {

            var countHiddenColumns = $filter('filter')(vm.columnConfig, {visible: false}).length + 1;

           // var config = vm.columnConfig.find(item => item.id === vm.selectedVisible);
            var config = {};
            vm.columnConfig.forEach(function(item) {
                if (item.id === vm.selectedVisible) {
                    config = item;
                }
            });
            config.visible = false;
            config.position = countHiddenColumns;
            countHiddenColumns++;

            vm.columnConfig.sort(function(a,b) {
                return parseFloat(a.position) - parseFloat(b.position);
            });

            rearrangePositions($filter('filter')(vm.columnConfig, {visible: true}));

            console.log($filter('filter')(vm.columnConfig, {visible: true}));
            console.log($filter('filter')(vm.columnConfig, {visible: false}));
        }

        function moveUp() {
            //   var config = vm.columnConfig.find(item => item.id === vm.selectedVisible);
            var config = {};
            vm.columnConfig.forEach(function(item) {
                if (item.id === vm.selectedVisible) {
                    config = item;
                }
            });

            var newPosition = parseFloat(config.position) - 1;

            var configAtNewPosition = $filter('filter')(vm.columnConfig, {visible: true, position: newPosition})[0];
            configAtNewPosition.position = parseFloat(configAtNewPosition.position) + 1;
            config.position = newPosition;

            vm.columnConfig.sort(function(a,b) {
                return parseFloat(a.position) - parseFloat(b.position);
            });

            console.log($filter('filter')(vm.columnConfig, {visible: true}));
        }

        function moveDown() {
            //  var config = vm.columnConfig.find(item => item.id === vm.selectedVisible);
            var config = {};
            vm.columnConfig.forEach(function(item) {
                if (item.id === vm.selectedAvailable) {
                    config = item;
                }
            });


             var newPosition = parseFloat(config.position) + 1;

            var configAtNewPosition = $filter('filter')(vm.columnConfig, {visible: true, position: newPosition})[0];
            configAtNewPosition.position = parseFloat(configAtNewPosition.position) - 1;
            config.position = newPosition;

            vm.columnConfig.sort(function(a,b) {
                return parseFloat(a.position) - parseFloat(b.position);
            });

            console.log($filter('filter')(vm.columnConfig, {visible: true}));
        }

        function rearrangePositions(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].position = i+1;
            }
        }

        function reset() {
            ColumnConfig.query({entity: $stateParams.entity, showDefault: true}).$promise.then(function (result) {
                vm.columnConfig = result;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            ColumnConfigService.update(vm.columnConfig, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(true);
        }

        function onSaveError() {
            $uibModalInstance.close(true);
        }
    }
})();
