(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipFeeForPeriodDialogController', MembershipFeeForPeriodDialogController);

    MembershipFeeForPeriodDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'MembershipFeeForPeriod', 'CashbookEntry', 'Membership'];

    function MembershipFeeForPeriodDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, MembershipFeeForPeriod, CashbookEntry, Membership) {
        var vm = this;

        vm.membershipFeeForPeriod = entity;
        vm.clear = clear;
        vm.save = save;
        vm.cashbookentries = CashbookEntry.query({filter: 'membershipfeeforperiod-is-null'});
        $q.all([vm.membershipFeeForPeriod.$promise, vm.cashbookentries.$promise]).then(function() {
            if (!vm.membershipFeeForPeriod.cashbookEntryId) {
                return $q.reject();
            }
            return CashbookEntry.get({id : vm.membershipFeeForPeriod.cashbookEntryId}).$promise;
        }).then(function(cashbookEntry) {
            vm.cashbookentries.push(cashbookEntry);
        });
        vm.memberships = Membership.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.membershipFeeForPeriod.id !== null) {
                MembershipFeeForPeriod.update(vm.membershipFeeForPeriod, onSaveSuccess, onSaveError);
            } else {
                MembershipFeeForPeriod.save(vm.membershipFeeForPeriod, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:membershipFeeForPeriodUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
