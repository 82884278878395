(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('InstrumentServiceDeleteController',InstrumentServiceDeleteController);

    InstrumentServiceDeleteController.$inject = ['$uibModalInstance', 'entity', 'InstrumentService', '$scope', '$stateParams', '$state', 'previousState'];

    function InstrumentServiceDeleteController($uibModalInstance, entity, InstrumentService, $scope, $stateParams, $state, previousState) {
        var vm = this;

        vm.instrumentService = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.instrumentId = $stateParams.instrumentId;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            InstrumentService.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
