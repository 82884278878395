(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CompositionRemoveArrangerController',CompositionRemoveArrangerController);

    CompositionRemoveArrangerController.$inject = ['$uibModalInstance', 'entity', 'composition', 'Arranger', 'ArrangersForComposition'];

    function CompositionRemoveArrangerController($uibModalInstance, entity, composition, Arranger, ArrangersForComposition) {
        var vm = this;

        vm.composition = composition;
        vm.arranger = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
        	ArrangersForComposition.delete({id : vm.composition.id, arrangerId : vm.arranger.id}, vm.arranger,
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
