(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CashbookAccountTransferController',CashbookAccountTransferController);

    CashbookAccountTransferController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'CashbookAccountTransfer', 'CashbookAccountTransferRelatedEntities'];

    function CashbookAccountTransferController($state, $scope, $rootScope, $stateParams, previousState, CashbookAccountTransfer, CashbookAccountTransferRelatedEntities) {
        var vm = this;

        vm.goBack = goBack;
        vm.save = save;
        vm.cashbookAccountFromId;
        vm.cashbookAccountToId;
        vm.amount = 0.0;
        vm.cashbookAccounts = CashbookAccountTransferRelatedEntities.getCashbookAccounts();

        function goBack() {
            $state.go(previousState.name,  previousState.params);
        }

        function save () {
            vm.isSaving = true;
            CashbookAccountTransfer.save({cashbookAccountFromId: vm.cashbookAccountFromId, cashbookAccountToId: vm.cashbookAccountToId, amount: vm.amount},
                onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:cashbookAccountTransferUpdate', result);
            vm.isSaving = false;
            $state.go('cashbook-entry', previousState.params, {reload : true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
