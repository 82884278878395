(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('CashbookEntryDetailController', CashbookEntryDetailController);

	CashbookEntryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CashbookEntry', 'Composition', 'InstrumentService', 'MembershipFeeForPeriod', 'Appointment', 'CashbookCategory', 'Receipt'];

	function CashbookEntryDetailController($scope, $rootScope, $stateParams, previousState, entity, CashbookEntry, Composition, InstrumentService, MembershipFeeForPeriod, Appointment, CashbookCategory, Receipt) {
		var vm = this;

		vm.cashbookEntry = entity;
		vm.previousState = previousState.name;

		if(entity.receiptId) {
			vm.cashbookEntryReceipt = Receipt.get({ id : entity.receiptId });
		}

		vm.isCreatedByForeignRelation = isCreatedByForeignRelation;
		function isCreatedByForeignRelation() {
			var compositions = vm.cashbookEntry.compositions && vm.cashbookEntry.compositions.length === 0;
			var instrumentService = vm.cashbookEntry.instrumentServiceId === null;
			var membershipFeeForPeriod = vm.cashbookEntry.membershipFeeForPeriodId === null;

			return !(compositions && instrumentService && membershipFeeForPeriod);
		}

		var unsubscribe = $rootScope.$on('walterApp:cashbookEntryUpdate', function(event, result) {
			vm.cashbookEntry = result;
		});

		$scope.$on('$destroy', unsubscribe);

		vm.downloadReceipt = downloadReceipt;
		function downloadReceipt(receipt) {
			Receipt.download({
				uuid: receipt.file
			}, onSuccess, onError);

			function onSuccess(data, headers) {
				var blob = new Blob([data.data], { type: headers('content-type') });
				saveAs(blob, data.filename);
			};

			function onError(error) {
				AlertService.error(error.data.message);
			};
		}
	}
})();
