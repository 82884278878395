(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('GenreDetailController', GenreDetailController);

    GenreDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Genre'];

    function GenreDetailController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, Genre) {
        var vm = this;
        vm.previousState = previousState;
        vm.save = save;
        vm.genre = entity;

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        
        function save () {
            vm.isSaving = true;
            if (vm.genre.id !== null) {
                Genre.update(vm.genre, onSaveSuccess, onSaveError);
            } else {
                Genre.save(vm.genre, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:GenreUpdate', result);
            $state.go(previousState.name, previousState.params);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
