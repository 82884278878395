(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('cashbook-account', {
                parent: 'accounting',
                url: '/cashbook-account?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.cashbookAccount.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/accounting/cashbook-account/cashbook-accounts.html',
                        controller: 'CashbookAccountController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cashbookAccount');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('bankImportData');
                        $translatePartialLoader.addPart('bankImporterType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('cashbook-account.new', {

                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.CashbookAccount.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/accounting/cashbook-account/cashbook-account-detail.html',
                        controller: 'CashbookAccountDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cashbookAccount');
                        $translatePartialLoader.addPart('bankImportData');
                        $translatePartialLoader.addPart('bankImporterType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CashbookAccount', function($stateParams, CashbookAccount) {
                        return {
                            name: null,
                            id: null
                        };
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'cashbook-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('cashbook-account.edit', {

                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.CashbookAccount.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/accounting/cashbook-account/cashbook-account-detail.html',
                        controller: 'CashbookAccountDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cashbookAccount');
                        $translatePartialLoader.addPart('bankImportData');
                        $translatePartialLoader.addPart('bankImporterType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CashbookAccount', function($stateParams, CashbookAccount) {
                        return CashbookAccount.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'cashbook-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('cashbook-account.delete', {
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/accounting/cashbook-account/cashbook-account-delete-dialog.html',
                        controller: 'CashbookAccountDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CashbookAccount', function(CashbookAccount) {
                                return CashbookAccount.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('cashbook-account', null, { reload: 'cashbook-account' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
