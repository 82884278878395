(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Composition', Composition);

    Composition.$inject = ['$resource'];

    function Composition ($resource) {
        var resourceUrl =  'api/compositions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('ComposersForComposition', ComposersForComposition);

    ComposersForComposition.$inject = ['$resource'];

    function ComposersForComposition ($resource) {
        var resourceUrl =  'api/compositions/:id/composers/:composerId';

        return $resource(resourceUrl, {id: '@id', composerId: '@composerId'}, {
            'query': { method: 'GET', isArray: true},
        	'update': { method: 'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('ArrangersForComposition', ArrangersForComposition);

    ArrangersForComposition.$inject = ['$resource'];

    function ArrangersForComposition ($resource) {
        var resourceUrl =  'api/compositions/:id/arrangers/:arrangerId';

        return $resource(resourceUrl, {id: '@id', arrangerId: '@arrangerId'}, {
            'query': { method: 'GET', isArray: true},
            'update': { method: 'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CompositionComposers', CompositionComposers);

    CompositionComposers.$inject = ['$resource'];

    function CompositionComposers ($resource) {
        var resourceUrl =  'api/compositions/composers';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CompositionArrangers', CompositionArrangers);

    CompositionArrangers.$inject = ['$resource'];

    function CompositionArrangers ($resource) {
        var resourceUrl =  'api/compositions/arrangers';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CompositionColorCodes', CompositionColorCodes);

    CompositionColorCodes.$inject = ['$resource'];

    function CompositionColorCodes ($resource) {
        var resourceUrl =  'api/compositions/color-codes';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, cache: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CompositionMusicBooks', CompositionMusicBooks);

    CompositionMusicBooks.$inject = ['$resource'];

    function CompositionMusicBooks ($resource) {
        var resourceUrl =  'api/compositions/music-books';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('CompositionRelatedEntities', CompositionRelatedEntities);

    CompositionRelatedEntities.$inject = ['$resource'];

    function CompositionRelatedEntities ($resource) {
        var me = this;

        var musicBooks;
        var colorCodes;

        this.getMusicBooks = getMusicBooks;
        this.getColorCodes = getColorCodes;
        this.invalidate = invalidate;

        function getMusicBooks() {
            if (!musicBooks) {
                musicBooks = MusicBookResource().query();
            }
            return musicBooks;
        }

        function getColorCodes() {
            if (!colorCodes) {
                colorCodes = ColorCodeResource().query();
            }
            return colorCodes;
        }

        function invalidate() {
            musicBooks = null;
            colorCodes = null;
        }

        function MusicBookResource() {
            var resourceUrl =  'api/compositions/music-books';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true}
            });
        }

        function ColorCodeResource() {
            var resourceUrl =  'api/compositions/color-codes';

            return $resource(resourceUrl, {}, {
                'query': { method: 'GET', isArray: true, cache: true}
            });
        }

        return this;
    }
})();




