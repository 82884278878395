(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'roles', 'User', 'JhiLanguageService', 'UserRoles'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, roles, User, JhiLanguageService, UserRoles) {
        var vm = this;

        vm.authorities = [];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;

        getRoles();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });



        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getRoles() {
            UserRoles.query(function(data) {
                for (var r in data) {
                    vm.authorities.push(data[r].name);
                }
            });
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
