(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('TenantConfigurationEditController', TenantConfigurationEditController);

	TenantConfigurationEditController.$inject = ['$timeout', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TenantConfiguration'];

	function TenantConfigurationEditController ($timeout, $state, $scope, $rootScope, $stateParams, previousState, entity, TenantConfiguration) {
		var vm = this;

		vm.tenantConfiguration = entity;
		vm.savedTenantConfiguration = entity.tenantId;
		vm.clear = clear;
		vm.save = save;
		vm.previousState = previousState;
		vm.dbConnections = TenantConfiguration.getAvailableConnections();

		$timeout(function (){
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear () {}

		function save () {
			vm.isSaving = true;
			if (vm.tenantConfiguration.id !== null) {
				TenantConfiguration.update(vm.tenantConfiguration, onSaveSuccess, onSaveError);
			} else {
				TenantConfiguration.save(vm.tenantConfiguration, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess (result) {
			$scope.$emit('walterApp:tenantConfigurationUpdate', result);
			vm.isSaving = false;
			$state.go(previousState.name, previousState.params);
		}

		function onSaveError () {
			vm.isSaving = false;
		}


	}
})();
