(function() {
    'use strict';
    angular
    .module('walterApp')
    .factory('Filter', Filter);

    Filter.$inject = ['$resource'];

    function Filter ($resource) {
        var resourceUrl =  'api/filter/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('FilterService', FilterService);

    FilterService.$inject = ['Filter'];

    function FilterService (Filter) {
        /** Alles was für persistente Filter benötigt wird! */
        var me = this;

        this.selectedFilter = null;
        this.filterName = "";
        this.visible = true;


        this.setResetSearchFun = setResetSearchFun;
        this.setListName = setListName;
        this.saveCurrentFilter = saveCurrentFilter;
        this.deleteFilter = deleteFilter;
        this.getFilter = getFilter;
        this.getAllFilter = getAllFilter;
        this.setAvailableFilterFun = setAvailableFilterFun;
        this.setResetFilterSelectionFun = setResetFilterSelectionFun;
        this.setSearchFun = setSearchFun;
        this.selectFilter = selectFilter;
        this.resetFilterSelection = resetFilterSelection;
        this.isVisible = isVisible;
        this.setIsVisible = setIsVisible;

        this.getCurrentSearchFun = getCurrentSearchFun;

        this.resetSearch = null;
        this.listName = null;
        this.setAvailableFilter = null;
        this.setSearch = null;
        this.getCurrentSearch = null;
        this.resetFilterSelectionFun = null;


        this.availableFilter = [];

        function setResetFilterSelectionFun(resetFilterSelectionFun) {
            this.resetFilterSelectionFun = resetFilterSelectionFun;
        }

        function resetFilterSelection() {
            this.resetFilterSelectionFun();
        }


        function getCurrentSearchFun(getCurrentSearch ) {
            this.getCurrentSearch = getCurrentSearch;
        }

        function setSearchFun(setSearch) {
            this.setSearch = setSearch;
        }

        function setResetSearchFun(resetSearch) {
            this.resetSearch = resetSearch;
        }


        function setAvailableFilterFun(availableFilter) {
            this.setAvailableFilter = availableFilter;
        }

        function setListName(listName) {
            this.listName = listName;
        }

        function getAllFilter() {
            Filter.query({
                listName: me.listName
            }).$promise.then(function (data) {
                me.setAvailableFilter(data);
            });
        }

        function saveCurrentFilter(filterName) {
            Filter.save({
                listName: me.listName,
                name: filterName,
                filter: angular.toJson(me.getCurrentSearch())
            }).$promise.then(function(data) {
                getAllFilter();
            }).catch(function (error) {
                AlertService.error(error.data.message);
            });
        }


        function selectFilter(filter) {
            me.selectedFilter = filter;
            getFilter();
        }

        function getFilter() {
            if (me.selectedFilter != null && me.selectedFilter != "") {
                Filter.get({id: me.selectedFilter}).$promise.then(
                    function (data) {
                        me.setSearch(angular.fromJson(data.filter));
                    });
            } else {
                if (me.resetSearch) {
                    me.resetSearch();
                }
            }
        }

        function deleteFilter(toDelete) {
            Filter.delete({id: toDelete}).$promise.then(function(data) {
                me.selectedFilter = null;
                getAllFilter();
            });
        }

        function isVisible() {
            return me.visible;
        }

        function setIsVisible(visible) {
            me.visible = visible;
        }

        return this;
    }
})();
