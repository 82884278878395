(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('DataImportController', DataImportController);



    DataImportController.$inject = ['$scope', '$state', 'Upload'];

	function DataImportController ($scope, $state, Upload) {


		var vm = this;
        vm.TYPE_PERSON = {
            value: "TYPE_PERSON",
            text: "walterApp.dataImport.types.person"
        };

		vm.import = importData;

		vm.importType = null;
		vm.validImportTypes = [ vm.TYPE_PERSON ];


		function importData() {

            var postData = {
                csvFile : vm.file,
            };

            Upload.upload({
                url : 'api/people/import/csv',
                method : 'POST',
                data : postData
            }).progress(function(evt) {
                vm.progress = parseInt(100.0 * evt.loaded / evt.total);
            }).success(onImportSuccess).error(onImportError);



        }

        function onImportSuccess(result) {
            vm.isSaving = false;
            vm.result = result;
            $state.go("person-bulk", {personCollection: result});
        }

        function onImportError() {
		    vm.isSaving = false;

        }




    }
})();
