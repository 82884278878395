(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('RoleDetailController', RoleDetailController);

    RoleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$filter', 'previousState', 'entity', 'Role'];

    function RoleDetailController($scope, $rootScope, $stateParams, $filter, previousState, entity, Role) {
        var vm = this;

        vm.role = entity;
        vm.previousState = previousState.name;
        vm.possibleAuthorities;

        loadAuthorities();

        function loadAuthorities () {
            Role.getFeatureGroups({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.possibleAuthorities = [];

                data.forEach(function(group) {
                    var outputGroup = { group: group.group, authorities : [] };
                    group.authorities.forEach(function(posAuth) {
                        var auth;
                        if (vm.role.authorities) {
                            var display = canBeDisplayed(posAuth);
                            var change = canBeChanged(posAuth);

                            var id;
                            if (display) {
                                id = $filter('filter')(vm.role.authorities, {name: posAuth}, true)[0].id;
                            }
                            auth = {name: posAuth, id: id, display: display, change: change};
                        } else {
                            auth =  {name: posAuth, display: false, change: false};
                        }
                        outputGroup.authorities.push(auth);
                    });
                    vm.possibleAuthorities.push(outputGroup);
                });



            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

            function canBeDisplayed(auth) {
                var res = $filter('filter')(vm.role.authorities, {name: auth}, true)[0];
                if (res) {
                    return true;
                }
                return false;
            }
            function canBeChanged(auth) {
                var res = $filter('filter')(vm.role.authorities, {name: auth}, true)[0];
                if (res && res.write === true) {
                    return true;
                }
                return false;
            }
        };


        var unsubscribe = $rootScope.$on('walterApp:roleUpdate', function(event, result) {
            vm.role = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
