(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MembershipFeeAmountDeleteController',MembershipFeeAmountDeleteController);

    MembershipFeeAmountDeleteController.$inject = ['$uibModalInstance', 'entity', 'MembershipFeeAmount', '$scope', '$stateParams', '$state', 'previousState'];

    function MembershipFeeAmountDeleteController($uibModalInstance, entity, MembershipFeeAmount, $scope, $stateParams, $state, previousState) {
        var vm = this;

        vm.membershipFeeAmount = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        $scope.membershipFeeId = $stateParams.membershipFeeId;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MembershipFeeAmount.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
