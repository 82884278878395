(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Receipt', Receipt);

    Receipt.$inject = ['$resource', 'DateUtils'];

    function Receipt ($resource, DateUtils) {
        var resourceUrl =  'api/receipts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'linkToCashbookEntry': {
                url: 'api/receipts/linktocashbookentry',
                method: 'PUT'
            },
            'unlinkCashbookEntry': {
                url: 'api/receipts/unlinkcashbookentry',
                method: 'PUT'
            },
            'download': {
                method: 'GET',
                url: 'api/receipts/file/:uuid',
                responseType: 'arraybuffer',
                transformResponse: function(data, headers) {
                    var header = headers('content-disposition');
                    var result = header.split(';')[1].trim().split('=')[1];
                    var filename = result.replace(/"/g, '');
                    
                    return {
                        data: data,
                        filename: filename
                    }
                }
            }
        });
    }
})();
