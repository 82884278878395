(function() {
	'use strict';

	angular
		.module('walterApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('receipt', {
			parent: 'accounting',
			url: '/receipt',
		})
		.state('receipt.new', {
			url: '/new',
			'params': {
				'cashbookEntryId': null
			},
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.receipt.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/receipt/receipt-edit.html',
					controller: 'ReceiptEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('receipt');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'Receipt', function($stateParams, Receipt) {
					return {
						company: null,
						note: null,
						date: new Date(),
						id: null,
						identifier: null,
						cashbookEntryId: $stateParams.cashbookEntryId
					};
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name,
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
		.state('receipt.edit', {
			url: '/{id}/edit',
			params: {
				'cashbookEntryId': null
			},
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.receipt.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/receipt/receipt-edit.html',
					controller: 'ReceiptEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('receipt');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'Receipt', function($stateParams, Receipt) {
					return Receipt.get({ id : $stateParams.id }).$promise;
				}],
				previousState: ["$state", "$stateParams", function ($state, $stateParams) {
					var currentStateData = {
						name: $state.current.name,
						params: $stateParams,
						url: $state.href('receipt-detail', $stateParams)
					};
					return currentStateData;
				}]
			}
		})
		.state('receipt.edit.delete', {
			url: '/delete',
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/accounting/receipt/receipt-delete-dialog.html',
					controller: 'ReceiptDeleteController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['Receipt', function(Receipt) {
						    console.log($stateParams.id);
							return Receipt.get({ id : $stateParams.id }).$promise;
						}]
					}
				}).result.then(function() {
					$state.go('cashbook-entry-detail', {id: $stateParams.cashbookEntryId});
				}, function() {
					$state.go('^');
				});
			}]
		});
	}

})();
