(function() {
	'use strict';

	angular.module('walterApp').controller('AppointmentController',
			AppointmentController);

	AppointmentController.$inject = [ '$scope', '$state', '$stateParams', 'PaginationUtil', '$window',
			'DateUtils', 'Appointment', 'ParseLinks', 'AlertService',
			'pagingParams', 'paginationConstants', 'AppointmentType', 'moment',
			'calendarConfig', 'ColumnConfigService', 'AppointmentRelatedEntities', 'FilterService' ];

	function AppointmentController($scope, $state, $stateParams, PaginationUtil, $window, DateUtils,
			Appointment, ParseLinks, AlertService, pagingParams,
			paginationConstants, AppointmentType, moment, calendarConfig, ColumnConfigService, AppointmentRelatedEntities, FilterService) {
		var vm = this;

        vm.listName = "APPOINTMENT";

        /** Column-Config */

        ColumnConfigService.query(vm.listName, true, setColumnConfig);

        function setColumnConfig(columnConfig) {
            vm.columnConfig = columnConfig;
        }

        vm.listDateFormats = {
            beginDate: "medium",
            endDate: "medium"
        };

        pagingParams.search = $stateParams.search;
        pagingParams.sort = $stateParams.sort;
        pagingParams.mode = $stateParams.mode;
        pagingParams.predicate = PaginationUtil.parsePredicate($stateParams.sort);
        pagingParams.ascending = PaginationUtil.parseAscending($stateParams.sort);

        vm.loadPage = loadPage;
        vm.resetSearch = resetSearch;
        vm.switchSearch = switchSearch;
        vm.isSearchDataDirty = isSearchDataDirty;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.switchPage = switchPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.mode = pagingParams.mode;
        vm.switchMode = switchMode;
        vm.appointmentTypes = AppointmentRelatedEntities.getAppointmentTypes();

        var currentSearch = pagingParams.search;
        if (currentSearch)
        {
            vm.search = angular.fromJson(currentSearch);

            loadAll();
        }
        else  {
            resetSearch();
        }

        vm.searchActive = vm.search.search;

        function loadAll(noFilterReset) {
            if (!noFilterReset) {
                FilterService.resetFilterSelection();
            }

            var earliestBegin = vm.search.earliestBegin ? DateUtils
            .convertLocalDateToServer(vm.search.earliestBegin) : null;
            var latestEnd = vm.search.latestEnd ? DateUtils
            .convertLocalDateToServer(vm.search.latestEnd) : null;

            Appointment.query({
                page : pagingParams.page - 1,
                size : vm.itemsPerPage,
                sort : sort(),
                appointmentTypeId : vm.search.appointmentTypeId,
                searchString : trimInput(vm.search.nameOrDescription),
                earliestBegin : earliestBegin,
                latestEnd : latestEnd,
                location : trimInput(vm.search.location)
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.appointments = data;
                vm.page = pagingParams.page;
                transition();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function switchPage() {
            pagingParams.page = vm.page;
            transition();
        }

        function transition () {
            vm.search.search = vm.searchActive;
            if (vm.mode) {
                FilterService.setIsVisible(false);
            } else {
                FilterService.setIsVisible(true);
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: angular.toJson(vm.search),
                mode: vm.mode
            });
        }

        function switchSearch() {
            if (vm.searchActive) {
                vm.searchActive = false;
            } else {
                vm.searchActive = true;
            }
            transition();
        }

        function resetSearch() {
            vm.appointments = null;
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = {
                appointmentTypeId : null,
                nameOrDescription : null,
                earliestBegin : null,
                latestEnd : null,
                location : null,
                search: vm.searchActive
            };
            loadAll();
        }

        function isSearchDataDirty() {
            return vm.search.appointmentTypeId != null ||
                vm.search.nameOrDescription != null ||
                vm.search.earliestBegin != null ||
                vm.search.latestEnd != null ||
                vm.search.location != null;
        }

        function setSearch(search) {
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = search;
            loadAll(true);
        }

        function getCurrentSearch() {
            return vm.search;
        }

        FilterService.setResetSearchFun(resetSearch);
        FilterService.setSearchFun(setSearch);
        FilterService.getCurrentSearchFun(getCurrentSearch);
        FilterService.setIsVisible(false);


        // for search
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.exportReport = exportReport;

		function trimInput(string) {

			if (!string || 0 === string.length) {
				return null;
			} else {
				return string.trim();
			}
		}

        // for export
        vm.export = {
            from: new Date((new Date()).getFullYear() - 2, 11, 1),
            to: new Date((new Date()).getFullYear() - 1, 10, 30)
        };
        function exportReport() {
            Appointment.report({
                from: DateUtils.convertLocalDateToServer(vm.export.from),
                to: DateUtils.convertLocalDateToServer(vm.export.to)
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                var blob = new Blob([data.data], { type: 'text/xml' });
                saveAs(blob, data.filename);
            };

            function onError(error) {
                AlertService.error(error.data.message);
            };
        }

        vm.datePickerOpenStatus.earliestBegin = false;
		vm.datePickerOpenStatus.latestEnd = false;
        vm.datePickerOpenStatus.exportFrom = false;
        vm.datePickerOpenStatus.exportTo = false;

		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}

		//Calendar-View
		vm.calendarView = 'month';
		vm.viewDate = new Date();

		calendarConfig.i18nStrings.weekNumber = 'Woche {week}';
		calendarConfig.allDateFormats.angular.title.week = 'Woche {week} in {year}';
		calendarConfig.allDateFormats.angular.title.day = 'EEEE d. MMMM, yyyy';
		calendarConfig.allDateFormats.moment.date.hour = 'HH:mm';

		moment.updateLocale('de', {
			week: {
				dow: 1
			}
		});
		moment.updateLocale('de');

		//Fill calendar view with events
		vm.events = [];

		var earliestBegin = vm.search.earliestBegin ? DateUtils
				.convertLocalDateToServer(vm.search.earliestBegin) : null;
		var latestEnd = vm.search.latestEnd ? DateUtils
				.convertLocalDateToServer(vm.search.latestEnd) : null;

		Appointment.query({
			size : 9999,
			earliestBegin : earliestBegin,
			latestEnd : latestEnd
		}, fillEvents);

		function fillEvents(data, headers) {
			for (var i = 0; i < data.length; i++) {
				vm.events.push({
					id : data[i].id,
					title : data[i].name,
					color : calendarConfig.colorTypes.warning,
					startsAt : new Date(data[i].beginDate),
					endsAt : new Date(data[i].endDate)
				});
			}
		}

		vm.cellIsOpen = true;

		vm.toggle = function($event, field, event) {
			$event.preventDefault();
			$event.stopPropagation();
			event[field] = !event[field];
		};

		vm.timespanClicked = function(date, cell) {

			if (vm.calendarView === 'month') {
				if ((vm.cellIsOpen && moment(date).startOf('day').isSame(
						moment(vm.viewDate).startOf('day')))
						|| cell.events.length === 0 || !cell.inMonth) {
					vm.cellIsOpen = false;
				} else {
					vm.cellIsOpen = true;
					vm.viewDate = date;
				}
			} else if (vm.calendarView === 'year') {
				if ((vm.cellIsOpen && moment(date).startOf('month').isSame(
						moment(vm.viewDate).startOf('month')))
						|| cell.events.length === 0) {
					vm.cellIsOpen = false;
				} else {
					vm.cellIsOpen = true;
					vm.viewDate = date;
				}
			}

		};

		vm.eventClicked = function(event) {
			$state.go('appointment-detail',  {id: event.id});
		};

		function switchMode() {
		    vm.mode = !vm.mode;
		    resetSearch();
		    transition();
        }

	}
})();
