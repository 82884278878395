(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider

        .state('demandletter', {
            parent: 'app',
            url: '/demandletter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.demandletter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/letters/demand-letters/demandletter-edit.html',
                    controller: 'DemandLetterEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('demandletter');
                    $translatePartialLoader.addPart('letter');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DemandLetter', function($stateParams, DemandLetter) {
                    return {
                        title: null,
                        text: null,
                        payment: true,
                        letterType: "DEMAND_LETTER"
                    };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'demandletter',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('demandletter.delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/letters/demand-letters/demandletter-delete-dialog.html',
                    controller: 'DemandLetterDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DemandLetter', function(DemandLetter) {
                            return DemandLetter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('demandletter', null, { reload: 'demandletter' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('demandletter.overwrite', {
            url: '/{id}/overwrite',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/letters/demand-letters/demandletter-overwrite-dialog.html',
                    controller: 'DemandLetterEditController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DemandLetter', function(DemandLetter) {
                            return DemandLetter.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('demandletter', null, { reload: 'demandletter' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
