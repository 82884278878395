(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonInstrumentEditController', PersonInstrumentEditController);

    PersonInstrumentEditController.$inject = ['$timeout', '$state', '$scope', 'previousState', '$stateParams', 'entity', 'PersonInstrument', 'Instrument', 'Person'];

    function PersonInstrumentEditController ($timeout, $state, $scope, previousState, $stateParams, entity, PersonInstrument, Instrument, Person) {
        var vm = this;

        vm.personInstrument = entity;

        vm.previousState = previousState.name;
        vm.save = save;
        vm.back = back;
        vm.person = Person.get({id : $stateParams.personid});
        vm.personInstrument.personId = $stateParams.personid;


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.instruments = Instrument.query();
        vm.createMode = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        if(entity.id != null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
        }


        function save () {
            vm.isSaving = true;
            if (vm.personInstrument.id !== null) {
                PersonInstrument.update(vm.personInstrument, onSaveSuccess, onSaveError);
            } else {
                PersonInstrument.save(vm.personInstrument, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personInstrumentUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
                $state.go(vm.previousState, {"id": vm.person.id});
        }

        vm.datePickerOpenStatus.beginDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
