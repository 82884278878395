(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CompositionRemoveComposerController',CompositionRemoveComposerController);

    CompositionRemoveComposerController.$inject = ['$uibModalInstance', 'entity', 'composition', 'Composer', 'ComposersForComposition'];

    function CompositionRemoveComposerController($uibModalInstance, entity, composition, Composer, ComposersForComposition) {
        var vm = this;

        vm.composition = composition;
        vm.composer = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
        	ComposersForComposition.delete({id : vm.composition.id, composerId : vm.composer.id}, vm.composer,
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
