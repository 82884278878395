(function() {
    'use strict';

    angular
        .module('walterApp')
        .provider('ConceditService', ConceditService);

    function ConceditService () {

        /*jshint validthis: true */
        this.$get = getService;


        getService.$inject = ['$timeout', '$sce', '$translate', 'JhiTrackerService'];

        function getService ($timeout, $sce,$translate, JhiTrackerService) {


            return {
                get: get
            };

            function get() {
                return concedits;
            }


        }
    }
})();
