(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('membership-fee-amount', {
            parent: 'personal-data-management',
            url: '/membership-fee-amount?page&sort&search',
            data: {
                pageTitle: 'walterApp.membershipFeeAmount.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/membership-fee-amount/membership-fee-amounts.html',
                    controller: 'MembershipFeeAmountController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('membershipFeeAmount');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('membership-fee-amount.new', {
            url: '/new?membershipFeeId',
            data: {
                pageTitle: 'walterApp.membershipFeeAmount.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/membership-fee-amount/membership-fee-amount-edit.html',
                    controller: 'MembershipFeeAmountEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('membershipFeeAmount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MembershipFeeAmount', function($stateParams, MembershipFeeAmount) {
                        return {
                        	beginDate: null,
                            endDate: null,
                            amount: null,
                            id: null
                        };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'membership-fee-amount',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            },
        })
        .state('membership-fee-amount.edit', {
            url: '/{id}/edit?membershipFeeId',
            data: {
                pageTitle: 'walterApp.membershipFeeAmount.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/personal-data-management/membership-fee-amount/membership-fee-amount-edit.html',
                    controller: 'MembershipFeeAmountEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('membershipFeeAmount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MembershipFeeAmount', function($stateParams, MembershipFeeAmount) {
                    return MembershipFeeAmount.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'membershipFee',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('membership-fee-amount.delete', {
            url: '/{id}/delete',
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/personal-data-management/membership-fee-amount/membership-fee-amount-delete-dialog.html',
                    controller: 'MembershipFeeAmountDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MembershipFeeAmount', function(MembershipFeeAmount) {
                            return MembershipFeeAmount.get({id : $stateParams.id}).$promise;
                        }],
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'membership-fee-detail',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                }).result.then(function() {
                    $state.go('membership-fee-detail', {id: $stateParams.membershipFeeId}, { reload: 'membership-fee-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
