(function() {
	'use strict';
	angular
		.module('walterApp')
		.factory('BankImportData', BankImportData);

	BankImportData.$inject = ['$resource', 'DateUtils'];

	function BankImportData ($resource, DateUtils) {
		var resourceUrl =  'api/bank-import-data/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true,
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

						if(data.entryDate) {
							data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
						}
					}
					return data;
				}
			},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        if(data.entryDate) {
                            data.dateFrom = DateUtils.convertLocalDateFromServer(data.dateFrom);
                        }
                    }
                    return data;
                }
            },
			'save': {
				method: 'POST',
				isArray: true,
				transformRequest: function (data) {
					var copy = angular.copy(data);
					copy.date = DateUtils.convertLocalDateToServer(copy.date);
					return angular.toJson(copy);
				}
			},
            'update': { method:'PUT' }
		});
	}
})();

(function() {
	'use strict';
	angular
		.module('walterApp')
		.factory('APIImport', APIImport);

	APIImport.$inject = ['$resource', 'DateUtils'];

	function APIImport ($resource, DateUtils) {
		var resourceUrl =  'api/bank-import-data/api';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST',
				isArray: true,
				transformRequest: function (data) {
					var copy = angular.copy(data);
					copy.from = DateUtils.convertLocalDateToServer(copy.from);
					copy.to = DateUtils.convertLocalDateToServer(copy.to);
					return angular.toJson(copy);
				}
			}
		});
	}
})();
