(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('MemberNewController', MemberNewController);

    MemberNewController.$inject = ['$state', '$timeout', '$scope', '$stateParams', 'previousState', 'entity', 'Member', 'MemberNeededEntities', '$filter'];

    function  MemberNewController ($state, $timeout, $scope, $stateParams,  previousState, entity, Member,  MemberNeededEntities, $filter) {
        var vm = this;

        vm.member = entity;
        vm.person = vm.member.person;

        vm.membership = vm.member.membership;
        vm.membershipFees = MemberNeededEntities.membershipFees();

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.previousState = previousState;
        vm.createMode = false;
        vm.back = back;
        vm.isSaving = false;

        if (vm.person.id !== null) {
            vm.createMode = false;
        } else {
            vm.createMode = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function save () {
            vm.isSaving = true;
            vm.member.person = vm.person;
            vm.member.membership = vm.membership;
            if (vm.member.person.id !== null) {
                Member.update(vm.member, onSaveSuccess, onSaveError);
            } else {
                Member.save(vm.member, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personUpdate', result);
            vm.isSaving = false;
            back();
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function back () {
                if (previousState.params.id) {
                    $state.go(vm.previousState.name, {"id": vm.previousState.params.id}, {reload : true});
                } else {
                    $state.go(vm.previousState.name, {}, {reload : true});
                }

        }


        vm.datepickerOptions = {
            datepickerMode:'year',
            minMode:'year',
            showWeeks:"false",
        };


        vm.datePickerOpenStatus.birthDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.changeDate = function () {
            var date = vm.membership.endDate;
            if (date.getDate() === 31 && date.getMonth() === 11) {
                //do nothing
            }
            else {
                date.setFullYear(date.getFullYear() +1);
                date.setDate(date.getDate() -1);
                vm.membership.endDate = date;
                //       var dateText = $filter('date')(dateText, "dd.MM.yyyy");
                var _date = $filter('date')(new Date(date), 'dd.MM.yyyy');
                $('#field_endDate').val(_date).trigger('input');
            }
        }
    }
})();
