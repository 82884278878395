(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('Member', Member);

    Member.$inject = ['$resource', 'DateUtils'];

    function Member ($resource, DateUtils) {
        var resourceUrl =  'api/people/member/:id';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.person.birthDate = DateUtils.convertLocalDateToServer(copy.person.birthDate);
                    copy.membership.beginDate = DateUtils.convertLocalDateToServer(copy.membership.beginDate);
                    copy.membership.endDate = DateUtils.convertLocalDateToServer(copy.membership.endDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('MemberNeededEntities', MemberNeededEntities);

    MemberNeededEntities.$inject = ['NeededEntitiesCache'];

    function MemberNeededEntities (NeededEntitiesCache) {

        this.membershipFees = membershipFees;

        function membershipFees() {
            return NeededEntitiesCache.getCache('api/people/member/membership-fees').query();
        }

        return this;

    }
})();
