(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('MailLetter', MailLetter);

    MailLetter.$inject = ['$resource'];

    function MailLetter ($resource) {
        var resourceUrl =  'api/mail-letters/:id';

        return $resource(resourceUrl, {}, {
            'send': { method:'PUT', 'url': 'api/mail-letters' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PeopleWithInvalidMail', PeopleWithInvalidMail);

    PeopleWithInvalidMail.$inject = ['$resource'];

    function PeopleWithInvalidMail ($resource) {
        var resourceUrl =  'api/peopleWithInvalidMail';

        return $resource(resourceUrl, {}, {
            'query': { method:'GET', isArray: true }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('PeopleWithValidMail', PeopleWithValidMail);

    PeopleWithValidMail.$inject = ['$resource'];

    function PeopleWithValidMail ($resource) {
        var resourceUrl =  'api/peopleWithValidMail';

        return $resource(resourceUrl, {}, {
            'query': { method:'GET', isArray: true }
        });
    }
})();
