(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('ClothingDetailController', ClothingDetailController);

    ClothingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Clothing', 'PersonClothing', 'ClothingType'];

    function ClothingDetailController($scope, $rootScope, $stateParams, previousState, entity, Clothing, PersonClothing, ClothingType) {
        var vm = this;

        vm.clothing = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('walterApp:clothingUpdate', function(event, result) {
            vm.clothing = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
