(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('ComposerDetailController', ComposerDetailController);

    ComposerDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Composer'];

    function ComposerDetailController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, Composer) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.composer = entity;
        vm.goBack = goBack;

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        
        function save () {
            vm.isSaving = true;
            if (vm.composer.id !== null) {
                Composer.update(vm.composer, onSaveSuccess, onSaveError);
            } else {
                Composer.save(vm.composer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:ComposerUpdate', result);
            $state.go(previousState.name, previousState.params, {reload : true});
        }
        
        function goBack() {
        	$state.go(previousState.name, previousState.params, {reload : true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
