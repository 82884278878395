(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('letter.mail-letter', {
            url: '/mail-letter',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'walterApp.mail-letter.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/letters/mail-letters/mail-letter-edit.html',
                    controller: 'MailLetterEditController',
                    controllerAs: 'vm'
                }
            },
            params: {
                letter: {
                    value: null
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('letter');
                    $translatePartialLoader.addPart('mail-letter')
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'mail-letter',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('demandletter.mail-letter', {
                url: '/mail-letter',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'walterApp.mail-letter.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/letters/mail-letters/mail-letter-edit.html',
                        controller: 'MailLetterEditController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    letter: {
                        value: null
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('letter');
                        $translatePartialLoader.addPart('mail-letter')
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'mail-letter',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();
