(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('AwardDeleteController',AwardDeleteController);

    AwardDeleteController.$inject = ['$uibModalInstance', 'entity', 'Award'];

    function AwardDeleteController($uibModalInstance, entity, Award) {
        var vm = this;

        vm.award = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Award.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
