(function () {
    'use strict';

    angular
        .module('walterApp')
        .controller('AppointmentDetailController', AppointmentDetailController);

    AppointmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$filter', 'previousState',
        'entity', 'Appointment', 'CashbookEntry', 'Person',
        'AppointmentType', 'AvailableCompositions', 'CompositionsForAppointment', 'PeopleForPersonGroup', 'PeopleForAppointment', 'UiaccService'];

    function AppointmentDetailController($scope, $rootScope, $stateParams, $filter, previousState, entity, Appointment,
                                         CashbookEntry, Person, AppointmentType, AvailableCompositions,
                                         CompositionsForAppointment, PeopleForPersonGroup, PeopleForAppointment, UiaccService) {
        var vm = this;

        vm.appointment = entity;
        vm.previousState = previousState.name;
        vm.availableCompositions = [];
        vm.addComposition = addComposition;
        vm.showCompositions = (entity.officialAppointmentId != null)  && UiaccService.isDisplayable("appointment.compositions") ;
        vm.showPeople = (entity.personGroupId != null) && UiaccService.isDisplayable("appointment.people");
        vm.isPresent = isPresent;
        vm.changePresence = changePresence;
        vm.getActiveTab = getActiveTab;
        vm.peopleEditable = peopleEditable;
        vm.tab = getActiveTab();

        if (vm.showCompositions) {
            vm.availableCompositions = AvailableCompositions.query({active : true, size : 9999})
        }

        if (vm.showCompositions) {
        	getCompositions();
        }

        if (vm.showPeople) {
        	getPeople();
        }

        function getCompositions() {
        	CompositionsForAppointment.query({id : entity.officialAppointmentId, sort : 'title,asc'}, function(data,headers) {
        		vm.compositions = data;
        	});
        }

        function addComposition() {
        	CompositionsForAppointment.update({id : entity.officialAppointmentId}, vm.selectedComposition, function() {
        		getCompositions();
        	});
        }
        function getPeople() {
        	vm.availablePeople = PeopleForPersonGroup.query({id : entity.personGroupId, size : 9999, sort : 'lastName,firstName,asc'});
        	vm.people = PeopleForAppointment.query({id : entity.id, size : 9999});
        }

        function addPerson(person) {
        	PeopleForAppointment.update({id : entity.id}, person, function() {
        		vm.people = PeopleForAppointment.query({id : entity.id, size : 9999});
        	});
        }

        function removePerson(person) {
        	PeopleForAppointment.delete({id : entity.id, personId : person.id}, person, function() {
        		vm.people = PeopleForAppointment.query({id : entity.id, size : 9999});
        	});
        }

        function changePresence(person) {
        	if (!isPresent(person.id)) {
        		addPerson(person);
        	}
        	else {
        		removePerson(person);
        	}
        }

        function isPresent(personId) {
        	var found = $filter('filter')(vm.people, {id : personId}, true);
        	return found.length != 0;
        }
        var unsubscribe = $rootScope.$on('walterApp:appointmentUpdate', function (event, result) {
            vm.appointment = result;
        });

        function getActiveTab() {
        	if (vm.showCompositions && vm.showPeople) {
        		return 0;
        	} else
        	if (vm.showPeople) {
        		return 1;
        	}
        	else {
        		return 0;
        	}
        }

        function peopleEditable() {
           return  UiaccService.isEditable("appointment.people");
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
