(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('ReceiptEditController', ReceiptEditController);

	ReceiptEditController.$inject = ['$timeout', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Upload', 'Receipt', 'CashbookEntry', 'Company', 'DateUtils'];

	function ReceiptEditController ($timeout, $state, $scope, $rootScope, $stateParams, previousState, entity, Upload, Receipt, CashbookEntry, Company, DateUtils) {
		var vm = this;

		vm.overwriteIdentifierOptions = [
            {value: false, label: "walterApp.receipt.autoIdentifier"},
            {value: true, label: "walterApp.receipt.manualIdentifier"}
        ];
		vm.cashbookEntryId = $stateParams.cashbookEntryId;
		vm.receipt = entity;
		vm.currentReceiptFile = entity.file;
		vm.clear = clear;
		vm.datePickerOpenStatus = {};
		vm.openCalendar = openCalendar;
		vm.save = save;
		vm.cashbookentries = CashbookEntry.query();
		vm.companies = Company.query({ size: 9999 });
		vm.previousState = previousState;
		vm.progress = false;
		vm.showUploadField = false;
        vm.datepickerOptions = {
              maxDate: new Date()
        };
        vm.isInEditMode = entity.id !== null;
        vm.hasFileUploaded = entity.file !== null;
        vm.toggleShowUploadField = toggleShowUploadField;

        if(entity.id != null) {
            // transform fields for typeahead if necessary
            if(typeof vm.receipt.companyId != 'object') {
                vm.receipt.companyId = {
                    id: vm.receipt.companyId,
                    name: vm.receipt.companyName
                };
            }
        }

		if(vm.receipt.id != null) {
			// transform fields for typeahead if necessary
			if(typeof vm.receipt.company != 'object') {
				vm.receipt.company = {
					id: vm.receipt.companyId,
					name: vm.receipt.companyName
				};
			}
		} else {
            vm.receipt.overwriteIdentifier = false;
        }

		function toggleShowUploadField() {
			vm.showUploadField = !vm.showUploadField;

			if(vm.showUploadField) { vm.receipt.file = null; }
			else { vm.receipt.file = entity.file; }
		}

		$timeout(function (){
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function save (file) {
			vm.isSaving = true;

			var receipt = {};
			angular.copy(vm.receipt, receipt);

			receipt.date = DateUtils.convertLocalDateToServer(receipt.date);

			// transform fields from typeahead configuration to one usable by the rest-interface
			if(typeof receipt.companyId === 'object' && receipt.companyId !== null) {
				receipt.companyId = receipt.companyId.id;
			}
			else {
				receipt.companyId = null;
			}

			// cleanup
			if(receipt.id == null) delete receipt.id;
			if(receipt.identifier == null) delete receipt.identifier;
			if(receipt.companyId == null) delete receipt.companyId;
			if(receipt.companyName == null) delete receipt.companyName;
			if(receipt.note == null) delete receipt.note;
			if(receipt.cashbookEntryId == null) delete receipt.cashbookEntryId;
			if(!receipt.overwriteIdentifier) receipt.identifier = "";
			delete receipt.file;
			delete receipt.uploadedFile;
			delete receipt.company;
			delete receipt.entriesCount;
			delete receipt.$promise;
			delete receipt.$resolved;

			Upload.upload({
				url: 'api/receipts' + (vm.receipt.id !== null ? '/' + vm.receipt.id : ''),
				method: 'POST',
				data: {
					receipt: receipt,
					uploadFile: file
				}
			})
			.progress(function (evt) { vm.progress = parseInt(100.0 * evt.loaded / evt.total); })
			.success(onSaveSuccess)
			.error(onSaveError);
		}

		function onSaveSuccess (result) {
			$scope.$emit('walterApp:receiptUpdate', result);
			vm.isSaving = false;
			$state.go(previousState.name, { id: $stateParams.cashbookEntryId });
		}

		function onSaveError () {
			vm.isSaving = false;
		}

		vm.datePickerOpenStatus.date = false;

		function openCalendar (date) {
			vm.datePickerOpenStatus[date] = true;
		}

		vm.goBack = goBack;
		function goBack() {
        	$state.go(previousState.name,  { id: $stateParams.cashbookEntryId });
		}

		vm.downloadReceipt = downloadReceipt;
		function downloadReceipt() {
			Receipt.download({
				uuid: vm.currentReceiptFile
			}, onSuccess, onError);

			function onSuccess(data, headers) {
				var blob = new Blob([data.data], { type: headers('content-type') });
				saveAs(blob, data.filename);
			};

			function onError(error) {
				AlertService.error(error.data.message);
			};
		}
	}
})();
