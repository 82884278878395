(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonGroupEditController', PersonGroupEditController);

    PersonGroupEditController.$inject = ['$state', '$timeout', '$scope', '$stateParams', 'previousState', 'entity', 'PersonGroup'];

    function PersonGroupEditController ($state, $timeout, $scope, $stateParams,  previousState, entity, PersonGroup) {
        var vm = this;

        vm.personGroup = entity;
        vm.previousState = previousState.name;
        vm.save = save;

        vm.persongroups = PersonGroup.query();


        if(entity.id != null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function save () {
            vm.isSaving = true;
            if (!vm.personGroup.hasPersonRelevance) {
                vm.personGroup.hasPersonRelevance = false;
            }
            if (vm.personGroup.id !== null) {
                PersonGroup.update(vm.personGroup, onSaveSuccess, onSaveError);
            } else {
                PersonGroup.save(vm.personGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:personGroupUpdate', result);
            vm.isSaving = false;
            $state.go(previousState.name);


        }



        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
