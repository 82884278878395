(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('CashbookEntryChooseAppointmentController', CashbookEntryChooseAppointmentController);

	CashbookEntryChooseAppointmentController.$inject = ['$uibModalInstance', '$state', 'Appointment', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

	function CashbookEntryChooseAppointmentController($uibModalInstance, $state, Appointment, ParseLinks, AlertService, pagingParams, paginationConstants) {
		var vm = this;

		vm.clear = clear;
		vm.save = save;

		vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.loadAll = loadAll;

		loadAll();

		function loadAll () {
		    Appointment.query({
		        page: pagingParams.page - 1,
		        size: vm.itemsPerPage,
                sort: sort()
		    }, onSuccess, onError);

            function sort() {
            	var result = ['beginDate,desc'];
            	if(vm.predicate != 'date') {
            		result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            	}

                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

		    function onSuccess(data, headers) {
		        vm.links = ParseLinks.parse(headers('link'));
		        vm.totalItems = headers('X-Total-Count');
		        vm.queryCount = vm.totalItems;
		        vm.page = pagingParams.page;
		        vm.appointments = data;
		    }

		    function onError(error) {
		        AlertService.error(error.data.message);
		    }
		}

		function loadPage (page) {
		    vm.page = page;
		    vm.transition();
		}

		function transition () {
		    $state.transitionTo($state.$current, {
		        page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
		        search: vm.currentSearch
		    });
		}
		
		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		function trimInput(string) {
			if (!string || 0 === string.length) {
				return null;
			}
			else {
				return string.trim();
			}
		}

		function save(appointment) {
			$uibModalInstance.close(appointment, {
				id: appointment.id,
				beginDate: appointment.beginDate,
				name: appointment.name,
			});
		}
	}
})();
