(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('ClothingWithGroup', ClothingWithGroup);

    ClothingWithGroup.$inject = ['$resource'];

    function ClothingWithGroup ($resource) {
        var resourceUrl =  'api/clothing/clothingGroup';

        return $resource(resourceUrl, {}, {

                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }

                }
        });
    }
})();
