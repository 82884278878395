(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('ImportDialogController',ImportDialogController);

    ImportDialogController.$inject = ['$uibModalInstance', '$rootScope', '$stateParams', 'BankImportData', 'selectedBankImportData'];

    function ImportDialogController($uibModalInstance, $rootScope, $stateParams, BankImportData, selectedBankImportData) {
        var vm = this;

        vm.clear = clear;
        vm.confirm = confirm;

        function clear () {
            $uibModalInstance.dismiss();
        }

        function confirm () {
        	BankImportData.save(selectedBankImportData, onSaveSuccess);
        }

        function onSaveSuccess(result) {
            $rootScope.$emit('walterApp:cashbookEntryUpdate', result);
            $uibModalInstance.close();
        }
    }
})();
