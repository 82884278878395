(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('LetterOverwriteController', LetterOverwriteController);

    LetterOverwriteController.$inject = ['$uibModalInstance', 'entity', 'Letter'];

    function  LetterOverwriteController($uibModalInstance, entity, Letter) {
        var vmO = this;

        vmO.letter = entity;
        vmO.clear = clear;
        vmO.confirmOverwrite = confirmOverwrite

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmOverwrite() {
            $uibModalInstance.close(true);

        }
    }
})();
