(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('PersonController', PersonController);

    PersonController.$inject = ['$scope', '$state', '$stateParams', 'DateUtils', 'Person', 'ParseLinks', 'AlertService','FilterService', 'pagingParams', 'paginationConstants', 'PaginationUtil', 'ColumnConfigService', 'PersonNeededEntities'];

    function PersonController ($scope, $state, $stateParams, DateUtils, Person, ParseLinks, AlertService, FilterService, pagingParams, paginationConstants, PaginationUtil, ColumnConfigService, PersonNeededEntities) {
        var vm = this;

        vm.listName = "PERSON";

        /** Column-Config */

        ColumnConfigService.query(vm.listName, true, setColumnConfig);

        function setColumnConfig(columnConfig) {
            vm.columnConfig = columnConfig;
        }

        vm.listTranslations = {
            gender: {
                MALE: "walterApp.person.constants.MALE",
                FEMALE: "walterApp.person.constants.FEMALE"
            }
        };

        vm.listDateFormats = {
            birthDate: "mediumDate"
        }

        pagingParams.search = $stateParams.search;
        pagingParams.sort = $stateParams.sort;
        pagingParams.predicate = PaginationUtil.parsePredicate($stateParams.sort);
        pagingParams.ascending = PaginationUtil.parseAscending($stateParams.sort);


        vm.isSearchOpen = false;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.switchPage = switchPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.personGroups = PersonNeededEntities.personGroups();


        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.loadAll = loadAll;
        vm.bulkEdit = bulkEdit;



        var currentSearch = pagingParams.search;
        if (currentSearch)
            { vm.search = angular.fromJson(currentSearch)}
        else  {
            resetSearch();

        }
        vm.isSearchOpen = vm.search.search;
        vm.resetSearch = resetSearch;
        function resetSearch() {
            vm.search = {
                name: null,
                dateBegin: null,
                dateEnd: null,
                gender: null,
                personGroups: null,
                search: vm.isSearchOpen
            };
            loadAll();
        }

        vm.isSearchDataDirty = isSearchDataDirty;
        function isSearchDataDirty() {
            return vm.search.name != null ||
                vm.search.dateBegin != null ||
                vm.search.dateEnd != null ||
                vm.search.personGroups != null ||
                vm.search.gender != null
        }


        loadAll();

        function loadAll () {
            var dateBegin = vm.search.dateBegin ? DateUtils.convertLocalDateToServer(vm.search.dateBegin) : null;
            var dateEnd = vm.search.dateEnd ? DateUtils.convertLocalDateToServer(vm.search.dateEnd) : null;
            Person.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                namePattern: vm.search.name,
                bdBegin: dateBegin,
                bdEnd: dateEnd,
                gender: vm.search.gender,
                personGroups: vm.search.personGroups
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.people = data;
                vm.page = pagingParams.page;
                transition();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function switchPage() {
            pagingParams.page = vm.page;
            loadAll();
            transition();
        }

        function transition () {
            vm.search.search = vm.isSearchOpen;
            $state.go($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: angular.toJson(vm.search)
            });
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function setSearch(search) {
            vm.search = search;
            vm.loadAll();
        }

        function getCurrentSearch() {
            return vm.search;
        }

        FilterService.setListName("PERSON");
        FilterService.setResetSearchFun(resetSearch);
        FilterService.setSearchFun(setSearch);
        FilterService.getCurrentSearchFun(getCurrentSearch);
        FilterService.setIsVisible(true);

        FilterService.getAllFilter();

        function bulkEdit() {

            var dateBegin = vm.search.dateBegin ? DateUtils.convertLocalDateToServer(vm.search.dateBegin) : null;
            var dateEnd = vm.search.dateEnd ? DateUtils.convertLocalDateToServer(vm.search.dateEnd) : null;
            Person.query({
                sort: sort(),
                namePattern: vm.search.name,
                bdBegin: dateBegin,
                bdEnd: dateEnd,
                gender: vm.search.gender,
                personGroups: vm.search.personGroups
            }, onSuccessBulkEdit, onErrorBulkEdit());
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function onSuccessBulkEdit(result) {
            var personCollection = {"persons": result};

            $state.go("person-bulk", {personCollection: personCollection});
        }

        function onErrorBulkEdit() {

        }

    }
})();
