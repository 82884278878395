(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CompositionController', CompositionController);

    CompositionController.$inject = ['$scope', '$state', '$stateParams', 'PaginationUtil', 'Composition', 'CompositionColorCodes', 'CompositionMusicBooks', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Genre', 'FilterService', 'ColumnConfigService', 'CompositionRelatedEntities'];

    function CompositionController ($scope, $state, $stateParams, PaginationUtil, Composition, CompositionColorCodes, CompositionMusicBooks, ParseLinks, AlertService, pagingParams, paginationConstants, Genre, FilterService, ColumnConfigService, CompositionRelatedEntities) {
        var vm = this;

        vm.listName = "COMPOSITION";

        /** Column-Config */

        ColumnConfigService.query(vm.listName, true, setColumnConfig);

        function setColumnConfig(columnConfig) {
            vm.columnConfig = columnConfig;
        }

        vm.listLinks = {
            colorCodeName: {
                link: 'color-code.edit',
                id: 'colorCodeId'
            },
            orderingCompanyName: {
                link: 'company.edit',
                id: 'orderingCompanyId'
            },
            publisherName: {
                link: 'company.edit',
                id: 'publisherId'
            },
            genreName: {
                link: 'genre.edit',
                id: 'genreId'
            },
            musicBookName: {
                link: 'music-book-detail',
                id: 'musicBookId'
            }
        };

        pagingParams.search = $stateParams.search;
        pagingParams.sort = $stateParams.sort;
        pagingParams.predicate = PaginationUtil.parsePredicate($stateParams.sort);
        pagingParams.ascending = PaginationUtil.parseAscending($stateParams.sort);

        vm.loadPage = loadPage;
        vm.resetSearch = resetSearch;
        vm.switchSearch = switchSearch;
        vm.isSearchDataDirty = isSearchDataDirty;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.switchPage = switchPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.colorCodes = CompositionRelatedEntities.getColorCodes();
        vm.musicBooks = CompositionRelatedEntities.getMusicBooks();

        var currentSearch = pagingParams.search;
        if (currentSearch)
        {
            vm.search = angular.fromJson(currentSearch);

            loadAll();
        }
        else  {
            resetSearch();
        }

        vm.searchActive = vm.search.search;

        function loadAll (noFilterReset) {
            if (!noFilterReset) {
                FilterService.resetFilterSelection();
            }
            Composition.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                colorCodeId: vm.search.colorCodeId,
                nr: trimInput(vm.search.nr),
                title: trimInput(vm.search.title),
                orderingCompany: trimInput(vm.search.orderingCompany),
                publishingCompany: trimInput(vm.search.publishingCompany),
                composer: trimInput(vm.search.composer),
                arranger: trimInput(vm.search.arranger),
                genre: trimInput(vm.search.genre),
                musicBookId: vm.search.musicBookId
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.compositions = data;
                vm.page = pagingParams.page;
                transition();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function switchPage() {
            pagingParams.page = vm.page;
            transition();
        }

        function transition () {
            vm.search.search = vm.searchActive;
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: angular.toJson(vm.search)
            });
        }

        function switchSearch() {
            if (vm.searchActive) {
                vm.searchActive = false;
            } else {
                vm.searchActive = true;
            }
            transition();
        }

        function trimInput(string) {
        	if (!string || 0 === string.length) {
        		return null;
        	}
        	else {
        		return string.trim();
        	}
        }

        function resetSearch() {
            vm.compositions = null;
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = {
                colorCodeId: null,
                nr: null,
                title: null,
                orderingCompany: null,
                publishingCompany: null,
                composer: null,
                arranger: null,
                genre: null,
                musicBookId: null,
                search: vm.isSearchOpen
            };
            loadAll();
        }

        function isSearchDataDirty() {
        	return vm.search.colorCodeId != null ||
                vm.search.nr != null ||
                vm.search.title != null ||
                vm.search.orderingCompany != null ||
                vm.search.publishingCompany != null ||
                vm.search.composer != null ||
                vm.search.arranger != null ||
                vm.search.genre != null ||
                vm.search.musicBookId != null;
        }


        function setSearch(search) {
            vm.page = 1;
            pagingParams.page = vm.page;
            vm.search = search;
            loadAll(true);
        }

        function getCurrentSearch() {
            return vm.search;
        }

        FilterService.setResetSearchFun(resetSearch);
        FilterService.setSearchFun(setSearch);
        FilterService.getCurrentSearchFun(getCurrentSearch);
        FilterService.setIsVisible(true);




        }
})();
