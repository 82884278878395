(function() {
    'use strict';

    angular
        .module('walterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('arranger', {
            parent: 'base',
            url: '/arranger?page&sort&search',
            data: {
                pageTitle: 'walterApp.arranger.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/base/arranger/arrangers.html',
                    controller: 'ArrangerController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('arranger');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('arranger.new', {
            url: '/new',
            data: {
                pageTitle: 'walterApp.arranger.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/base/arranger/arranger-detail.html',
                    controller: 'ArrangerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('arranger');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Arranger', function($stateParams, arranger) {
                        return {
                            name: null,
                            id: null
                        };
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'arranger',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('arranger.edit', {
            url: '/{id}/edit',
            data: {
                pageTitle: 'walterApp.arranger.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/base/arranger/arranger-detail.html',
                    controller: 'ArrangerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('arranger');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Arranger', function($stateParams, arranger) {
                    return arranger.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'arranger',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('arranger.delete', {
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/base/arranger/arranger-delete-dialog.html',
                    controller: 'ArrangerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Arranger', function(arranger) {
                            return arranger.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('arranger', null, { reload: 'arranger' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
