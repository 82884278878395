(function() {
	'use strict';

	angular
		.module('walterApp')
		.controller('NotificationRuleEditController', NotificationRuleEditController);

	NotificationRuleEditController.$inject = ['$timeout', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'NotificationRule'];

	function NotificationRuleEditController ($timeout, $state, $scope, $rootScope, $stateParams, previousState, entity, NotificationRule) {
		var vm = this;

		vm.notificationRule = entity;
		vm.clear = clear;
		vm.save = save;
		vm.validEntities = NotificationRule.validEntities();
		vm.authorities = NotificationRule.validAuthorities();
		vm.previousState = previousState;

		$timeout(function (){
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear () {}

		function save () {
			vm.isSaving = true;
			if (vm.notificationRule.id !== null) {
				NotificationRule.update(vm.notificationRule, onSaveSuccess, onSaveError);
			} else {
				NotificationRule.save(vm.notificationRule, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess (result) {
			$scope.$emit('walterApp:notificationRuleUpdate', result);
			vm.isSaving = false;
			$state.go(previousState.name, previousState.params);
		}

		function onSaveError () {
			vm.isSaving = false;
		}


	}
})();
