(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('CashbookAccountDetailController', CashbookAccountDetailController);

    CashbookAccountDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'CashbookAccount'];

    function CashbookAccountDetailController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, CashbookAccount) {
        var vm = this;
        vm.previousState = previousState;
        vm.save = save;
        vm.cashbookAccount = entity;

        if(entity.id != null) {
            vm.createMode = false;
        }
        else {
            vm.createMode = true;
            vm.cashbookAccount.bankAccount = false;
            vm.cashbookAccount.bankImporterType = null;
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function save () {
            vm.isSaving = true;
            if (vm.cashbookAccount.id !== null) {
                CashbookAccount.update(vm.cashbookAccount, onSaveSuccess, onSaveError);
            } else {
                CashbookAccount.save(vm.cashbookAccount, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:CashbookAccountUpdate', result);
            $state.go(previousState.name, previousState.params);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
