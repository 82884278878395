(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('AwardEditController', AwardEditController);

    AwardEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Award'];

    function AwardEditController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, Award) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.award = entity;

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        
        function save () {
            vm.isSaving = true;
            if (vm.award.id !== null) {
                Award.update(vm.award, onSaveSuccess, onSaveError);
            } else {
            	Award.save(vm.award, onSaveSuccess, onSaveError);
            }
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('walterApp:awardUpdate', result);
            $state.go(previousState.name);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
