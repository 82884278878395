(function () {
    'use strict';

    angular
        .module('walterApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
        });

        return service;
    }
})();

(function () {
    'use strict';

    angular
        .module('walterApp')
        .factory('UserRoles', UserRoles);

    UserRoles.$inject = ['$resource'];

    function UserRoles ($resource) {
        var resourceUrl =  'api/users/roles';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        })
    }
})();
