(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('ColorCodeDetailController', ColorCodeDetailController);

    ColorCodeDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'ColorCode'];

    function ColorCodeDetailController($timeout, $scope, $rootScope, $state, $stateParams, previousState, entity, ColorCode) {
        var vm = this;
        vm.previousState = previousState;
        vm.save = save;
        vm.colorCode = entity;

        if(entity.id != null) {
        	vm.createMode = false;
        }
        else {
        	vm.createMode = true;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        
        function save () {
            vm.isSaving = true;
            if (vm.colorCode.id !== null) {
                ColorCode.update(vm.colorCode, onSaveSuccess, onSaveError);
            } else {
                ColorCode.save(vm.colorCode, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('walterApp:ColorCodeUpdate', result);
            $state.go(previousState.name, previousState.params);
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
