(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('LetterDeleteController',LetterDeleteController);

    LetterDeleteController.$inject = ['$uibModalInstance', 'entity', 'Letter'];

    function LetterDeleteController($uibModalInstance, entity, Letter) {
        var vm = this;

        vm.letter = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Letter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
