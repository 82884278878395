(function() {
    'use strict';
    angular
        .module('walterApp')
        .factory('NeededEntitiesCache', NeededEntitiesCache);

    NeededEntitiesCache.$inject = ['$resource'];

    function NeededEntitiesCache ($resource) {

        this.getCache = getCache;
        this.invalidate = invalidate;

        this.caches = {};

        function getCache(resourceUrl) {


            if (!resourceUrl) {
                return null;
            }

            var cache = this.caches[resourceUrl];
            if (cache) {
                return cache;
            }

            cache =  new EntityCache($resource, resourceUrl);


            this.caches[resourceUrl] = cache;
            return cache;

        }

        function invalidate() {
            this.caches = {};
        }
        return this;
    }
})();



function EntityCache ($resource, resourceUrl) {

        if (!resourceUrl) {
            return null;
        }

        var vm = this;

        vm.query = query;
        vm.invalidate = invalidate;

        vm.data = null;

        vm.resource = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
        });

        function query() {
            if (vm.data) {
                return vm.data;
            }
            vm.data = vm.resource.query({}, onSuccess, onError);
            return vm.data;

            function onSuccess(data, headers) {
                vm.data = data;
            }

            function onError() {
                vm.data = null;
            }
        }

        function invalidate() {
            vm.data = null;
        }

        return vm;

}
