(function() {
	'use strict';

	angular
		.module('walterApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
        .state('cashbook-entry-overview', {
            parent: 'accounting',
            abstract: true,
            views: {
                'filterbar@': {
                    templateUrl: 'app/layouts/navbar/filterbar.html',
                    controller: 'FilterbarController',
                    controllerAs: 'vm'

                }
            },
            onExit: ['CashbookRelatedEntities', function(CashbookRelatedEntities) {
                CashbookRelatedEntities.invalidate();
            }]
        })
		.state('cashbook-entry', {
			parent: 'cashbook-entry-overview',
			url: '/cashbook-entry?page&sort&search',
            listName: 'CASHBOOK-ENTRY',
            type: 'overview',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.cashbookEntry.home.title'
			},
			views: {
				'content-overview@': {
					templateUrl: 'app/accounting/cashbook-entry/cashbook-entries.html',
					controller: 'CashbookEntryController',
					controllerAs: 'vm'
				}
			},
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'date,desc',
                    squash: true
                },
                search: {
                    value: null,
                    dynamic: true
                }
            },
			resolve: {
				pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
					return {
						page: PaginationUtil.parsePage($stateParams.page),
						sort: $stateParams.sort,
						predicate: PaginationUtil.parsePredicate($stateParams.sort),
						ascending: PaginationUtil.parseAscending($stateParams.sort),
						search: $stateParams.search
					};
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('cashbookEntry');
					$translatePartialLoader.addPart('cashbookEntryType');
                    $translatePartialLoader.addPart('filter');
					$translatePartialLoader.addPart('global');
					$translatePartialLoader.addPart('columnConfig');
					return $translate.refresh();
				}]
			}
		})
        .state('cashbook-entry.column-config-edit', {
            type: 'overview',
            url: '/column-config/{entity}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/column-config/column-config-dialog.html',
                    controller: 'ColumnConfigController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        columnConfig: ['ColumnConfig', function(ColumnConfig) {
                            return ColumnConfig.query({entity : $stateParams.entity}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('columnConfig');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('cashbookEntry');
                            return $translate.refresh();
                        }]
                    }
                })
                .result.then(function() {
                    $state.go('cashbook-entry', $stateParams, { reload: 'cashbook-entry' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
		.state('cashbook-entry.new', {
			url: '/new',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.cashbookEntry.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-edit.html',
					controller: 'CashbookEntryEditController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('cashbookEntry');
					$translatePartialLoader.addPart('cashbookEntryType');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'CashbookEntry', function($stateParams, CashbookEntry) {
					return {
						title: null,
						type: null,
						date: new Date(),
						amount: null,
						id: null
					};
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name || 'cashbook-entry',
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
		.state('cashbook-entry-detail', {
            parent: 'cashbook-entry',
            url: '/cashbook-entry/{id}/detail',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.cashbookEntry.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-detail.html',
					controller: 'CashbookEntryDetailController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('cashbookEntry');
					$translatePartialLoader.addPart('cashbookEntryType');
					$translatePartialLoader.addPart('receipt');
					return $translate.refresh();
				}],
				entity: ['$stateParams', 'CashbookEntry', function($stateParams, CashbookEntry) {
					return CashbookEntry.get({ id : $stateParams.id }).$promise;
				}],
				previousState: ["$state", function ($state) {
					var currentStateData = {
						name: $state.current.name || 'cashbook-entry',
						params: $state.params,
						url: $state.href($state.current.name, $state.params)
					};
					return currentStateData;
				}]
			}
		})
        .state('cashbook-entry-detail.edit', {
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-edit.html',
                    controller: 'CashbookEntryEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('cashbookEntry');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CashbookEntry', function($stateParams, CashbookEntry) {
                    return CashbookEntry.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'cashbook-entry-detail',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('cashbook-entry-detail.delete', {
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-delete-dialog.html',
                    controller: 'CashbookEntryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CashbookEntry', function(CashbookEntry) {
                            return CashbookEntry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cashbook-entry', $stateParams, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
		.state('cashbook-entry-edit', {
		    parent: 'cashbook-entry',
			url: '/{id}/edit',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.cashbookEntry.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-edit.html',
					controller: 'CashbookEntryEditController',
					controllerAs: 'vm'
				}
			},
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('cashbookEntry');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CashbookEntry', function($stateParams, CashbookEntry) {
                    return CashbookEntry.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'cashbook-entry',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
		})
        .state('cashbook-entry-edit.delete', {
            url: '/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/accounting/cashbook-entry/cashbook-entry-delete-dialog.html',
                    controller: 'CashbookEntryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CashbookEntry', function(CashbookEntry) {
                            return CashbookEntry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('cashbook-entry', $stateParams, { reload: 'cashbook-entry' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
		.state('cashbook-entry-detail.choose-receipt', {
			url: '/choose?page&sort&search',
			data: {
				authorities: ['ROLE_USER']
			},
			params: {
				page: {
					value: '1',
					squash: true
				},
				sort: {
					value: 'date,desc',
					squash: true
				},
				search: null
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/accounting/receipt/receipt-choose-dialog.html',
					controller: 'ReceiptChooseController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
							return {
								page: PaginationUtil.parsePage($stateParams.page),
								sort: $stateParams.sort,
								predicate: PaginationUtil.parsePredicate($stateParams.sort),
								ascending: PaginationUtil.parseAscending($stateParams.sort),
								search: $stateParams.search
							};
						}],
						translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('receipt');
							$translatePartialLoader.addPart('global');
							return $translate.refresh();
						}]
					}
				}).result.then(function() {
					$state.go('cashbook-entry-detail', { id: $stateParams.id }, { reload: true });
				}, function() {
					$state.go('^');
				});
			}]
		})
		.state('cashbook-entry-detail.delete-receipt', {
			url: '/delete-receipt/{receiptId}',
			params: {
				'receiptId': null
			},
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/accounting/receipt/receipt-delete-dialog.html',
					controller: 'ReceiptDeleteController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['Receipt', function(Receipt) {
							return Receipt.get({ id : $stateParams.receiptId }).$promise;
						}]
					}
				}).result.then(function() {
					$state.go('cashbook-entry-detail', { id: $stateParams.id }, { reload: true });
				}, function() {
					$state.go('^');
				});
			}]
		})
		.state('cashbook-entry-detail.unlink-receipt', {
			url: '/unlink-receipt/{receiptId}',
			params: {
				'receiptId': null
			},
			data: {
				authorities: ['ROLE_USER']
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/accounting/receipt/receipt-unlink-dialog.html',
					controller: 'ReceiptUnlinkController',
					controllerAs: 'vm',
					size: 'md',
					resolve: {
						entity: ['Receipt', function(Receipt) {
							return Receipt.get({ id : $stateParams.receiptId }).$promise;
						}],
						cashbookEntryId: function() { return $stateParams.id; }
					}
				}).result.then(function() {
					$state.go('cashbook-entry-detail', { id: $stateParams.id }, { reload: true });
				}, function() {
					$state.go('^');
				});
			}]
		})
		.state('cashbook-entry.import', {
			url: '/bank-import-data/',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'walterApp.bankImportData.home.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/accounting/bank-import-data/bank-import-data.html',
					controller: 'ImportController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('bankImportData');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				}]
			}
		});
	}

})();
