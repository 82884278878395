(function() {
    'use strict';

    angular
        .module('walterApp')
        .controller('EmailVerificationLetterOverwriteController', EmailVerificationLetterOverwriteController);

    EmailVerificationLetterOverwriteController.$inject = ['$uibModalInstance', 'entity', 'EmailVerificationLetter'];

    function  EmailVerificationLetterOverwriteController($uibModalInstance, entity, EmailVerificationLetter) {
        var vmO = this;

        vmO.emailVerificationLetter = entity;
        vmO.clear = clear;
        vmO.confirmOverwrite = confirmOverwrite

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmOverwrite() {
            $uibModalInstance.close(true);

        }
    }
})();
